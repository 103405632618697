import { useRef, useState, useEffect } from 'react';
import { Row, Col, Typography, Button, Input, Spin, message } from 'antd';
import { ArrowRightOutlined, ClockCircleOutlined, EnvironmentFilled } from '@ant-design/icons';
import "./facilitySearch.scss";
import {
  setDefaults,
  fromAddress,
} from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useLoadScript } from "@react-google-maps/api";
import classNames from 'classnames';
import { getNearbyFacilities } from '../../services/facility.service';
import LocationHelper from '../../helpers/location.helper';
import { updateMe } from '../../services/user.service';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from 'react-router-dom';
import PosthogHelper from '../../helpers/posthog.helper';

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
})

const { Paragraph, Text } = Typography;

export const FacilitySearch = ({ productTypeCode, flowType }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const [address, setAddress] = useState(currentUser?.location ? LocationHelper.getLocation(currentUser.location) : null);
  const [facilities, setFacilities] = useState([]);
  const [location, setLocation] = useState(currentUser?.location)
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate()

  const focusAddressInput = () => {
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  const handleAddressChange = address => {
    setAddress(address);
  };

  const handleAddressSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const streetNumber = getAddressValueByType(results, 'street_number')
        const route = getAddressValueByType(results, 'route')
        let formattedAddress = ''
        if (streetNumber) {
          formattedAddress = streetNumber
        }
        if (route) {
          formattedAddress = `${formattedAddress} ${route}`
        }
        
        const fetchedLocation = {
          streetAddress: formattedAddress,
          postalCode: getAddressValueByType(results, 'postal_code'),
          city: getAddressValueByType(results, 'locality') || getAddressValueByType(results, 'sublocality_level_1') || getAddressValueByType(results, 'neighborhood'),
          state: getAddressValueByType(results, 'administrative_area_level_1'),
        }
        
        setLocation(fetchedLocation)
        setAddress(address);
      })
      .catch(error => console.error('Error selecting address', error));
  };

  const addressInputRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['places']
  });

  const getAddressValueByType = (results, type) => {
    if (!results || !results[0] || !results[0].address_components) return null;
    
    const component = results[0].address_components.filter(component => 
      component.types.includes(type)
    );
    
    if (component.length) {
      return component[0].short_name;
    }
    return null;
  };

  const onFacilitySearch = async ({ defaultAddress=null, defaultLocation=null, defaultPatient=null }) => {
    if (!defaultAddress && !address) return;

    setIsSearching(true)

    const { results } = await fromAddress(defaultAddress || address);
    
    const {
      lat: latitude, 
      lng: longitude,
    } = results[0].geometry.location;

    const userLocation = defaultLocation || location
    const searchAttempts = currentUser?.searchAttempts?.length ? currentUser?.searchAttempts : []
    const updatedUser = await updateMe({
      fields: {
        location: {
          ...userLocation,
          latitude,
          longitude
        },
        searchAttempts: [
          ...searchAttempts,
          {
            createdAt: new Date()
          }
        ]
      }
    })
    setCurrentUser(updatedUser)

    try {
      const fetchedFacilities = await getNearbyFacilities({
        latitude,
        longitude,
        productTypeCode,
        maxDuration: 120
      });
  
      const facilitiesWithProduct = fetchedFacilities?.filter(facility => 
        facility.menu?.some(item => 
          item.productType.code === productTypeCode && 
          item.price != null && 
          item.price > 0
        )
      ).sort((a, b) => a.duration - b.duration)
  
      setFacilities(facilitiesWithProduct)
    } catch (err) {
      message.error(err.response?.data?.message || 'Failed to search facilities')
    }

    setIsSearching(false)
  };

  useEffect(() => {
    if (currentUser?.location) {
      setLocation(currentUser.location)
      setAddress(LocationHelper.getLocation(currentUser.location))
    }
  }, [currentUser])

  return (
    <div className="facility-search">
      {/* <RegisterModal
        open={openRegisterModal}
        setOpen={setOpenRegisterModal}
        onFinish={onRegisterFinish}
      /> */}

      <div 
        className={classNames("pricing-search")}
      >
        <Row gutter={16} align="bottom">
          <Col flex="auto" onClick={focusAddressInput} className="clickable-input-col">
            <Text className="pricing-search-label">
              <EnvironmentFilled className="pricing-search-label-icon" /> Your Address
            </Text>
            
            {isLoaded ? (
              <PlacesAutocomplete
                value={address}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
                debounce={300}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className="address-autocomplete-container">
                    <Input
                      {...getInputProps({
                        className: 'pricing-search-address',
                        placeholder: 'Enter your full address...',
                        onKeyDown: e => {
                          if (e.key === 'Enter' && suggestions?.length) {
                            e.preventDefault();
                            handleAddressSelect(suggestions[0].description);
                            addressInputRef.current.blur();
                          }
                        },
                      })}
                      ref={addressInputRef}
                      onClick={(e) => e.stopPropagation()}
                    />
                    
                    <div className="autocomplete-dropdown-container">
                      {loading && (
                        <div className="address-spinner">
                          <Spin size='small' /> Loading...
                        </div>
                      )}
                      {suggestions.map((suggestion, index) => {
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, { style })}
                            className="suggestion-item"
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            ) : (
              <Input
                className="pricing-search-address"
                placeholder="Loading Google Places..."
                disabled
              />
            )}
          </Col>
          
          <Col
            className="search-address-btn-col"
          >
            <Button 
              type="primary"
              className="search-address-btn"
              onClick={onFacilitySearch}
              loading={isSearching}
            >
              Search
            </Button>
          </Col>
        </Row>
      </div>

      {facilities.map((facility) => (
        <div 
          key={facility.id}
          className="search-facility-item"
        >
          <Paragraph 
            className="search-p2 bold search-facility-name"
            ellipsis={{
              rows: 1,
            }}
          >
            {facility.name}
          </Paragraph>
          <Paragraph className="search-facility-location">
            {LocationHelper.getLocation(facility.location)}
          </Paragraph>
          <Paragraph className="search-facility-duration">
            <ClockCircleOutlined /> {Math.round(facility.duration)} min drive
          </Paragraph>
          <Text className="search-p2 bold search-facility-price"> 
            {facility.menu.find(item => item.productType.code === productTypeCode)?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </Text>
          <Button 
            type="primary"
            className="schedule-facility-btn"
            onClick={() => {
              PosthogHelper.track(currentUser, `[cac] clicked schedule cac: ${facility._id}`, true)
              navigate(`/flow/${flowType}?facility=${facility._id}`)
            }}
          >
            Schedule <ArrowRightOutlined />
          </Button>
        </div>
      ))}
    </div>
  )
}