import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'


export const listFaqs = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/faqs`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}

