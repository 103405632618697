import { Popover, Typography } from 'antd'
import Breakpoint from '../../enums/breakpoint.enum'
import useWidth from '../../hooks/useWidth.hook'
import { CloseOutlined } from '@ant-design/icons'
import { useState } from 'react'
import './redDropPopover.scss'

const { Paragraph } = Typography

export const RedDropPopover = ({ trigger, male=true }) => {
  const width = useWidth()
  const [open, setOpen] = useState(false)

  return (
    <Popover 
      overlayClassName="reddrop-layover"
      open={open}
      trigger={['hover', 'click']}  // Allow both hover and click
      onOpenChange={setOpen}
      overlayStyle={{
        maxWidth: width < Breakpoint.SM ? '100%' : 500,
      }}
      content={(
        <div className="reddrop-popover">
          <div className="reddrop-popover-header">
            <Paragraph className="reddrop-title">
              Truly Painless Testing
            </Paragraph>
            <CloseOutlined 
              onClick={() => setOpen(false)}
              className="close-popover-icon"
            />
          </div>
          <img 
            src={"/img/reddrop-grid.webp"}
            className="reddrop-img"
          />
          <Paragraph className="reddrop-description">
            Unlike fingerprick tests, RedDrop One gently draws your blood with virtually no sensation. It's designed to make the process pain-free, removing the stress of pricking yourself with a needle.
          </Paragraph>
        </div>
      )}
      placement='right'
    >
      {trigger}
    </Popover>
  )
}