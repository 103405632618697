import { useContext, useState, useEffect, useRef } from 'react'
import { Button, Typography } from 'antd'
import { PopupModal } from "react-calendly";
import Color from '../../colors.scss';
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from 'react-router-dom';
import FlowType from '../../enums/flowType.enum';
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { SyncOutlined, RightOutlined, CheckOutlined } from "@ant-design/icons"
import { useMediaQuery } from 'react-responsive'; 
import "./heart.scss";

const { Paragraph } = Typography

const CalendlyModal = ({ open, setOpen }) => {
  const { currentUser } = useContext(UserContext)

  return (
    <PopupModal
      open={open}
      url="https://calendly.com/d/cp8q-hch-fvg/heart-health-intro-call"
      onModalClose={() => setOpen(false)}
      rootElement={document.getElementById("root")}
      pageSettings={{
        hideLandingPageDetails: true,
      }}
      prefill={{
        firstName: currentUser?.firstName ? currentUser.firstName : '',
        lastName: currentUser?.lastName ? currentUser.lastName : '',
        name: currentUser?.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : '',
        email: currentUser?.email ? currentUser.email : '',
      }}
    />
  )
}

const getBackgroundClass = (isMobileOrTablet) => {
  return isMobileOrTablet ? "no-bg" : "gray-bg"
};

export const Heart = () => {
  const [open, setOpen] = useState()
  const [sections, setSections] = useState(null)
  const [showScrollButton, setShowScrollButton] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate()
  const { instalabMembership } = useContext(UserContext)
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 990px)' });

  const onStart = () => {
    navigate(`/flow/${FlowType.HEART_HEALTH_INTAKE}`)
  }
  const onScheduleCall = () => { setOpen(true) }

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Show the button after scrolling 400px
      if (scrollPosition > 400) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const HeartIntro = () => {
    return <div className={`heart-intro ${getBackgroundClass(isMobileOrTablet)}`}>
        <CalendlyModal open={open} setOpen={setOpen}/>

        <Paragraph className="intro-title">
          Get Your Own <br/>Heart Health Coach
          {/* Heart Health<br/>Membership */}
        </Paragraph>
        
        <Paragraph className="intro-description">
        {/* Join the many who work one-on-one with an Instalab physician to significantly lower their heart disease risk. */}
          Join the many who have significantly lowered their heart disease risk with Instalab's physician-led approach.
        </Paragraph>

        <Button 
          ref={buttonRef}
          type="primary"
          className={`join-btn`}
          onClick={onStart}
        >
        {instalabMembership ? 'Upgrade Today' : 'Join Today'} <RightOutlined className="btn-icon" />
        </Button>
        <Button 
          className="intro-btn"
          onClick={onScheduleCall}
        >
          Book Call to Learn More
        </Button>

        <div className="accepted-insurance" >
            <CheckOutlined /> HSA / FSA cards accepted
        </div>
      </div>
  }

  const timeline = [
    {
      key: 'monthly-care',
      title: <div className="timeline-header">Ongoing Monthly Care: <span>$148/month</span></div>,
      header: true,
      dot: <SyncOutlined/>,
      color: "black"
    },
    {
      key: 'baseline',
      title: "Measure your baseline",
      description: "We'll send you at-home blood test kit (that's painless to use) and a blood pressure monitor to evaluate your heart health. Biomarkers tested include key risk factors such as ApoB, Lp(a), LDL cholesterol, Triglycerides, HbA1c, and more.",
    },
    {
      key: 'meet-physician',
      title: "Meet with your physician",
      description: "Your dedicated physician will review your results, discuss your heart health goals, and work with you to create a personalized care plan that fits your needs.",
    },
    {
      title: "Receive medications if prescribed",
      description: "We'll ship you any medications that are prescribed as part of your care plan. Refills will be automatically sent."
    },

    {
      title: "Test every 6 weeks to check on progress",
      description: "Every 6-8 weeks, you'll test for key heart health biomarkers like blood pressure, HbA1c, ApoB, and LDL cholesterol. Your physician will fine-tune your care plan based on these results and your feedback.",
    },
    {
      title: "Check-ins with your physician",
      description: "You'll have continuous access to your physician through video or messaging, plus easy at-home testing, medication management, and refill support. We're here for you every step of the way."
    },
  ]

  useEffect(() => {
  
    const faqs = [
    {
      question: "How much does the Heart Health Membership cost?",
      answer: <>The Heart Health Membership costs $148/month with the option to cancel anytime. {instalabMembership ? 'Once you upgrade, your Essentials Membership will be canceled, as all its benefits are included in the Heart Health Membership.' : ''}</>
    },
    {
      question: <>What's included in the Heart Health Membership?</>,
      answer: <>The plan offers unlimited physician support, at-home lab tests, and access to generic medications. If you need brand-name drugs like Ozempic or Repatha, our concierge team can assist with insurance coverage. If required for your care, you'll also receive personalized devices such as a blood glucose meter, CGM, blood pressure monitor, or weight scale to enhance your health insights.</>
    },
    {
      question: "Who will be my dedicated physician?",
      answer: "Your dedicated physician will be Dr. Steven Winiarski, a board-certified specialist in clinical lipidology, obesity medicine, and internal medicine. He has over 30 years of experience working with entrepreneurs, executives, and professional athletes, focusing on optimizing heart health and overall well-being. Dr. Winiarski's expertise in these areas ensures personalized and effective care tailored to your specific needs and goals."
    },
    {
      question: `Is the Heart Health Membership for me?`,
      answer: <>If you or your family have a history of high cholesterol, insulin resistance, or high blood pressure, you are eligible for the Heart Health Program. You also qualify if you've received medical test results with non-optimal values in these areas.<br/><br/>Additionally, we encourage anyone interested in optimizing their heart health to consider joining. In your first baseline test, you'll discover exactly how much improvement is needed. For any questions or to learn how this program can effectively reduce your long-term risk, <a onClick={onScheduleCall} className="heart-link">schedule a call</a> with an Instalab advisor.</>
    },  
    {
      question: 'Which medications do you prescribe?',
      answer: <>
        The Heart Health Program provides targeted medications to address specific areas of your heart health. As your treatment progresses, we monitor the effectiveness of these medications and make any necessary adjustments to find the ideal combination for you.
        <br/><br/>
        <b>High Cholesterol:</b>
        <ul>
          <li>Statins</li>
          <li>Cholesterol absorption inhibitors</li>
          <li>PCSK9 inhibitors</li>
          <li>Inclisiran</li>
        </ul>
  
        <b>Insulin Resistance:</b>
        <ul>
          <li>Metformin</li>
          <li>Acarbose</li>
          <li>Semaglutide / Tirzepatide</li>
        </ul>
  
        <b>High Blood Pressure:</b>
        <ul>
          <li>Losartan / Valsartan</li>
          <li>Hydrochlorothiazide</li>
          <li>Amlodipine</li>
        </ul>
      </>
    },
  
    {
      question: `Will my health insurance cover this?`,
      answer: <>This membership isn't covered by insurance but is eligible for HSA/FSA funds. We provide itemized receipts for reimbursement. For non-generic medications, we can help explore insurance coverage options.</>
    }, 
    {
      question: `How often should I get my blood work done?`,
      answer: <>We recommend testing every 6-8 weeks. Regular testing allows you and your physician to fine-tune your treatment and achieve optimal heart health quickly.</>
    }, 
    {
      question: `Is this program available in my state?`,
      answer: <>Yes, this is availabe everywhere in the United States.</>
    }, 
    {
      question: "How are the at-home blood tests virtually painless?",
      answer: "Instalab uses the RedDrop One, which offers a more comfortable and convenient alternative to traditional finger pricks for blood collection. Unlike finger pricks, which can cause sharp pain by puncturing deeper into the skin, the RedDrop One uses microneedles and a gentle suction mechanism to draw blood from the skin's surface with minimal penetration. This reduces the discomfort and potential bruising associated with finger pricks."
    },
    // {
    //   question: `What happens when I reach optimal levels associated with heart health?`,
    //   answer: <>When your biomarkers reach optimal levels, you will no longer need to be enrolled in the Heart Health Program. At that point, you have two options: <b>(1)</b> Downgrade to a more cost-effective program: For $49/month, you'll continue receiving your medication if prescribed and access to your physician for ad-hoc sessions. <b>(2)</b> Consult with your primary care physician: If you prefer, you can discuss your ongoing care and medication needs directly with them.</>
    // }
    ]
  
    setSections([
      {},
      { type: ProductDetailSection.XGRID,
        title: "What's included",
        description: "A concierge physican-led approach to helping you lower your risk of heart disease.",
        data: [
          {
            image: "/img/hh-care-plan.png",
            title: "Personalized Care Plan",
            text: "Your dedicated physician will meet with you to create a plan that considers where you're at now and what your goals should be."
          },
          {
            image: "/img/hh-testing.png",
            // image: "/img/heart-treatment.jpg",
            title: "Frequent Testing",
            text: "You'll test every 6-8 weeks to ensure efficacy of your plan. We'll also send devices like BP monitors and CGMs to gain better insights."          
            // Updates to your care plan will be made based on these results and your feedback." 
            // If needed, we'll also send you devices like blood pressure monitors and CGMs to gain better insights."
          },
          {
            image: "/img/hh-medication-delivery.png",
            title: "Prescription Medication",
            text: "Medications are shipped to you. All generics are included and we'll help navigate insurance for any brand medications."
          },
          {
            image: "/img/hh-physician-access.png",
            title: "Unlimited Physician Access",
            text: "You can check in with your physician anytime, and they'll always follow up to review test results and answer questions."
          }
        ]
      },
      { type: ProductDetailSection.TIMELINE,
        title: "How it works",
        description: <>Getting started on a path to better heart health just takes 2 minutes.
              <Button 
          type="primary"
          className={`desktop-cta-btn`}
          onClick={onStart}
        >
          {instalabMembership ? 'Upgrade Today' : 'Join Today'}&nbsp;&nbsp;<RightOutlined className="btn-icon" />
        </Button>
        </>,
        data: timeline,
      },
      { type: ProductDetailSection.XGRID,
        title: "See results fast",
        description: "Prevention begins by addressing the key risk factors of heart disease. By following your care plan and adjusting as needed, you can achieve optimal levels within months.",
        className: "grid-60-40",
        data: [
          {
            image: "/img/stat-apob.png",
            title: "Lower Cholesterol",
            text: "Excess cholesterol can accumulate in the arterial walls, leading to plaque formation, which narrows the arteries and increases your risk of heart attack and stroke."
          },
          {
            image: "/img/stat-bp.png",
            title: "Lower Blood Pressure",
            text: "High blood pressure makes the heart work harder, weakening it over time and raising your risk of stroke, heart failure, and chronic kidney disease."
          },
          {
            image: "/img/stat-a1c.png",
            title: "Lower Insulin Resistance",
            text: "Insulin resistance is an early indicator of type 2 diabetes and raises heart disease risk due to its link with higher blood sugar, inflammation, and abnormal lipid levels."
          },
          {
            image: "/img/stat-weight.png",
            title: "Reduce Body Weight",
            text: "Excess body weight, particularly visceral fat, is linked to increased risk of high cholesterol, high blood pressure, and insulin resistance, all of which are major contributors to heart disease."
          }
        ]
      },
      // { type: ProductDetailSection.XPANEL,
      //   title: "Why it works",
      //   description: "Our treatments are effective because they are tailored to meet your specific needs and preferences, combined with frequent testing and monitoring to ensure the best possible outcomes.",
      //   data: [
      //     {
      //       image: "/img/treatment-panel-1.png",
      //       title: "Wide treatment options",
      //       text: "Access a broad range of treatments in a judgment-free zone. Whether you're exploring newer medications like PCSK9i or semaglutide, or prefer to focus on just diet, we'll support your decisions."
      //     },
      //     {
      //       image: "/img/treatment-panel-2.png",
      //       title: "Frequent diagnostic testing",
      //       text: "We consistently track your progress through at-home testing and monitoring, allowing us to make timely adjustments to your treatment based on your results and feedback, ensuring you stay on track."
      //     },
      //     {
      //       image: "/img/treatment-panel-3.png",
      //       title: "Personalized expert guidance",
      //       text: "Receive expert advice from specialized physicians who guide you toward achieving your heart health goals, whether it's managing cholesterol, blood pressure, or overall cardiovascular health."
      //     }
      //   ]
      // },
      { type: ProductDetailSection.TESTIMONIALS,
        title: "Hear from real patients",
        data: [
          {
            name: "Santi Mesa",
            title: "Business Development",
            image: "/img/santi-mesa.jpg", 
            text: "Joining Instalab was one of the best decisions I've made for my health. The personalized approach made all the difference. My physician took the time to understand my unique situation, and their expertise in lipidology and weight management was evident from day one. Everything just flowed smoothly, and I love that I can reach out anytime for advice. I'm seeing real improvements, and it's been worth it."
          },  
          {
            name: "Jason Goldman",
            title: "Film Director",
            image: "/img/jason.png",
            text: "I've been a Heart Health member for several months now, and the transformation has been remarkable. The program is tailored specifically to my needs, and the care I received was anything but one-size-fits-all. My doctor was not only knowledgeable but also incredibly supportive, helping me manage my cholesterol with a customized plan. I feel more in control of my health than ever and the ongoing coaching has been a game-changer."
          },
          {
            name: "Aaron Cheung",
            title: "Founder",
            image: "/img/aaron-square.png",
            text: "I was skeptical at first, but the results don't lie. Since joining Instalab's Heart Health Program, my cholesterol and blood pressure are finally where they should be. The changes fit into my routine easily, and the support from the team made a big difference. They helped me understand what was happening and why it mattered. It's been straightforward, and I'm really happy with how far I've come."
          },
          {
            name: "Kenza El Madih",
            title: "Product Management",
            image: "/img/kenza-el-madih.jpg",
            text: "I've struggled with high blood pressure for years, but Instalab truly turned things around for me. The personalized approach meant that every aspect of my care was tailored specifically to my needs, which made a huge difference. My physician's expertise, combined with their gentle coaching and constant availability, made the entire experience painless. My blood pressure is now under control and I couldn't be happier with the results."
          },

    
      ],
      },
      {
        type: ProductDetailSection.FAQ,
        data: faqs
      }
    ])

  }, [instalabMembership])

  return sections && (

    <div className="heart">

      <HeartIntro/>

      <ProductDetail 
        sections={sections}
      />

        {/* Scroll Button */}
        {showScrollButton && (
        <Button
          type="primary"
          className="scroll-join-btn"
          onClick={onStart}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 999,
          }}
        >
          {instalabMembership ? 'Upgrade Today' : 'Join Today'} <RightOutlined className="btn-icon" />
        </Button>
      )}
    </div>
  )
}

