import { useContext, useState, useEffect } from 'react'
import PosthogHelper from "../../helpers/posthog.helper";
import { Button, Typography, Tabs, Row, Col, Collapse, Affix, Tooltip } from 'antd';
import { UserContext } from "../../contexts/user.context"
import { InfoCircleOutlined, RightCircleFilled } from '@ant-design/icons';
import { TassoPopover } from '../tassoPopover/tassoPopover.component';
import './heartHealthLanding.scss';
import classNames from 'classnames';
import { BiomarkerPopover } from '../biomarkerPopover/biomarkerPopover.component';
import { RedDropPopover } from '../redDropPopover/redDropPopover.component';
import { useNavigate } from 'react-router-dom';
const { Text, Paragraph } = Typography;

export const HeartHealthLanding = () => {
  const { currentUser } = useContext(UserContext)
  const [isTimelineVisible, setIsTimelineVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    PosthogHelper.track(currentUser, `[heart] view landing`, true)
  }, [])

  useEffect(() => {
    const timelineContainer = document.querySelector('.timeline-container');
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsTimelineVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (timelineContainer) {
      observer.observe(timelineContainer);
    }

    return () => {
      if (timelineContainer) {
        observer.unobserve(timelineContainer);
      }
    };
  }, []);

  const scrollToSection = (key) => {
    const element = document.getElementById(`timeline-section-${key}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const TimelineItem = ({ label, title, description, body, img }) => {
    return (
      <div className="timeline-item">
        <Row gutter={60}>
          <Col 
            xs={{ span: 24 }}
            lg={{ span: 0 }}
          >
            <div className="landing-divider timeline-item-divider" />
          </Col>

          <Col 
            xs={{ span: 24 }}
            lg={{ span: 14 }} 
            className="timeline-text-col"
          >
            <div>
              <Paragraph className="timeline-label">
                {label}
              </Paragraph>
              <Paragraph className={classNames("timeline-title", "heart-health-title-2")}>
                {title}
              </Paragraph>
              <Paragraph className={classNames("timeline-description", "heart-health-description")}>
                {description}
              </Paragraph>
              <Paragraph className="timeline-body">
                {body}
              </Paragraph>
            </div>
          </Col>
  
          <Col 
            xs={{ span: 24 }}
            lg={{ span: 10 }}
          >
            <img src={img} className="timeline-img" />
          </Col>
        </Row>
      </div>
    )
  }
  
  const timelineItems = [
    {
      key: '1',
      label: 'Baseline',
      children: (
        <TimelineItem
          label={`DAY 01`}
          title={`Measure Your Baseline`}
          description={<>
            Take a blood test for <BiomarkerPopover trigger={<a>11 biomarkers</a>} /> to assess your cardiovascular health.
          </>}
          img={`/img/timeline-baseline.jpg`}
          body={<>
            <ul className="timeline-list">
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Choose between an at-home test kit or a clinical lab blood draw
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Receive your lab results within 2-3 business days
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Upload previous test results and medical data if available
                  </Col>
                </Row>
              </li>
            </ul>
          </>}
        />
      )
    },
    {
      key: '2',
      label: `Game Plan`,
      children: (
        <TimelineItem
          label={`DAY 04`}
          title={`Meet Your Physician`}
          description={<>
            Using your test results and medical history, your physician will tailor a game plan to lower your cholesterol levels.
          </>}
          img={`/img/timeline-chat.jpg`}
          body={<>
            <ul className="timeline-list">
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    30-minute Zoom call
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Prescription for recommended medication included
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Concierge service to assist with follow-up questions, insurance coverage, and more
                  </Col>
                </Row>
              </li>
            </ul>
          </>}
        />
      )
    },
    {
      key: '3',
      label: 'Iterate',
      children: (
        <TimelineItem
          label={`MONTH 01 - 04`}
          title={`Monthly Follow-Up`}
          description={<>
            Each month, you'll take a follow-up blood test and meet with your physician to review your progress.
          </>}
          img={`/img/timeline-chart.jpg`}
          body={<>
            <ul className="timeline-list">
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Refine game plan based on blood test results
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Prescription change if applicable
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Email correspondence with Instalab medical team and physician
                  </Col>
                </Row>
              </li>
            </ul>
          </>}
        />
      )
    },
    {
      key: '4',
      label: `Graduate`,
      children: (
        <TimelineItem
          label={`MONTH 04`}
          title={`Graduate From the Program`}
          description={<>
            Once we've optimized your cholesterol levels, you may graduate to a maintenance program for $49/month.
          </>}
          img={`/img/timeline-graduate.jpg`}
          body={<>
            <ul className="timeline-list">
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Annual blood test to ensure you're still on track
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    Automatic prescription refills
                  </Col>
                </Row>
              </li>
              <li>
                <Row align="top">
                  <Col>
                    <RightCircleFilled className="timeline-icon" />
                  </Col>
                  <Col>
                    If preferred, we can transfer your prescription
                  </Col>
                </Row>
              </li>
            </ul>
          </>}
        />
      )
    },
  ]

  const tabHeaders = timelineItems.map(item => ({
    key: item.key,
    label: item.label
  }));
  
  const faqItems = [
    {
      key: '1',
      question: `How much does the Heart Health Program cost?`,
      answer: (
        <div>
          The program starts at $599, which includes the following components:
          <ul>
            <li>
              <strong>Monthly blood testing</strong> to track your progress.
            </li>
            <li>
              <strong>Monthly consultations</strong> with your Instalab physician to review test results and adjust your treatment plan.
            </li>
            <li>
              <strong>Prescriptions</strong> if needed—your physician can send them to your local pharmacy or arrange home delivery.
            </li>
            <li>
              <strong>Concierge support</strong> for follow-up questions, test coordination, and more.
            </li>
            <li>
              <strong>Direct access to your medical team</strong> (including your Instalab physician) via email.
            </li>
          </ul>
        </div>
      )
    },
    {
      key: '2',
      question: `How do I get my blood tested?`,
      answer: (
        <div>
          We offer three flexible options—you can choose what works best for you:
          <ul>
            <li>
              <strong>At-home test kit: </strong> You'll receive a kit in the mail, collect your own sample, and schedule a pickup. we use the <RedDropPopover trigger={<a>RedDrop One device</a>} />, which makes this process painless and easy—unlike traditional fingerpricks, which can be messy, painful, and unreliable.
            </li>
            <li>
              <strong>Clinical lab visit: </strong> We'll schedule a lab visit near you. We partner with Quest Diagnostics and other major labs, making this a convenient option, especially in metropolitan areas.
            </li>
            <li>
              <strong>At-home phlebotomy: </strong> An Instalab phlebotomist will come to your home or office for a $99 additional fee. Most of our patients prefer this option for its unmatched convenience.
            </li>
          </ul>
        </div>
      )
    },
    {
      key: '3',
      question: `What are Instalab physicians like?`,
      answer: (
        <div>
          Instalab physicians are specialists in lipidology—the study of cholesterol and its impact on heart health and longevity. Unlike general practitioners, they have deep expertise in optimizing lipid levels and reducing cardiovascular risk. Our physicians don't just understand the science; they apply it daily, working closely with patients to achieve measurable results.
        </div>
      )
    },
    {
      key: '4',
      question: `Does insurance cover the Heart Health Program?`,
      answer: (
        <div>
          Most insurance plans do not cover the program, but you can use HSA/FSA funds to pay. We'll also provide an itemized receipt for reimbursement purposes.
          <br /><br />
          As for medication, most of the prescriptions we recommend are generic and very affordable. If you and your physician decide on a less standard approach (e.g., PCSK9 inhibitors), our concierge team will assist with navigating insurance coverage, saving you time and increasing your chances of approval.
        </div>
      )
    },
    {
      key: '5',
      question: `Will I be prescribed medication?`,
      answer: (
        <div>
          At Instalab, we believe that diet, exercise, and supplements can play a significant role in reducing choleserol levels. However, we also recognize that medication may be necessary for some patients to reach optimal heart health.
          <br /><br />
          If medication is recommended or preferred, your Instalab physician will ensure a safe, stepwise approach, starting with the lowest effective dose and adjusting as needed based on follow-up blood tests. We commonly prescribe: 
          <ul>
            <li>Statins</li>
            <li>Ezetimibe</li>
            <li>PCSK9 inhibitors</li>
            <li>Inclisiran</li>
            <li>Bempedoic acid</li>
          </ul>
        </div>
      )
    },
    {
      key: '6',
      question: `Is the Heart Health Program available in my city?`,
      answer: (
        <div>
          If you live in the United States, you can enroll in the program. If you travel frequently or are planning to spend extended time abroad, we'll coordinate blood testing and medication delivery to fit your schedule.
        </div>
      )
    },
    {
      key: '7',
      question: `What if I already have a physician?`,
      answer: (
        <div>
          That's completely fine—and actually quite common. Many of our patients are already working with another doctor but seek more specialized care or better results.
          <br /><br />
          Your Instalab physician can:
          <ul>
            <li>Work with your existing treatment plan and adjust as needed.</li>
            <li>Communicate with your current doctor, if you'd like.</li>
          </ul>
          We respect your existing healthcare relationships and will tailor our involvement based on your needs.
        </div>
      )
    },
    {
      key: '8',
      question: `I have another question.`,
      answer: (
        <div>
          We're passionate about helping people achieve optimal heart health–whether or not they're enrolled in the program. If you have a question, need guidance, or just want to explore your options, <a onClick={onChat}>reach out</a>! We're happy to chat.
        </div>
      )
    },
  ];

  const onStart = () => {
    PosthogHelper.track(currentUser, `[heart] click signup cta`, true)
    navigate('/flow/heart-health')
  }

  return (
    <div className="heart-health-landing">
      <div className={classNames("intro-container", "heart-health-section")}>
        <Row 
          gutter={72}
        >
          <Col 
            lg={14} 
            xs={24}
            className="intro-text-col"
          >
            <div>
              <Tooltip 
                title="Medication costs are not included in the program fee. If your insurance does not cover your medications, we will find you the best savings to pay out of pocket."
              >
                <div className="intro-price">
                  Starting at $599  
                  <InfoCircleOutlined className="price-info-icon" />
                </div>
              </Tooltip>
              <Paragraph className="heart-health-title">
                Save yourself from a heart attack or stroke in 4 months.
              </Paragraph>
              <Paragraph className="heart-health-description">
                Get coached by a physician dedicated to lowering your cardiovascular risk through lifestyle adjustments, medication, and more.
              </Paragraph>
              <Button 
                className={classNames("heart-health-btn", "intro-btn", "start-btn")}
                type="primary"
                onClick={onStart}
              >
                Get Started
              </Button>

              <Button 
                className={classNames("heart-health-btn", "intro-btn", "learn-btn")}
                onClick={() => {
                  document.querySelector('.timeline-container').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Learn More
              </Button>
            </div>
          </Col>

          <Col 
            lg={10}
            xs={0}
            className="intro-img-col"
          >
            <img 
              src="/img/heart-health-intro.jpg" 
              className="intro-img"
            />
          </Col>
        </Row>
      </div>
      
      <div className="stats-container">
        <div className={classNames("heart-health-section")}>
          <Row gutter={24}>
          <Col 
            lg={6}
            xs={12}
          >
              <div className="stat-item">
                <Paragraph className="stat-value">
                  1.3k+
                </Paragraph>
                <Paragraph className="stat-label">
                  Patients who graduated from the program in the last month
                </Paragraph>
              </div>
            </Col>

            <Col 
              lg={6}
              xs={12}
            >
              <div className="stat-item">
                <Paragraph className="stat-value">
                  96.7%
                </Paragraph>
                <Paragraph className="stat-label">
                  Patients who graduated with optimal cholesterol levels
                </Paragraph>
              </div>
            </Col>
            
            <Col 
              lg={6}
              xs={12}
            >
              <div className="stat-item">
                <Paragraph className="stat-value">
                  8.7k+
                </Paragraph>
                <Paragraph className="stat-label">
                  Blood tests completed to verify improved cholesterol levels
                </Paragraph>
              </div>
            </Col>
            
            <Col 
              lg={6}
              xs={12}
            >
              <div className="stat-item">
                <Paragraph className="stat-value">
                  99.8%
                </Paragraph>
                <Paragraph className="stat-label">
                  Patients reported they were highly satisfied after graduating
                </Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div 
        className={classNames("timeline-container", "heart-health-section")} 
      >
        <div className="timeline-header">
          <Paragraph className="heart-health-title">
            The Timeline
          </Paragraph>
          <Paragraph className="heart-health-description">
            A 4-month program designed to help you reach healthy cholesterol levels and establish a routine that will support your heart health for life.
          </Paragraph>
        </div>
        {isTimelineVisible && (
          <Affix offsetTop={0}>
            <Tabs 
              defaultActiveKey="1"
              onChange={scrollToSection}
              items={tabHeaders}
              className="timeline-tabs"
            />
          </Affix>
        )}
        <div 
          className="timeline-sections"
        >
          {timelineItems.map(item => (
            <div 
              key={item.key} 
              id={`timeline-section-${item.key}`}
              className="timeline-section"
            >
              {item.children}
            </div>
          ))}
        </div>
      </div>

      <div className="testimonial-section">
        <div className="heart-health-section">
          <Row>
            <Col 
              xs={{ span: 24, order: 1 }}
              md={{ span: 7, order: 2 }} 
              className="testimonial-img-col"
            >
              <img 
                src="img/ken.jpg" 
                className="testimonial-img" 
              />
            </Col>

            <Col 
              xs={{ span: 24, order: 2 }}
              md={{ span: 17, order: 1 }} 
              className="testimonial-content-col"
            >
              <div className="testimonial-content">
                <div className="testimonial-author-container">
                  <Text className="testimonial-author">
                    Ken Falk
                  </Text>
                  <Text className="testimonial-role">
                    , New York
                  </Text>
                </div>

                <Paragraph className="testimonial-text">
                  "I spent many years believing misinformation online about the role of high ApoB in heart disease. When I finally broke free from that delusion, I realized that the scientific evidence overwhelmingly supports its causal link beyond any reasonable doubt. In my search for ways to assess and treat my risk factors, I came across Instalab.
                  <br/><br/>
                  The experience has been truly life-changing.
                  <br/><br/>
                  Over several months of testing and tweaking my medication, I've lowered my ApoB to 60 mg/dL—placing me in a very low-risk category. The sense of relief is incredible, knowing I've addressed a major threat that kills so many of us prematurely and unnecessarily.
                  <br/><br/>
                  Beyond that, Instalab helped me tackle other risk factors, including low B12 and B9 levels, which had been driving up my homocysteine—a key contributor to cardiovascular disease. I believe this intervention also cured the chronic joint issues that plagued me for decades. 
                  <br/><br/>
                  It's clear that this kind of preventative medicine—going beyond just treating existing illness—is the future of healthcare."
                </Paragraph>
                
                <Paragraph className="testimonial-date">
                  GRADUATED&nbsp;&nbsp;•&nbsp;&nbsp;FEB 2024
                </Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      
      <div className="faq-section heart-health-section">
        <Paragraph className="heart-health-title">
          Frequently Asked Questions
        </Paragraph>
        <Collapse
          bordered={false}
          className="faq-collapse"
        >
          {faqItems.map(({ key, question, answer }) => (
            <Collapse.Panel header={question} key={key}>
              {answer}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>

      <div className="trusted-by">
        <div className="heart-health-section">
          <Paragraph className={classNames("brands-title", "heart-health-title")}>
            Join <span className="highlight-text">2,000+ patients</span> like you{' '}
            <br className="desktop-only"/>
            who've added healthy years to their lives.
          </Paragraph>

          <Paragraph className={classNames("brands-description", "heart-health-description")}> 
            Professionals, athletes, and high-performers are all using Instalab to optimize their cholesterol levels and reduce their cardiovascular risk—without disrupting their busy lives.
          </Paragraph>

          <Button 
            className={classNames("heart-health-btn", "brands-button")}
            type="primary"
            onClick={onStart}
          >
            Get Started
          </Button>

          <Button 
            className={classNames("heart-health-btn", "msg-btn")}
            onClick={onChat}
            icon={<InfoCircleOutlined />}
          >
            I Have a Question
          </Button>
        </div>

        <div className="logo-scroll">
          <div className="logo-scroll-content">
            <img src="/img/logos/mlb.png" alt="MLB" />
            <img src="/img/logos/google.png" alt="Google" />
            <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
            <img src="/img/logos/linkedin.png" alt="LinkedIn" />
            <img src="/img/logos/nfl.png" alt="NFL" />
            <img src="/img/logos/amazon.png" alt="Amazon" />
            <img src="/img/logos/uber.png" alt="Lyft" />
          </div>
          <div className="logo-scroll-content">
            <img src="/img/logos/mlb.png" alt="MLB" />
            <img src="/img/logos/google.png" alt="Google" />
            <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
            <img src="/img/logos/linkedin.png" alt="LinkedIn" />
            <img src="/img/logos/nfl.png" alt="NFL" />
            <img src="/img/logos/amazon.png" alt="Amazon" />
            <img src="/img/logos/uber.png" alt="Lyft" />
          </div>
        </div>
      </div>
    </div>
  )
};
