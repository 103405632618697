import { Modal, Typography } from 'antd'
import ReactPlayer from "react-player";
import './redDropModal.scss'
import { useRef } from 'react';

const { Paragraph } = Typography

export const RedDropModal = ({ open, setOpen }) => {
  const playerRef = useRef()

  const onCancel = () => {
    setOpen(false)
  }

  return open && (
    <Modal
      open={open}
      onCancel={onCancel}
      className="reddrop-modal"
      footer={null}
      width={500}
    >
      <Paragraph className="reddrop-title">
        RedDrop One: A Painless Blood Test
      </Paragraph>
      <Paragraph className="reddrop-description">
      This device is a more comfortable and convenient alternative to traditional finger pricks. It uses microneedles and gentle suction to draw blood from the skin’s surface, avoiding the pain and bruising associated with finger pricks.
      </Paragraph>

      <div className="reddrop-video">
        <ReactPlayer
          ref={playerRef}
          url={'https://youtube.com/shorts/t1Y7yLk3Dl0'}
          controls={true}
          width="100%"
          playing={true}
          playbackRate={1.33}
        />
      </div>
    </Modal>
  )
}