
import './home.scss'
import { listProductTypes } from '../../services/productType.service';
import RecommendationHelper from '../../helpers/recommendation.helper';
import { useContext, useRef, useState, useEffect, useLayoutEffect } from 'react'
import { Typography, Row, Col, Button, Select, Tooltip, Carousel, Input, Progress, Tabs, Skeleton, message, Spin  } from 'antd'
import { InfoCircleOutlined, CheckCircleOutlined, ArrowRightOutlined } from '@ant-design/icons'
import PosthogHelper from '../../helpers/posthog.helper'
import { UserContext } from '../../contexts/user.context'
import { useNavigate } from 'react-router-dom'
import FlowType from '../../enums/flowType.enum'
import { updateMe } from '../../services/user.service'
import dayjs from 'dayjs'
import PhotoHelper from '../../helpers/photo.helper';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { getNearbyFacilities } from '../../services/facility.service';
import TestCode from '../../enums/testCode.enum';
import useWidth from '../../hooks/useWidth.hook';
import classNames from 'classnames';
import Breakpoint from '../../enums/breakpoint.enum';
import { motion, AnimatePresence } from "framer-motion";

const { Text, Paragraph } = Typography
const MAX_ATTEMPTS = 100

const videos = [
  '/video/galleri-min.m4v',
  '/video/derm-min.m4v',
  '/video/statin-min.m4v',
];

const biomarkerDescriptions = {
  [TestCode.APO_B]: 'ApoB is found in harmful cholesterol particles that contribute to plaque buildup. Higher levels mean more particles are present, increasing the risk of blocked arteries.',
  [TestCode.LIPOPROTEIN_A]: 'A genetically determined cholesterol particle that increases blood clotting and promotes plaque formation. High levels significantly raise the risk of cardiovascular disease.',
  [TestCode.HBA1C]: 'Reflects average blood sugar levels over the past 2-3 months. Chronically high HbA1c damages blood vessels, making them more prone to narrowing and blockages.',
  [TestCode.HS_CRP]: 'A marker of systemic inflammation. Elevated levels indicate increased stress on blood vessels, which can accelerate plaque formation and destabilization.'
};


const productTypeValues = {
  [ProductTypeCode.LONGEVITY_PANEL]: {
    tagline: `A blood test that detects hidden risks and early warning signs of disease in all your body's key systems—cardiovascular, metabolic, liver, kidneys, thyroid, and more.`,
    category: `Full-Body`,
    photos: [{
      jpg: `/img/home/longevity.jpg`
    }],
  },
  [ProductTypeCode.CTCALCIUM]: {
    tagline: `Scanning the arteries in your heart for calcium buildup. This calcium is a sign of plaque, which can narrow or block your arteries, increasing your risk of a heart attack.`,
    category: `Heart Health`
  },
  [ProductTypeCode.CLEERLY]: {
    tagline: `The most accurate heart health test available. This scan uses high-resolution, AI-enhanced imaging to detect blockages in your arteries before symptoms appear.`,
    photos: [{
      jpg: `/img/home/ct-procedure.jpg`
    }],
    category: `Heart Health`
  },
  [ProductTypeCode.DEXA_BODYCOMP]: {
    tagline: `The gold standard for measuring body composition. This imaging procedure tracks fat distribution, muscle mass, and bone density—helping you assess overall health.`,
    category: `Full-Body`,
    photos: [{
      jpg: `/img/home/dexa-body-composition.jpg`
    }],
  },
  [ProductTypeCode.DEXA_BONE]: {
    category: `Bone Health`,
    tagline: `An imaging test detecting osteoporosis early and fracture risk. It measures bone mineral density to help prevent bone loss and monitor overall skeletal health.`,
    photos: [{
      jpg: `/img/home/dexa-bone.jpg`
    }],
  },
  [ProductTypeCode.SKIN_CANCER_EXAM]: {
    category: `Cancer`,
    tagline: `A head-to-toe screening to identify melanoma and other skin cancers early. This quick, non-invasive exam detects suspicious moles or lesions before they become serious.`,
    photos: [{
      jpg: `/img/home/full-body-skin-exam.jpg`
    }],
  },
  [ProductTypeCode.GALLERI]: {
    category: `Cancer`,
    tagline: `Screening for 50+ types of cancer—even before symptoms appear. By identifying DNA signals shed by cancer cells, this test helps catch cancer at its earliest stage.`,
  },
  [ProductTypeCode.FIT_STOOL_TEST_KIT]: {
    category: `Cancer`,
    tagline: `A quick and simple at-home test that detects hidden blood in your stool—a key indicator for catching colorectal cancer and other gastrointestinal conditions early.`,
    photos: [{
      jpg: `/img/home/fit.jpg`
    }],
  },
  [ProductTypeCode.COLONOSCOPY]: {
    category: `Cancer`,
    tagline: `This camera-based exam detects and removes polyps in the colon, helping to prevent colon cancer. It's an effective method for identifying precancerous growths early.`,
    photos: [{
      jpg: `/img/home/colonoscopy.jpg`
    }],
  },
  [ProductTypeCode.MAMMOGRAM]: {
    category: `Cancer`,
    tagline: `A specialized X-ray detecting breast cancer in its earliest stages. This screening helps identify abnormal tissue changes, significantly improving treatment outcomes.`,
    photos: [{
      jpg: `/img/home/mammogram.jpg`
    }]
  },
  [ProductTypeCode.HPV_TEST]: {
    category: `Cancer`,
    tagline: `This combined test detects abnormal cervical cells and high-risk HPV strains—the leading cause of cervical cancer. Early detection helps prevent serious health issues.`,
    photos: [{
      jpg: `/img/home/pap-smear.jpg`
    }],
  },
  [ProductTypeCode.PAP_SMEAR]: {
    category: `Cancer`,
    tagline: `A test that detects precancerous changes and helps prevent cervical cancer. This quick, routine screening identifies abnormalities early for timely intervention.`,
    photos: [{
      jpg: `/img/home/pap-smear.jpg`
    }],
  },
  [ProductTypeCode.DRE]: {
    category: `Cancer`,
    tagline: `A quick physical exam used to evaluate the prostate for abnormalities and detect potential issues early, including signs of prostate cancer.`,
    photos: [{
      jpg: `/img/home/dre.jpg`
    }],
  },
  [ProductTypeCode.HEART_HEALTH_PANEL]: {
    title: `Heart Health Blood Panel`,
    category: `Heart Health`,
    tagline: <>
      An advanced blood test that accurately predicts your risk of heart attack and stroke by measuring key biomarkers like{' '}
      <Tooltip title={biomarkerDescriptions[TestCode.APO_B]}>
        <Text className="biomarker-link">ApoB</Text>
      </Tooltip>
      ,{' '}
      <Tooltip title={biomarkerDescriptions[TestCode.LIPOPROTEIN_A]}>
        <Text className="biomarker-link">Lp(a)</Text>
      </Tooltip>
      ,{' '}
      <Tooltip title={biomarkerDescriptions[TestCode.HBA1C]}>
        <Text className="biomarker-link">HbA1c</Text>
      </Tooltip>
      ,{' '}
      <Tooltip title={biomarkerDescriptions[TestCode.HS_CRP]}>
        <Text className="biomarker-link">hs-CRP</Text>
      </Tooltip>
      , and more.
    </>,
    photos: [{
      jpg: `/img/heart-health-box.png`
    }],
  },
  [ProductTypeCode.TESTOSTERONE_PANEL]: {
    category: `Hormonal Health`,
    tagline: `A blood test that identifies the causes of changes in energy, muscle mass, libido, and mood. Measures total and free testosterone plus key related proteins.`,
    photos: [{
      jpg: `/img/home/testosterone.jpg`
    }],
  },
  // [ProductTypeCode.FERTILITY_PANEL]: {
  //   category: `Hormonal Health`,
  //   tagline: `A blood test that measures hormones and other fertility indicators to help you understand your reproductive health.`,
  //   photos: [{
  //     jpg: `/img/home/fertility.jpg`
  //   }],
  // },
  [ProductTypeCode.WATCHPAT_ONE]: {
    category: `Sleep Health`,
    tagline: `An at-home sleep test that detects sleep apnea and measures sleep quality. Tracks breathing, oxygen levels, heart rate, and sleep stages for an accurate diagnosis.`,
  },
}

export const Home = () => {
  const navigate = useNavigate()
  const videoRefs = useRef([]);
  const carouselRef = useRef();
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState(null);
  const [productTypes, setProductTypes] = useState([]);
  const [familyHistory, setFamilyHistory] = useState([]);
  const [postalCode, setPostalCode] = useState('');
  const [searchProgress, setSearchProgress] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [categories, setCategories] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all');
  const tabsContainerRef = useRef(null);
  const [visibleCategories, setVisibleCategories] = useState(5);
  const width = useWidth()
  const [itemWidth, setItemWidth] = useState('33.333%');

  const perks = [{
    title: `No Service Fees`,
    description: `Skip the $25 fee for scheduling appointments`,
  }, {
    title: `Personal Health Concierge`,
    description: `Explaining test results, referring top specialists, and more`,
  }, {
    title: `Physician Consultations`,
    description: `Review test results with a medical specialist for an additional $99 fee`,
  }, {
    title: `Annual Member Benefits`,
    description: <>
      1 free <a onClick={() => {
        PosthogHelper.track(currentUser, `[home] clicked instalab blood panel perk`, true)
        navigate(`/longevity-panel`)
      }} className="perk-link">Instalab Blood Panel</a> + 1 free physician consultation
    </>,
  }]

  const how = [{
    title: `Choose Your Test`,
    description: `We recommend the most important tests for your health based on age, sex, and family history—but you're free to choose any test that interests you.`,
  }, {
    title: `Schedule Your Test`,
    description: `We'll find the most affordable nearby facility and schedule your test. For blood tests, we offer at-home phlebotomy and self-collection kits.`,
  }, {
    title: `Test Results + Analysis`,
    description: `Get results fast—typically within a week. Our team of medical specialists will give you a detailed analysis with clear, actionable steps to improve your health.`,
  }, ]

  useEffect(() => {
    document.title = `Instalab | Find the Tests That Could Save Your Life`
  }, [])

  useEffect(() => {
    if (currentUser && !productTypes?.length) {
      onInitSearch()
    }
  }, [currentUser]);

  const onInitSearch = async () => {
    try {
      await fetchRecommendations()
    } catch (err) {
      console.log(err)
      message.error(err.response?.data?.message || 'Failed to search facilities')
    }
  }

  useLayoutEffect(() => {
    const updateVisibleTabs = () => {
      if (!tabsContainerRef.current) return;
      
      const containerWidth = tabsContainerRef.current.offsetWidth;
      // Adjusted width calculation: 
      // 80px for "All" tab + ~100px per category tab + some padding
      const approximateTabWidth = 100;
      const allTabWidth = 80;
      const availableWidth = containerWidth - allTabWidth;
      const possibleTabs = Math.floor(availableWidth / approximateTabWidth);
      setVisibleCategories(Math.max(1, Math.min(possibleTabs, categories.length)));
    };

    updateVisibleTabs();
    window.addEventListener('resize', updateVisibleTabs);
    return () => window.removeEventListener('resize', updateVisibleTabs);
  }, [categories.length]);

  useEffect(() => {
    if (width < Breakpoint.MD) {
      setItemWidth('100%');
    } else if (width < Breakpoint.LG) {
      setItemWidth('50%');
    } else {
      setItemWidth('33.333%');
    }
  }, [width]);

  const fetchRecommendations = async (user) => {
    user = user || currentUser

    const searchAttempts = user.searchAttempts?.length ? user.searchAttempts : []
    user = await updateMe({
      fields: {
        searchAttempts: [
          ...searchAttempts,
          {
            createdAt: new Date()
          }
        ]
      }
    })

    if (user.dob) {
      const userAge = dayjs().diff(dayjs(user.dob), 'year');
      setAge(userAge >= 60 ? 60 : userAge);
    } else if (user.age) {
      setAge(user.age);
    }
    
    if (user.gender) {
      setGender(user.gender);
    }
    
    if (user.familyHistory) {
      setFamilyHistory(user.familyHistory);
    }

    if (user.location?.postalCode) {
      setPostalCode(user.location.postalCode);
    }

    setSearchProgress(20);

    const recommendations = RecommendationHelper.getRecommendations(user);

    const productTypeTitles = recommendations.map(recommendation => {
      return recommendation.test
    });

    
    const hasNoValues = !user.age && !user.gender && !user.familyHistory?.length
    const defaultProductTypes = [
      ProductTypeCode.LONGEVITY_PANEL, // 600
      ProductTypeCode.CTCALCIUM, // 104
      ProductTypeCode.HEART_HEALTH_PANEL, // 66
      ProductTypeCode.DEXA_BODYCOMP, // 44
      ProductTypeCode.GALLERI, // 32
      ProductTypeCode.TESTOSTERONE_PANEL, // 19
      ProductTypeCode.WATCHPAT_ONE, // 17
      ProductTypeCode.CLEERLY, // 13
      ProductTypeCode.FIT_STOOL_TEST_KIT, // 11
      // ProductTypeCode.FERTILITY_PANEL, // 10
    ]
    const productTypeCodes = hasNoValues ? defaultProductTypes : productTypeTitles
    let productTypesResponse = await listProductTypes({
      filter: {
        code: { $in: productTypeCodes }
      }
    });

    // Sort the response to match productTypeCodes order
    productTypesResponse.sort((a, b) => {
      const indexA = productTypeCodes.indexOf(a.code);
      const indexB = productTypeCodes.indexOf(b.code);
      return indexA - indexB;
    });

    const NO_FACILITY_SEARCH = [
      ProductTypeCode.LONGEVITY_PANEL,
    ];

    // Replace Promise.all with sequential processing
    const productsWithValues = [];
    const progressPerProduct = 40 / productTypesResponse.length;

    for (let i = 0; i < productTypesResponse.length; i++) {
      const productType = productTypesResponse[i];
      let cheapestFacility = null;
      
      const searchAttempts = user.searchAttempts?.filter(attempt => new Date(attempt.createdAt) > new Date(Date.now() - 1000 * 60 * 60))?.length

      if (searchAttempts <= MAX_ATTEMPTS && user.location?.postalCode && productType.hasFacility && !NO_FACILITY_SEARCH.includes(productType.code)) {
        const coordinates = await getCoordinatesFromZip(user.location.postalCode);

        const facilities = await getNearbyFacilities({
          ...coordinates,
          productTypeCode: productType.code,
          maxDuration: 120
        });

        // First filter facilities that have this product type with a price
        const facilitiesWithProduct = facilities?.filter(facility => 
          facility.menu?.some(item => 
            item.productType._id === productType._id && 
            item.price != null && 
            item.price > 0
          )
        )
        
        cheapestFacility = facilitiesWithProduct?.length > 0 
          ? facilitiesWithProduct.reduce((cheapest, facility) => {
              // Find the price for this product type in current facility
              const currentPrice = facility.menu?.find(item => 
                item.productType._id === productType._id
              )?.price || Infinity;
              
              // Find the price for this product type in cheapest facility so far
              const cheapestPrice = cheapest.menu?.find(item => 
                item.productType._id === productType._id
              )?.price || Infinity;
              
              // Compare and return the facility with lower price
              return currentPrice < cheapestPrice ? facility : cheapest;
            }, facilitiesWithProduct[0])
          : null;
      }

      let values = {
        ...productType,
        ...(productTypeValues[productType.code] || {}),
      };

      if (productType.acceptsInsurance) {
        values.cost = <span className="insurance-accepted">Insurance</span>
        values.costInfo = `We'll submit your test to your insurance provider for reimbursement.`
      } else if (cheapestFacility) {
        values.facility = cheapestFacility;
        values.cost = formatCurrency(cheapestFacility.menu.find(item => item.productType._id === productType._id)?.price);
        values.costInfo = `${cheapestFacility.name} has the lowest price for this test near you.`;
      } else if (!NO_FACILITY_SEARCH.includes(productType.code) && productType.lowestPrice && productType.highestPrice) {
        values.cost = `${formatCurrency(productType.lowestPrice)} - ${formatCurrency(productType.highestPrice)}`
        values.costInfo = `Prices vary by location. Enter your postal code for more accurate pricing.`
      } else {
        values.cost = formatCurrency(values.cost)
      }

      productsWithValues.push(values);

      // Update progress for each product processed
      setSearchProgress(20 + (progressPerProduct * (i + 1)));
    }

    // Get categories and their counts
    const categoryCount = productsWithValues.reduce((acc, productType) => {
      if (productType.category) {
        acc[productType.category] = (acc[productType.category] || 0) + 1;
      }
      return acc;
    }, {});

    // Convert to array, sort by count, and extract just category names
    const sortedCategories = Object.entries(categoryCount)
      .sort(([categoryA], [categoryB]) => {
        // Find the first occurrence of each category in productWithValues
        const indexA = productsWithValues.findIndex(product => product.category === categoryA);
        const indexB = productsWithValues.findIndex(product => product.category === categoryB);
        return indexA - indexB; // Sort by original order
      })
      .map(([category]) => category)
      .filter(Boolean);

    setCategories(sortedCategories);
    
    setProductTypes(productsWithValues);

    setSearchProgress(100);
    
    await sleep(500)

    setTimeout(() => {
      setIsSearching(false);
      setSearchProgress(0);
    }, 500);
  }

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const onVideoEnded = (index) => {
    if (carouselRef.current) {
      if (index === videos.length - 1) {
        carouselRef.current.goTo(0);
      } else {
        carouselRef.current.next();
      }
    }
  };

  const onSlideChange = (current) => {
    videoRefs.current.forEach(video => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });

    if (videoRefs.current[current]) {
      videoRefs.current[current].play();
    }
  };

  const onJoinMembership = () => {
    PosthogHelper.track(currentUser, `[home] clicked join membership`, true)
    navigate(`/flow/${FlowType.MEMBER}`)
  }

  const handleSearch = async () => {
    setIsSearching(true);
    setSearchProgress(0);

    try {
      // Validate postal code format (US zip codes are 5 digits or 5+4 format)
      const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      if (postalCode && !zipCodeRegex.test(postalCode)) {
        message.error('Please enter a valid US postal code');
        setIsSearching(false);
        setSearchProgress(0);
        return;
      }

      setSearchProgress(10);

      const values = {
        gender,
        familyHistory,
        age,
        postalCode
      };

      const searchAttempts = currentUser?.searchAttempts?.length ? currentUser?.searchAttempts : []
      
      const response = await updateMe({
        fields: {
          gender: values.gender,
          familyHistory: values.familyHistory,
          age: values.age,
          location: {
            ...currentUser?.location,
            postalCode: values.postalCode
          },
        }
      });
      
      PosthogHelper.track(currentUser, `[home] searched`, true)
      setCurrentUser(response)
      try {
        await fetchRecommendations(response)
        document.querySelector('.recommended-section')?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      } catch (err) {
        message.error(err.response?.data?.message || 'Failed to search facilities')
        setIsSearching(false);
        setSearchProgress(0);
      }
    } catch (error) {
      console.error('Search error:', error);
      message.error('An error occurred while searching. Please try again.');
      setIsSearching(false);
      setSearchProgress(0);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const getCoordinatesFromZip = async (zip) => {
    try {
      const response = await fetch(`https://api.zippopotam.us/us/${zip}`);
      const data = await response.json();
      if (data && data.places && data.places[0]) {
        return {
          latitude: parseFloat(data.places[0].latitude),
          longitude: parseFloat(data.places[0].longitude)
        };
      }
      return null;
    } catch (error) {
      console.error('Error getting coordinates from zip:', error);
      return null;
    }
  };

  // Filter products based on selected category
  const filteredProducts = activeFilter === 'all' 
    ? productTypes
    : productTypes.filter(product => product.category === activeFilter);


  const getResponsiveGutter = () => {
    if (width >= Breakpoint.XXL) {
      return 60;  // Wide desktops
    } else {
      return 90;  // Regular desktops and large tablets
    }
  };

  return (
    <div className="home">
      <div className="home-intro">
        <Row gutter={getResponsiveGutter()}>
          <Col 
            xs={24} 
            sm={24} 
            md={13} 
            lg={13} 
            xl={13} 
            xxl={12}
          >
            <div className="intro-text-container">
              <Paragraph className="home-h1 intro-h1">
                Find the tests that could save your life.
              </Paragraph>
              <Paragraph className="home-p1 intro-p1">
                Stay ahead of heart disease, cancer, and more—because timing is everything. Detect health risks before symptoms appear and take action while it still counts.
              </Paragraph>
            </div>

            <Progress 
              percent={searchProgress} 
              status={searchProgress === 100 ? "success" : "active"}
              strokeColor={'#0ca37f'}
              showInfo={false}
              className={`search-progress ${isSearching ? 'searching' : ''}`}
            />
            <div className="home-search">
              <Row gutter={16} align="bottom">
                <Col flex="auto">
                  <Row gutter={[16, 16]}>
                    {[
                      {
                        label: "Age",
                        component: (
                          <Select
                            className="search-select"
                            placeholder="Select age"
                            value={age}
                            onChange={setAge}
                            options={[
                              { value: 21, label: '18-29' },
                              { value: 30, label: '30-39' },
                              { value: 40, label: '40-49' },
                              { value: 50, label: '50-59' },
                              { value: 60, label: '60+' }
                            ]}
                          />
                        )
                      },
                      {
                        label: "Biological Sex",
                        component: (
                          <Select
                            className="search-select"
                            placeholder="Select biological sex"
                            value={gender}
                            onChange={setGender}
                            options={[
                              { value: 'Male', label: 'Male' },
                              { value: 'Female', label: 'Female' },
                            ]}
                          />
                        )
                      },
                      {
                        label: (
                          <>
                            Postal Code
                            <Tooltip title="We use your location to find the most affordable tests near you.">
                              <InfoCircleOutlined className="info-icon" />
                            </Tooltip>
                          </>
                        ),
                        component: (
                          <Input
                            className="search-input"
                            placeholder="Enter postal code"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        )
                      },
                      {
                        label: (
                          <>
                            Family History
                            <Tooltip title="Which of these medical conditions has anyone in your immediate family had before age 60? ">
                              <InfoCircleOutlined className="info-icon" />
                            </Tooltip>
                          </>
                        ),
                        component: (
                          <Select
                            className="search-select"
                            placeholder="Select family history"
                            mode="multiple"
                            maxTagCount={0}
                            value={familyHistory}
                            onChange={setFamilyHistory}
                            maxTagPlaceholder={(selectedOptions) => `${selectedOptions.length} selected`}
                            showSearch={false}
                            options={[
                              `Cancer`,
                              `Dementia / Alzheimer's`,
                              `Diabetes`,
                              `Heart Attack`,
                              `Heart Disease`,
                              `Osteoporosis`,
                              `Stroke`,
                            ].map(option => ({
                              value: option,
                              label: option,
                            }))}
                          />
                        )
                      }
                    ].map((field, index) => (
                      <Col 
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        key={index}
                      >
                        <Text className="search-label">{field.label}</Text>
                        {field.component}
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col 
                  className="search-btn-col"
                >
                  <Button 
                    type="primary"
                    className="search-btn"
                    onClick={handleSearch}
                    loading={isSearching}
                    block={width < 768}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs={0} 
            sm={0} 
            md={11} 
            lg={11} 
            xl={11} 
            xxl={12}
          >
            <img 
              src="/img/home/intro.jpg" 
              className={classNames("intro-img", width >= Breakpoint.XXL ? 'intro-img-lg' : 'intro-img-sm')}
            />
          </Col>
        </Row>
      </div>

      <div className="home-section how-section">
        <Row gutter={30}>
          {how.map(({ title, description }, index) => (
            <Col 
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
              key={`how-${index}`}
              className="how-section-item"
            >
              <Row>
                <Col
                  xs={4}
                  sm={4}
                  md={24}
                  xl={24}
                  xxl={24}
                >
                  <Paragraph className="how-number">
                    {index + 1}
                  </Paragraph>
                </Col>
                <Col
                  xs={20}
                  sm={20}
                  md={24}
                  xl={24}
                  xxl={24}
                >
                  <Paragraph className="how-title">
                    {title}
                  </Paragraph>
                  <Paragraph className="how-description">
                    {description}
                  </Paragraph>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </div>

      {filteredProducts?.length > 0 ? (
        <div className="recommended-section">
          <div className="home-section"> 
            <div className="recommended-header">
              <Paragraph className="home-h1 recommended-h1">
                {(!currentUser?.age && !currentUser?.gender && !currentUser?.familyHistory?.length) ? `Popular Tests` : `Recommended`}
              </Paragraph>

              <div className="recommended-filter" ref={tabsContainerRef}>
                <Tabs
                  activeKey={activeFilter}
                  onChange={setActiveFilter}
                  className="recommended-filter-tabs"
                  items={[
                    {
                      key: 'all',
                      label: 'ALL',
                    },
                    ...categories.slice(0, visibleCategories).map(category => ({
                      key: category,
                      label: category.toUpperCase(),
                    }))
                  ]}
                />
              </div>
            </div>

            <div className="recommended-list">
              <Row gutter={24}>
                <AnimatePresence>
                  {filteredProducts.map(productType => (
                    <motion.div
                      layout
                      key={`product-type-${productType._id}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ width: itemWidth, padding: '0 12px', marginBottom: '24px' }}
                    >
                      <div className="recommended-item">
                        {productType.photos?.length > 0 ? 
                          PhotoHelper.format({
                            jpg: productType.photos[0].jpg,
                            png: productType.photos[0].png,
                            webp: productType.photos[0].webp,
                            className: 'recommended-item-image',
                          })
                          :
                          <div className="recommended-item-image">
                            <Skeleton.Image 
                              active={true}
                            />
                          </div>
                        }
                        <div className="recommended-item-content">
                          <Paragraph className="recommended-item-title">{productType.title}</Paragraph>
                          {productType.category && (
                            <span className="recommended-item-category">{productType.category}</span>
                          )}
                          <Paragraph className="recommended-item-tagline">{productType.tagline}</Paragraph>

                          <div className="recommended-item-footer">
                            <Text className="recommended-item-cost">
                              {productType.cost}
                            </Text>

                            {productType.costInfo && (
                              <Tooltip title={productType.costInfo}>
                                <InfoCircleOutlined className="cost-info-icon" />
                              </Tooltip>
                            )}
                            <Button 
                              type="primary"
                              className="recommended-item-btn"
                              onClick={() => {
                                PosthogHelper.track(currentUser, `[home] clicked recommended test: ${productType.title}`, true)
                                const url = productType?.path || `/flow/${productType?.flowType}`;
                                navigate(url)
                              }}
                            >
                              {productType.hasAppointment ? 'Schedule' : 'Order'} <ArrowRightOutlined />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Row>

              <Button 
                className="explore-all-btn"
                onClick={() => {
                  PosthogHelper.track(currentUser, `[home] clicked explore all tests`, true)
                  navigate('/catalog')
                }}
              >
                Explore All Tests
                <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="recommended-loading">
          <div className="home-section">
            <Paragraph className="recommended-loading-text"> 
              <Spin /> Loading tests...
            </Paragraph>
          </div>
        </div>
      )}

      <div className="concierge-section">
        <div className="home-section"> 
          <Row gutter={48}>
            <Col 
              xs={24}
              sm={24}
              md={24}
              lg={14}
              xl={14}
              xxl={14}
            >
              <Paragraph className="home-h1 concierge-h1">
                Feeling overwhelmed?
              </Paragraph>

              <Paragraph className="home-p1 concierge-p1">
                Taking control of your health can feel daunting, but you don't have to do it alone.
              </Paragraph>

              <div className="perks-container">
                {perks.map((perk, index) => (
                  <div key={`perk-${index}`} className="perk-item">
                    <CheckCircleOutlined className="perk-check" />
                    <div className="perk-content">
                      <div className="perk-title">{perk.title}</div>
                      <div className="perk-description">{perk.description}</div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="join-container">
                <Button 
                  className="join-btn"
                  type="primary"
                  onClick={onJoinMembership}
                >
                  Join Membership <ArrowRightOutlined />
                </Button>

                <Text className="join-price">
                  $49/month
                </Text>
              </div>
            </Col>
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={10}
              xl={10}
              xxl={10}
            >
              <div className="phone-container">
                <div className="phone-frame">
                  <Carousel 
                    effect="fade" 
                    ref={carouselRef}
                    autoplay={false}
                    afterChange={onSlideChange}
                  >
                    {videos.map((video, index) => (
                      <div key={`video-${index}`} className="video-slide">
                        <video
                          ref={el => videoRefs.current[index] = el}
                          key={`video-player-${index}`}
                          autoPlay
                          muted
                          playsInline
                          className="phone-video"
                          onEnded={() => onVideoEnded(index)}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="home-trusted">
        <div className="home-section">
          <Paragraph className="home-h1 trusted-h1">
            Trusted by
          </Paragraph>
          <Paragraph className="home-p1 trusted-p1">
            More than 18,000 patients—athletes, executives, and everyday individuals—using the latest diagnostic testing to understand their health risks and extend their lives.
          </Paragraph>
        </div>
      </div>

      <div className="logo-scroll">
        <div className="logo-scroll-content">
          <img src="/img/logos/mlb.png" alt="MLB" />
          <img src="/img/logos/google.png" alt="Google" />
          <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
          <img src="/img/logos/linkedin.png" alt="LinkedIn" />
          <img src="/img/logos/nfl.png" alt="NFL" />
          <img src="/img/logos/amazon.png" alt="Amazon" />
          <img src="/img/logos/uber.png" alt="Lyft" />
        </div>
        <div className="logo-scroll-content">
          <img src="/img/logos/mlb.png" alt="MLB" />
          <img src="/img/logos/google.png" alt="Google" />
          <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
          <img src="/img/logos/linkedin.png" alt="LinkedIn" />
          <img src="/img/logos/nfl.png" alt="NFL" />
          <img src="/img/logos/amazon.png" alt="Amazon" />
          <img src="/img/logos/uber.png" alt="Lyft" />
        </div>
        <div className="logo-scroll-content">
          <img src="/img/logos/mlb.png" alt="MLB" />
          <img src="/img/logos/google.png" alt="Google" />
          <img src="/img/logos/jpmorgan.png" alt="JPMorgan" />
          <img src="/img/logos/linkedin.png" alt="LinkedIn" />
          <img src="/img/logos/nfl.png" alt="NFL" />
          <img src="/img/logos/amazon.png" alt="Amazon" />
          <img src="/img/logos/uber.png" alt="Lyft" />
        </div>
      </div>
    </div>
  )
}