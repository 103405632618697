import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Collapse, 
  Row, 
  Col 
} from 'antd';
import TextHelper from '../../helpers/text.helper';
import ContentHelper from '../../helpers/content.helper';
import OrderButton from '../testChart/orderButton.component';
import './panelDetail.scss';
import { listFaqs } from '../../services/faq.service';
import { Questions } from '../questions/questions.component';
const { Text } = Typography;
const { Panel } = Collapse;

export const PanelDetail = ({ panel, isMobileView = false }) => {
  const [categorizedTests, setCategorizedTests] = useState({});
  const categoryKeys = Object.keys(categorizedTests);
  const [activeKeys, setActiveKeys] = useState(categoryKeys);
  const [hasOrderButton, setHasOrderButton] = useState(false);
  const [faqs, setFaqs] = useState(null);

  // Organize tests by category when the panel changes
  useEffect(() => {
    if (panel?.tests) {
      // Group tests by their absoluteCategory
      const testsByCategory = {};
      
      panel.tests.forEach(testItem => {
        if (!testItem) return;
        
        const category = testItem.absoluteCategory?.name || 'Other';
        
        if (!testsByCategory[category]) {
          testsByCategory[category] = [];
        }
        
        testsByCategory[category].push(testItem);
      });
      
      setCategorizedTests(testsByCategory);
    }

    const fetchFaqs = async () => {
      // get faqs for panel
      if (panel?.faqs && panel.faqs.length > 0) {
        const faqs = await listFaqs({ filter: { _id: { $in: panel.faqs } } });
        setFaqs(faqs);
      }

      else {
        setFaqs(null);
      }
    }

    fetchFaqs();
  }, [panel]);



  // Use useEffect to update activeKeys whenever categorizedTests changes
  useEffect(() => {
    if (Object.keys(categorizedTests).length > 0) {
      setActiveKeys(Object.keys(categorizedTests));
    }
  }, [categorizedTests]);

  // Render the OrderButton component
  const renderOrderButton = (panel) => {
    if (!panel) return null;
    if ((panel.cost && panel.cost > 0) || panel.productType) {
      return <OrderButton panel={panel} />;
    }
    return null;
  };

  useEffect(() => {
    if ((panel.cost && panel.cost > 0) || panel.productType) {
      setHasOrderButton(true);
    }
  }, [panel]);

  const renderAbout = (panel) => {

    if (!panel) return null
    if (panel.about && panel.about.length > 0) {
      return panel.about.map((item, index) => (
        <div key={index} className="about-item">
          {TextHelper.renderBlock(item)}
        </div>
      ))
    }

    else if (panel.details) {
      return <div className="about-item">
        {ContentHelper.formatByTestDocument(panel.details)}
      </div>
    }


    return null
  } 

  const renderFAQs = (faqs) => {
    if (!faqs) return null;
    if (faqs && faqs.length > 0) {
      return <Questions items={faqs} />
    }
    return null;
  }


  if (!panel) return null;

  return (
    <div className="panel-detail-container">
      <Row gutter={[hasOrderButton ? 16 : 0, 0]}>
        <Col sm={24} md={hasOrderButton ? 16 : 24} style={{width: '100%'}}>
          <div className="panel-detail">
            <div className="panel-header">
              <div className="panel-info">
                <div className="panel-name-container">
                  <h1 className="panel-name">{panel.name}</h1>
                </div>
                {panel.tagline && <h2 className="panel-tagline">{panel.tagline}</h2>}
              </div>
            </div>
            


            {/* Tests included in panel */}
            {panel.tests?.length > 1 ? (
              <div className="panel-tests panel-section">
                <h2>
                {panel.tests?.length || 0}{" "} Key Biomarkers Included
                </h2>
                
              {Object.keys(categorizedTests).length > 1 ? (
                <Collapse 
                  activeKey={activeKeys}
                  onChange={setActiveKeys}
                  className="categories-collapse"
                  expandIconPosition="end"
                  bordered={false}
                >
                  {Object.entries(categorizedTests).map(([category, tests]) => (
                    <Panel 
                      header={
                        <div className="category-header">
                          {category}
                          {/* <Tag color="default" className="category-count">{tests.length} biomarkers</Tag> */}
                        </div>
                      } 
                      key={category}
                      className="category-panel"
                    >
                      <div className="biomarkers-container">
                        {tests.map((testItem, index) => (
                          <div className="biomarker-item" key={testItem._id || index}>
                            <div className="biomarker-content">
                              <div className="biomarker-title">
                               {testItem.longName || testItem.name}
                              </div>
                              
                              {testItem.tagline && (
                                <Text type="secondary" className="biomarker-tagline">{testItem.tagline}</Text>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="biomarkers-container single-list">
                  {Object.keys(categorizedTests).length === 1 
                    ? categorizedTests[Object.keys(categorizedTests)[0]].map((testItem, index) => (
                        <div className="biomarker-item" key={testItem._id || index}>
                          <div className="biomarker-content">
                            <div className="biomarker-title">
                              {testItem.longName || testItem.name}
                              {/* {testItem.shortName && testItem.name && testItem.shortName !== testItem.name && (
                                <Text type="secondary" className="biomarker-abbreviation">({testItem.shortName})</Text>
                              )} */}
                            </div>
                            
                            {testItem.tagline && (
                              <Text type="secondary" className="biomarker-tagline">{testItem.tagline}</Text>
                            )}
                          </div>
                        </div>
                      ))
                    : (panel.tests || []).map((testItem, index) => (
                        <div className="biomarker-item" key={testItem._id || index}>
                          <div className="biomarker-content">
                            <div className="biomarker-title">
                              <Text strong>{testItem.longName || testItem.name}</Text>
                              {/* {testItem.shortName && testItem.name && testItem.shortName !== testItem.name && (
                                <Text type="secondary" className="biomarker-abbreviation">({testItem.shortName})</Text>
                              )} */}
                            </div>
                            
                            {testItem.tagline && (
                              <Text type="secondary" className="biomarker-tagline">{testItem.tagline}</Text>
                            )}
                          </div>
                        </div>
                      ))
                  }
                </div>
              )}
            </div>
            ) :
            <div className="panel-about panel-section">
              <h2>About {panel.name}</h2>
              <div className="panel-about-content">
                {renderAbout(panel.tests[0])}
            </div>            
          </div>          
          }


           {/* About panel */}
           {panel.tests?.length > 1 && panel.about && panel.about.length > 0 && (
              <div className="panel-about panel-section">
                <h2>About {panel.name}</h2>
                <div className="panel-about-content">
                  {renderAbout(panel)}
                </div>
              </div>
          )}

          {/* FAQs */}
          {faqs && faqs.length > 0 && (
            <div className="panel-faqs panel-section">
              <h2>FAQs</h2>
              <div className="panel-faqs-content">
                {renderFAQs(faqs)}
              </div>
            </div>
          )}



        
            


          </div>
        </Col>
        
        <Col sm={0} md={hasOrderButton ? 8 : 0}>
          {/* Order button for desktop view */}
          {!isMobileView && hasOrderButton && (
            <div className="order-button-container">
              {renderOrderButton(panel)}
            </div>
          )}
        </Col>
      </Row>

      {/* Mobile footer with order button */}
      {isMobileView && hasOrderButton && (
        <div className="mobile-footer">
          <div className="mobile-order-button-container">
            {renderOrderButton(panel)}
          </div>
        </div>
      )}
    </div>
  );
};

export default PanelDetail;