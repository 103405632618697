import { useRef, useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";
import FlowType from "../../enums/flowType.enum";
import { RedDropModal } from '../redDropModal/redDropModal.component.js';

export const FertilityTest = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);
  const [openReddrop, setOpenReddrop] = useState(false)
  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    const response = await getProductTypeByCode(ProductTypeCode.FERTILITY_PANEL)
    setProductType(response)
  };

  const fertilityPanel = [
  
      {
        name: "Anti-Müllerian Hormone",
        description: "AMH is released by cells around your follicles and is the most important hormone for estimating ovarian reserve, indicating your remaining egg supply and fertility lifespan."
      },
      {
        name: "Thyroid-Stimulating Hormone",
        description: "TSH, produced by the pituitary gland, regulates thyroid function, which is essential for maintaining hormonal balance and supporting reproductive health."
      },
      {
        name: "Follicle-Stimulating Hormone",
        description: "FSH is responsible for growing follicles growth and is crucial for evaluating ovarian reserve and the ovaries' ability to produce mature eggs."
      },
      {
        name: "Estradiol",
        description: "Estradiol is provided by the ovaries helps assess ovarian function and follicular development, ensuring accurate interpretation of other hormone levels."
      },
      {
        name: "Prolactin",
        description: "Prolactin stimulates milk production and can affect ovulation and menstrual regularity. Elevated levels may interfere with fertility."
      },
      {
        name: "Luteinizing Hormone",
        description: "LH, produced by the pituitary gland, regulates ovulation timing and luteal phase function, impacting overall fertility."
      },

   ]

  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
        question: "How do I get the Fertility Hormone Test done?",
        answer: <>You have three options:
        <ol style={{ marginTop: 12 }}>
        <li>Self-collection with <a className="cta-link" onClick={(e) => {
          e.stopPropagation(); 
          setOpenReddrop(true);
        }} >RedDrop One</a> – Use the easy, virtually painless device to collect your sample yourself at home. Then just drop it in the mail using the prepaid return envelope.</li>

          <li>At-home blood draw – A licensed phlebotomist comes to your home to collect your sample.</li>
          <li>Local lab visit – Get your blood drawn at a nearby partner lab.</li>

        </ol>
Choose the method that’s most convenient for you—we’ll guide you through the next steps after you order. 
        </>
      },
      {
          question: "What biomarkers are included?",
          answer: <>The Fertility Hormone Test is carefully curated by leading fertility experts, providing a comprehensive assessment of your ever-evolving fertility health. It includes AMH, TSH, FSH, LH, Estradiol and Prolactin. <a onClick={scrollToPanel}  className="cta-link">Learn More</a></>
      },
      {
        question: "How is the blood collection with the RedDrop One painless?",
        answer: "RedDrop One uses a microneedle-based device that draws a small amount of blood from the upper arm using gentle suction—not a finger prick or traditional needle. Most people report feeling only a slight tap or mild pressure, with no sharp poke. It’s designed to collect enough blood for advanced testing while keeping the process quick, comfortable, and easy to do at home."
      },
      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 1 week to get results after your sample arrives at our lab. We'll send you an email when your results and recommendations are ready to go!</>
      }, 
      {
        question: "How do I test hormone levels at home?",
        answer: <>If you choose the at-home option, we'll send you everything you need to test, along with instructions (and tips!) to support you throughout the process. After you collect your blood sample with the painless Tasso+ collection device, you'll just pop it in the mail and our lab will handle the rest.
        <br/><br/>Please note you'll want to wait til day three if your menstrual cycle to do the collection. This is considered the baseline of the menstrual cycle when hormone levels are stable and least influenced by the developing follicle. It allows for accurate assessment of hormone levels without the fluctuations that occur later in the cycle. </>,
      },
      {
        question: "Can I do this test when I'm on birth control?",
        answer: "Yes! Testing on birth control can help you get proactive with fertility insights even before you're ready for kids. If you're on hormonal birth control you'll want to test two key fertility hormones: AMH, the most reliable marker for ovarian reserve (aka your egg count), and TSH, the main indicator of thyroid health."
      },
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Do I need to fast before I collect my blood?`,
          answer: `No fasting is needed.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a longevity physician. They'll be ready to address any of your questions.`,
      }, 
      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to fertility specialists.`
      },
      {
          question: "How often should I get this panel done?",
          answer: `If you're looking to have kids, it's wise to have a check-up at least once a year to monitor your reproductive health.`,
      },             
      {
          question: `Does my health insurance cover this?`,
          answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don't have one, we can generate an itemized receipt for you to submit.`,
      }, 

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType?.title,
          memberPrice: ProductHelper.getMemberPrice(productType?.cost),
          originalPrice: productType?.cost,
          oneliner: productType?.tagline,
          panelRef: panelRef,
          cta: {
            text: "Get Started Now",
            url: `/flow/${productType?.flowType}`
          },
          hasKlarna: true,
          giftCta: {
            text: "Gift to Someone",
            url: `/flow/${FlowType.GIFT}/details?code=${ProductTypeCode.GIFT_FERTILITY_PANEL}`
          },
          photos: [
              {
                original: "/img/fertility-hero-1.webp", 
                thumbnail: "/img/fertility-hero-1.webp"
              },
              {
                original: "/img/reddrop-woman.webp",
                thumbnail: "/img/reddrop-woman.webp"
              },

              {
                original: "/img/reddrop-woman-after.webp",
                thumbnail: "/img/reddrop-woman-after.webp"
              },
              
              // {
              //   original: "/img/tasso.webp",
              //   thumbnail:  "/img/tasso.webp"
              // },

              // {
              //   original: "/img/fertility-tasso-grid.webp",
              //   thumbnail:  "/img/fertility-tasso-grid.webp"
              // },
              {
                  original: "/img/hsa-fsa-eligible-hero.webp",
                  thumbnail: "/img/hsa-fsa-eligible-hero.webp"
              }
          ],
          faqs: faqs
        },
      },
      {
        type: ProductDetailSection.SMALLPANEL,
        title: "Tests Included",
        data: {
          panel: fertilityPanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
          {
            title: "Choose collection method",
            description: "Select either a traditional blood draw (at home or at a lab) or use the RedDrop One device for self-collection. If you choose the latter, a test kit will be sent to you.",
            image: "/img/generic-test-kit.webp"
          },
            {
                title: "Complete your collection",
                description: "On day three of your menstrual cycle, either visit the lab or complete your at-home sample collection with the RedDrop One device. For at-home collection, simply mail your sample back.",
                image: "/img/reddrop-grid.webp"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 1 week. A report and game plan will be available as well.",
                image: "/img/receive-results-woman.png"
            },

        ],

      },
      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(3),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <RedDropModal
        open={openReddrop}
        setOpen={setOpenReddrop}
      />

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_FERTILITY_TEST}
      />

    </div>
  )

}
