import React, { useState, useEffect } from 'react'
import './lowRhrAdvice.scss'
import { Typography } from 'antd';
import ReportHelper from '../../../helpers/report.helper';
import ReportSection from '../../../enums/reportSection.enum';
import { TestChart } from '../testChart/testChart.component';
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import TestCode from '../../../enums/testCode.enum';
import { AdviceSection } from '../adviceSection/adviceSection.component';
const { Paragraph } = Typography

export const RhrAdvice = ({ report, setSectionMeta, section, tests, results, reports }) => {
  const [abnormalTest, setAbnormalTest] = useState()
  const [hasMultipleReadings, setHasMultipleReadings] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [recs, setRecs] = useState([])
  const isFixed = ReportHelper.isFixed(report, ReportSection.RHR_ADVICE);
  const isBetter = ReportHelper.isBetter(report,ReportSection.RHR_ADVICE);
  const isWorse = ReportHelper.isWorse(report, ReportSection.RHR_ADVICE);
  const isStatic = ReportHelper.isStatic(report, ReportSection.RHR_ADVICE);


  useEffect(() => {
    setAbnormalTest(getAbnormalTest())
  }, [report, tests])

  useEffect(() => {
    fetchHasMultipleReadings()
  }, [])

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription())
  }, [report, isFixed, isBetter, isWorse, isStatic]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const fetchHasMultipleReadings = () => {
    if (!results) return
    const test = tests.find(({ code }) => code === TestCode.PULSE)
    setHasMultipleReadings(results.filter(result => {
      return result.values.some(v => {
        return v.value && v.test === test._id
      })
    })?.length > 1)
  }

  const getAbnormalTest = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.RHR_ADVICE, tests)[0]
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report) return null;

    if (isStatic) {
      return (
        <>
          Your <span>resting heart rate</span> remain relatively <span>unchanged</span>.
        </>
      );
    }

    else if (isBetter) {
      return (
        <>
          Your <span>resting heart rate</span> is <span>improving</span>.
        </>
      );
    }
    else if (isWorse) {
      return (
        <>
          Your <span>resting heart rate</span> is <span>increasing</span>.
        </>
      );
    }

    else if (isFixed) {
      return (
        <>
          Your <span>resting heart rate</span> is now <span>optimal</span>.
        </>
      );
    }


    return (
      <>
        Your <span>resting heart rate</span> is{" "}
        <span>{ReportHelper.getRiskEval(TestCode.PULSE, report, results, tests)}</span>, the number of times your heart beats when you’re at rest.
      </>
    );
  };  

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return;

    if (isFixed) {
      return <>Since this biomarker has returned to a healthy range, you don't need to worry about the
      previous reading.</>
    }

    if (isWorse) {
      return <>
        <p>It’s possible your elevated heart rate during your last appointment was due to a “white coat” effect (temporary rise from stress or anxiety in a clinical setting), it’s also worth noting that your resting heart rate has been gradually increasing over time.</p>
        <p>A persistently high resting heart rate—especially above 100 BPM—can be a sign that your heart is under strain. Although occasional spikes may be due to stress, dehydration, or recent activity, consistently elevated readings deserve closer monitoring.</p>
      </>
    }

    return <>        {report.patient.exercises150PerWeek ? 
      `No worries for now - it's not unusual for your heart rate to be lower than normal if you exercise frequently.${hasMultipleReadings ? "" : " We'll also measure you again at your next appointment to verify if it's consistently abnormal."}` :
      `No worries for now. One low reading is not sufficient to determine if there's an issue, and your current level is quite close to the optimal range. Let's reevaluate during your next blood draw.`
    }</>
  }

  return abnormalTest && report && (
    <div className="low-rhr-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>
      <TestChart 
        report={report} 
        code={abnormalTest.code} 
        tests={tests}
        results={results}
        reports={reports}
      />
        <AdviceSection
          section={section}
          report={report}
          results={results}
          tests={tests}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
    </div>
  )
}