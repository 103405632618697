import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { OmegaCompleteApproval } from "./omegaCompleteApproval.component"
import { OmegaCompleteIntro } from "./omegaCompleteIntro.component"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import FlowType  from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"

export const Step = {
  INTRO: 'intro',
  SELECT: 'select',
  PAY_BASIC: 'pay-basic',
  PAY_COMPLETE: 'pay-complete',
  ACCOUNT: 'account',
  NAME: 'name',
  DOB: 'dob',
  GENDER: 'gender',
  PHONE: 'phone',
  DELIVERY: 'delivery',
  CONFIRM: 'confirm',
}

export const OmegaCompleteFlow = () => {
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  const navigate = useNavigate()

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, flow, hasPass])

  useEffect(() => {
    fetchProductTypes()
  }, [])


  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.OMEGAQUANT_COMPLETE,
            ProductTypeCode.OMEGAQUANT_BASIC
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }


  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <OmegaCompleteIntro footer={footer} />
      },  
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY_BASIC,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.OMEGA_3_INDEX}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      // [Step.SELECT]: {
      //   type: StepType.PRODUCT_SELECT,
      //   buildUser: true,
      //   onNextStep: (patient) => {
      //     switch (patient?.omegaQuantTestPreference) {
      //       case ProductTypeCode.OMEGAQUANT_BASIC:
      //         return Step.PAY_BASIC
      //       case ProductTypeCode.OMEGAQUANT_COMPLETE:
      //         return Step.PAY_COMPLETE
      //       default:
      //         return Step.PAY_BASIC
      //     }
      //   },
      //   title: `Which Omega-3 Index test would you like?`,
      //   field: {
      //     name: 'omegaQuantTestPreference',
      //     required: true,
      //     options: [{
      //       title: `Basic Test`,
      //       value: ProductTypeCode.OMEGAQUANT_BASIC,
      //       description: <>Reports just your Omega-3 index. Recommended if you just want to know your omega-3 status and you're just monitoring your status.</>,
      //       price:  ProductHelper.getTotalCostV2({
      //         productTypes: [getProductType(ProductTypeCode.OMEGAQUANT_BASIC)],
      //         instalabMembership,
      //         currentUser
      //       })
      //     }, 
      //     {
      //       title: `Complete Test`,
      //       value: ProductTypeCode.OMEGAQUANT_COMPLETE,
      //       description: <>This includes levels of all 24 fatty acids as well as your Omega-3 index, Ratios, and the Trans Fat Index. <a 
      //         className="link" 
      //         target="_blank" 
      //         rel="noreferrer" 
      //         href="https://omegaquant.com/wp-content/uploads/2020/12/OmegaQuant_O3complete_v15Dec2020.pdf"
      //         onClick={(e) => {
      //           e.stopPropagation();
      //         }}
      //       >View sample report</a>.</>,
      //       price:  ProductHelper.getTotalCostV2({
      //         productTypes: [getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)],
      //         instalabMembership,
      //         currentUser
      //       })
      //     }],
      //   },
      // },
      [Step.PAY_BASIC]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: ProductTypeCode.OMEGAQUANT_BASIC,
        title: <>Pay <span className="true-price">${ProductHelper.getTotalCostV2({
          productTypes: [getProductType(ProductTypeCode.OMEGAQUANT_BASIC)],
          instalabMembership,
          currentUser
        })}</span> for the <span className="product-name">{getProductType(ProductTypeCode.OMEGAQUANT_BASIC)?.title}</span></>,
        description: `This includes the collection kit, results and detail report.`,
      },
      [Step.PAY_COMPLETE]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: ProductTypeCode.OMEGAQUANT_COMPLETE,
        title: <>Pay <span className="true-price">${ProductHelper.getTotalCostV2({
          productTypes: [getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)],
          instalabMembership,
          currentUser
        })}</span> for the <span className="product-name">Omega-3 Index Complete Test</span></>,
        description: `This includes the collection kit, results and detail report.`,

      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is your date of birth?`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What was your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `What's your delivery address?`,
        description: `This is where your Omega-3 Index test kit will be sent.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set!`,
        description: `Your kit will be sent to your address within 1 business day.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <OmegaCompleteApproval/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}