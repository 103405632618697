import React, { useEffect, useState, useContext, useRef } from 'react';

import Color from '../../colors.scss';
import moment from 'moment';
import { Typography, Spin, Row, Col } from 'antd';
import { getAnnotatedTest, listTests } from '../../services/test.service';
import RiskLevel from '../../enums/riskLevel.enum';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { listRiskRanges } from '../../services/test.service';
import { listAnnotatedResults } from '../../services/result.service';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import CountUp from 'react-countup';
import 'chartjs-adapter-date-fns';
import { listAnnotations } from '../../services/annotation.service';
import './testChart.scss';
import Role from '../../enums/role.enum';
import { listReports } from '../../services/report.service';
import { listPrescriptionEvents } from '../../services/prescription.service';
import ReportStatus from '../../enums/reportStatus.enum';
import PrescriptionEvent from '../../enums/prescriptionEvent.enum';
import PrescriptionType from '../../enums/prescriptionType.enum';
import TestCode from '../../enums/testCode.enum';
import ContentHelper from '../../helpers/content.helper';
import { TestChartDrawer } from '../testChartDrawer/testChartDrawer.component';
import AnnotationType from '../../enums/annotationType.enum';
import ResultHelper from '../../helpers/result.helper';
import { TextHelper } from '../../helpers/text.helper';
import RecommendationItem from '../gameplan-v2/recommendationItem.component';
import { listRecommendations } from '../../services/recommendation.service';
import { listPanels } from '../../services/panel.service';
import OrderButton from './orderButton.component';
import RecommendationStatus from '../../enums/recommendationStatus.enum';
import { Questions } from '../questions/questions.component';
import { listFaqs } from '../../services/faq.service';
ChartJS.register(
  TimeScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  ChartTooltip, 
  Legend,
  annotationPlugin
);

const APOE_TEST_ID = '621d2a9d5a43a99402ccd0be'
const APOE_GENOTYPES = ['e2/e2', 'e2/e3', 'e2/e4', 'e3/e3', 'e3/e4', 'e4/e4'];

const healthScoreRisk = [{
  level: RiskLevel.OPTIMAL,
  greaterThanOrEqual: 90,
},
{
  level: RiskLevel.MODERATE,
  greaterThanOrEqual: 75,
  lessThan: 90,
},
{
  level: RiskLevel.HIGH,
  lessThan: 75,
}]

const prescriptionEffects = [{
  // cholesterol
  prescriptionTypes: [
    PrescriptionType.EZETIMIBE_10MG,
    
    PrescriptionType.BEMPEDOIC_ACID_180MG,
    PrescriptionType.ALIROCUMAB_75MG,
    PrescriptionType.ALIROCUMAB_150MG,
    PrescriptionType.EVOLOCUMAB_140MG,
    PrescriptionType.LEQVIO_284MG,
    PrescriptionType.BERBERINE_500MG,
  ],
  testCodes: [
    TestCode.APO_B,
    TestCode.LIPOPROTEIN_A,
    TestCode.TC,
    TestCode.DIRECT_LDL,
    TestCode.HDL,
    TestCode.VLDL,
    TestCode.TG,
    TestCode.LDL_CHOLESTEROL_CALC,
  ]
}, {
  prescriptionTypes: [
    PrescriptionType.ROSUVASTATIN_2500MCG,
    PrescriptionType.ROSUVASTATIN_5MG,
    PrescriptionType.ROSUVASTATIN_10MG,
    PrescriptionType.ROSUVASTATIN_20MG,
    PrescriptionType.ROSUVASTATIN_40MG,
    PrescriptionType.RED_YEAST_RICE_600MG,
    PrescriptionType.PITAVASTATIN_1MG,
    PrescriptionType.PITAVASTATIN_2MG,
    PrescriptionType.PITAVASTATIN_4MG,
    PrescriptionType.ATORVASTATIN_10MG,
    PrescriptionType.ATORVASTATIN_20MG,
  ],
  testCodes: [
    TestCode.APO_B,
    TestCode.LIPOPROTEIN_A,
    TestCode.TC,
    TestCode.DIRECT_LDL,
    TestCode.HDL,
    TestCode.VLDL,
    TestCode.TG,
    TestCode.LDL_CHOLESTEROL_CALC,
    TestCode.ALT,
    TestCode.AST,
    TestCode.ALP,
    TestCode.GGT,
    TestCode.TOTAL_BILIRUBIN,
    TestCode.DIRECT_BILIRUBIN,
    TestCode.INDIRECT_BILIRUBIN,
  ]
}, {
  // uric acid
  prescriptionTypes: [
    PrescriptionType.ALLOPURINOL_100MG,
    PrescriptionType.ALLOPURINOL_300MG,
  ],
  testCodes: [
    TestCode.URIC_ACID,
  ]
}, {
  prescriptionTypes: [
    PrescriptionType.ICOSAPENT_ETHYL_1G,
    PrescriptionType.ICOSAPENT_ETHYL_2G,
    PrescriptionType.ICOSAPENT_ETHYL_4G,
  ],
  testCodes: [
    TestCode.TG,
    TestCode.OMEGA_3_INDEX,
  ]
}, {
  // insulin resistance
  prescriptionTypes: [
    PrescriptionType.METFORMIN_500MG,
    PrescriptionType.METFORMIN_850MG,
    PrescriptionType.METFORMIN_1000MG,
    PrescriptionType.METFORMIN_ER_500MG,
    PrescriptionType.METFORMIN_ER_750MG,
    PrescriptionType.METFORMIN_ER_1500MG,
    PrescriptionType.MOUNJARO_12500MCG,
    PrescriptionType.MOUNJARO_7500MCG,
    PrescriptionType.MOUNJARO_5000MCG,
    PrescriptionType.MOUNJARO_2500MCG,
  ],
  testCodes: [
    TestCode.HOMAIR,
    TestCode.GLUCOSE,
    TestCode.INSULIN,
    TestCode.HBA1C,
    TestCode.TGI,
  ]
}, {
  // vitamin d
  prescriptionTypes: [
    PrescriptionType.VITAMIN_D_5000IU,
    PrescriptionType.VITAMIN_D_10000IU,
  ],
  testCodes: [
    TestCode.VITAMIN_D,
  ]
}, {
  // vitamin b12
  prescriptionTypes: [
    PrescriptionType.VITAMIN_B12_5000MCG,
  ],
  testCodes: [
    TestCode.VITAMIN_B12,
    TestCode.HOMOCYSTEINE,
  ]
}, {
  // vitamin b9
  prescriptionTypes: [
    PrescriptionType.VITAMIN_B9_1000MCG,
  ],
  testCodes: [
    TestCode.FOLATE,
    TestCode.HOMOCYSTEINE,
  ]
}]

const { Text } = Typography;

const riskColors = {
  [RiskLevel.OPTIMAL]: Color.success,
  [RiskLevel.MODERATE]: Color.warning,
  [RiskLevel.HIGH]: Color.error,
  [RiskLevel.ABNORMAL]: Color.error,
}

const riskBackgroundColors = {
  [RiskLevel.OPTIMAL]: Color.success_bg,
  [RiskLevel.MODERATE]: Color.warning_bg,
  [RiskLevel.HIGH]: Color.error_bg,
  [RiskLevel.ABNORMAL]: Color.error_bg,
}

const riskLabels = {
  [RiskLevel.OPTIMAL]: 'Optimal',
  [RiskLevel.MODERATE]: 'Borderline risk',
  [RiskLevel.HIGH]: 'At risk',
  [RiskLevel.ABNORMAL]: 'At risk',
  [RiskLevel.UNKNOWN]: 'n/a'
}

export const TestChart = ({ 
  id,
  annotations,
  setAnnotations,
}) => {
  const [allPrescriptionEvents, setAllPrescriptionEvents] = useState([])
  const chartRef = useRef(null);
  const { patientId } = useParams()
  const isApoe = id === APOE_TEST_ID
  const [tests, setTests] = useState([])
  const [test, setTest] = useState(null)
  const [results, setResults] = useState([])
  const { currentUser } = useContext(UserContext)
  const [chartData, setChartData] = useState(null)
  const [stats, setStats] = useState({})
  const [chartOptions, setChartOptions] = useState(null)
  const [reports, setReports] = useState([])
  const [prescriptionEvents, setPrescriptionEvents] = useState([])
  const [allResults, setAllResults] = useState([])
  const [riskRanges, setRiskRanges] = useState([])
  const [childTest, setChildTest] = useState()
  const [openChild, setOpenChild] = useState()
  const [isMobileView, setIsMobileView] = useState(false);
  const [recommendations, setRecommendations] = useState([])
  const [hasOrderButton, setHasOrderButton] = useState(false)
  const [faqs, setFaqs] = useState([])

  useEffect(() => {

    const fetchTest = async () => {
      if (!id || !currentUser) {
        setTest(null)
        return
      }

      if (id === 'health-score') {
        setTest({
          code: id,
          name: `Longevity Score`,
          unit: '%',
          details: `The longevity score, a scale from 0 to 100, is a comprehensive measure of your overall health and its potential impact on both your lifespan and healthspan. The higher your score, the closer you are to optimal health and the more potential you may have for disease-free years.
          <br/><br/>The score is calculated using a statistical algorithm that considers factors such as your biomarker levels, family and medical history, and current lifestyle. It asks the question: if everything stayed the same from today onward, how much are you at risk for aging-related diseases, including cardiovascular disease, diabetes, hypertension, dementia, chronic kidney disease, and more. By making changes to improve these factors, you can change the trajectory of your longevity score significantly.
          <br/><br/>Your percentile is determined by comparing scores with other Instalab users of the same age and biological sex.`,
          risk: healthScoreRisk,
          absoluteCategory: {
            name: 'Calculated',
          }
        })
      } else {
        let params = {}
        if (currentUser?.role !== Role.PATIENT) {
          params.patient = patientId
        }
        const response = await getAnnotatedTest(id, params)
        setTest(response)
      }
    }
    
    fetchTest()
  }, [id, currentUser])


  useEffect(() => {

    const fetchRecommendations = async () => {
      const response = await listRecommendations({ filter: { patient: patientId, status: {$ne: RecommendationStatus.INACTIVE} } })
      setRecommendations(response)
    }

    if (currentUser) {
      fetchRecommendations()
    }
  }, [currentUser])


  useEffect(() => {
    if ((patientId || currentUser) && test) {
      fetchAnnotations()
    }
  }, [patientId, currentUser, test])

  useEffect(() => {
    fetchRiskRanges()
  }, [id, patientId, reports, test])

  useEffect(() => {
    if (test && allPrescriptionEvents?.length) {
      setPrescriptionEvents(allPrescriptionEvents.filter(onFilterPrescriptionEvent))
    } else {
      setPrescriptionEvents([])
    }
  }, [allPrescriptionEvents, test])

  useEffect(() => {
    fetchAllResults()
    fetchReports()
  }, [currentUser, patientId])

  useEffect(() => {
    fetchResults()
  }, [allResults, id])

  useEffect(() => {
    fetchChartData()
  }, [results, reports, riskRanges, annotations, prescriptionEvents])

  useEffect(() => {
    fetchStats()
  }, [results, reports, test, patientId, prescriptionEvents, isApoe])

  useEffect(() => {
    fetchChartOptions()
  }, [results, reports, test, patientId, prescriptionEvents, isApoe, riskRanges])

  useEffect(() => {
    fetchPrescriptionEvents()
  }, [currentUser, patientId])

  useEffect(() => {
    fetchTests()
  }, [])

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    
    // Initial check
    checkIfMobile();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);


  useEffect(() => {
    if (!test) return
    const fetchHasOrderButton = async () => {
        if (!test || id === 'health-score') return false
        
        // show if test cost is greater than 0
        else if (test.cost > 0) {
          setHasOrderButton(true)
          return
        }
    
        // show if there is an associated product or panel with cost greater than 0
        else {
          const filter = {
            tests: { $in: [test._id] },
            active: true,
            code: { $exists: true, $ne: test.code }
          }
          const panels = await listPanels({filter: filter, select: '_id cost productType'});
          setHasOrderButton(panels.filter(panel => panel.productType || panel.cost > 0).length > 0)
  
        }
    }

    const fetchFaqs = async () => {
      const response =  (!test || !test.faqs || test.faqs.length === 0) ? null : await listFaqs({ filter: { _id: { $in: test.faqs } } })
      setFaqs(response)
    }

    fetchHasOrderButton()
    fetchFaqs()
  }, [test])


  const fetchAnnotations = async () => {
    const fetchedAnnotations = await listAnnotations({ patient: patientId })
    setAnnotations(fetchedAnnotations)
  }

  const fetchTests = async () => {
    const response = await listTests()
    setTests(response)
  }

  const onFilterPrescriptionEvent = (event) => {
    const { code } = test
    const prescriptionTypes = prescriptionEffects
      .filter(({ testCodes }) => testCodes.includes(code))
      .reduce((acc, { prescriptionTypes }) => [...acc, ...prescriptionTypes], [])
    
    return prescriptionTypes.includes(event.prescriptionType)
  }

  const fetchRiskRanges = async () => {
    if (!id) {
      setRiskRanges([])
      return
    } else if (id === 'health-score') {
      if (!test) return
      const targets = getTargets()
      setRiskRanges(targets.map(target => {
        return {
          collectedAt: target.result.collectedAt,
          risk: test.risk
        }
      }))
    } else {
      const riskRanges = await listRiskRanges(id, { patient: patientId })
      setRiskRanges(riskRanges.sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt)).map(riskRange => {
        riskRange.risk = riskRange.risk.filter(risk => !risk.hasOwnProperty('equal'))
        return riskRange
      }))
    }
  }

  const fetchPrescriptionEvents = async () => {
    if (!currentUser) return
    const response = await listPrescriptionEvents({ patient: patientId })
    setAllPrescriptionEvents(response)
  }

  const getTargets = () => {
    return id === 'health-score' ? reports : results
  }

  const fetchAllResults = async () => {
    if (!currentUser) return
    let params = {}
    if (currentUser?.role !== Role.PATIENT) {
      params.patient = patientId
    }
    let annotatedResults = await listAnnotatedResults(params)
    annotatedResults = annotatedResults
      .sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt))
    setAllResults(annotatedResults)
  }

  const fetchResults = async () => {
    setResults(allResults.filter(onFilterResult).sort(onSortResults))
  }

  const fetchReports = async () => {
    if (!currentUser) return
    
    let filter = {
      healthScore: {
        '$exists': true,
        '$gt': 0
      },
      status: ReportStatus.APPROVED
    }
    if (currentUser?.role !== Role.PATIENT) {
      filter.patient = patientId
    }
    let fetchedReports = await listReports({
      filter,
      select: 'status healthScore',
      populate: [{
        path: 'result',
        select: 'collectedAt',
      }],
      sort: '-createdAt'
    })

    fetchedReports = fetchedReports.sort(onSortReport)
    setReports(fetchedReports)
  }

  const onSortReport = (a, b) => {
    return new Date(a.result.collectedAt) - new Date(b.result.collectedAt)
  }

  const onFilterResult = (result) => {
    return result.values.some(value => value.test === id && value.value)
  }

  const onSortResults = (a, b) => {
    return new Date(a.collectedAt) - new Date(b.collectedAt)
  }

  const getDistanceToOptimal = (testValue, testRisk) => {
    let distanceToOptimal = null

    if (testRisk !== RiskLevel.OPTIMAL) {
      let closestOptimalRange = null

      const optimalRanges = test.risk.filter(({ level }) => level === RiskLevel.OPTIMAL)
      for (const range of optimalRanges) {
        const {
          greaterThan,
          greaterThanOrEqual,
          lessThan,
          lessThanOrEqual,
          equal
        } = range

        // Use proper null checking to handle 0 values correctly
        let rangeValue;
        if (greaterThanOrEqual !== undefined && greaterThanOrEqual !== null) {
          rangeValue = greaterThanOrEqual;
        } else if (lessThanOrEqual !== undefined && lessThanOrEqual !== null) {
          rangeValue = lessThanOrEqual;
        } else if (greaterThan !== undefined && greaterThan !== null) {
          rangeValue = greaterThan;
        } else if (lessThan !== undefined && lessThan !== null) {
          rangeValue = lessThan;
        } else if (equal !== undefined && equal !== null) {
          rangeValue = equal;
        }

        
        if (closestOptimalRange === null || Math.abs(rangeValue - testValue) < Math.abs(closestOptimalRange - testValue)) {
          closestOptimalRange = rangeValue
        }
      }
      const decimalPlaces = Math.max(
        getDecimalPlaces(testValue), 
        getDecimalPlaces(closestOptimalRange)
      )
      distanceToOptimal = parseFloat((closestOptimalRange - testValue).toFixed(decimalPlaces))
    }

    return distanceToOptimal
  }

  const getDecimalPlaces = (num) => {
    if (typeof num !== 'number' || Math.floor(num) === num) return 0;
    return num.toString().split(".")[1]?.length || 0;
  }

  const formatNumber = (value) => {
    if (typeof value === 'string') {
      // Handle strings like ">1500" or "<1500"
      const numericValue = value.replace(/[<>]/g, '');
      return !isNaN(numericValue) ? Number(numericValue) : value;
    }
    return !isNaN(value) ? Number(value) : value;
  }

  const getHasImproved = (prevTarget, latestTarget) => {
    let prevValue, prevRisk = null
    if (prevTarget) {
      prevValue = getTargetValue(prevTarget)
      prevRisk = getTargetRisk(prevTarget)
    }
    const latestValue = getTargetValue(latestTarget)
    const latestRisk = getTargetRisk(latestTarget)

    return ResultHelper.hasImproved(test, latestValue, latestRisk, prevValue, prevRisk)

  }

  const fetchStats = async () => {
    const targets = getTargets()

    if (!targets.length || !test) return

    const latestTarget = targets[targets.length - 1]
    
    const latestRisk = getTargetRisk(latestTarget)
    const latestValue = getTargetValue(latestTarget)
    const latestTestDate = getTargetTestDate(latestTarget)

    let distanceToOptimal = getDistanceToOptimal(latestValue, latestRisk)

    let delta, prevTarget = null
    if (targets.length > 1) {
      prevTarget = targets[targets.length-2]
      const prevValue = getTargetValue(prevTarget)
      const decimalPlaces = Math.max(
        getDecimalPlaces(latestValue),
        getDecimalPlaces(prevValue)
      )
      delta = isNaN(parseFloat((latestValue - prevValue).toFixed(decimalPlaces))) ? null : parseFloat((latestValue - prevValue).toFixed(decimalPlaces))
    }

    const hasImproved = getHasImproved(prevTarget, latestTarget)

    setStats({
      latestTestDate,
      latestRisk,
      latestValue,
      delta,
      distanceToOptimal,
      hasImproved
    })
  }

  const getTargetTestDate = (target) => {
    if (id === 'health-score') {
      return moment(target.result.collectedAt).format('MMM D, YYYY')
    } else {
      return moment(target.collectedAt).format('MMM D, YYYY')
    }
  }

  const getTargetRisk = (target) => {
    if (id === 'health-score') {
      const { healthScore } = target
      return healthScoreRisk.find(({ greaterThanOrEqual, lessThan }) => 
        (greaterThanOrEqual === undefined || healthScore >= greaterThanOrEqual) &&
        (lessThan === undefined || healthScore < lessThan)
      )?.level || RiskLevel.HIGH
    } else {
      return target?.values?.find(value => value.test === id)?.risk
    }
  }

  const getTargetValue = (target) => {
    if (!target) return
    if (id === 'health-score') {
      return formatNumber(target.healthScore)
    } else {
      return formatNumber(target.values.find(value => value.test === id)?.value)
    }
  }

  const interpolateYValue = (targetDate, results, test, chartRef) => {
    if (!results?.length) return null;
    
    const chart = chartRef.current;
    if (!chart) return null;

    // Get the dataset
    const testResultsDataset = chart.data.datasets.find(d => d.label === 'Test Results');
    if (!testResultsDataset) return null;

    // Use the raw data points instead of rendered positions
    const dataPoints = testResultsDataset.data.map(point => ({
      x: new Date(point.x).getTime(),
      y: point.y
    }));

    // Sort points by x value
    const sortedPoints = dataPoints.sort((a, b) => a.x - b.x);
    const targetTime = new Date(targetDate).getTime();

    // Find the two points we're between
    for (let i = 0; i < sortedPoints.length - 1; i++) {
      const point1 = sortedPoints[i];
      const point2 = sortedPoints[i + 1];
      
      if (targetTime >= point1.x && targetTime <= point2.x) {
        // Calculate percentage based on time difference
        const percentage = (targetTime - point1.x) / (point2.x - point1.x);
        
        // Linear interpolation using actual values
        const interpolatedY = point1.y + (point2.y - point1.y) * percentage;

        return interpolatedY;
      }
    }

    return null;
  };

  const fetchChartData = () => {
    const targets = getTargets()
    if (!targets.length) return

    setChartData({
      datasets: [
        {
          label: 'Prescriptions',
          data: prescriptionEvents.map(event => {
            const eventTimestamp = new Date(event.timestamp);
            let yPosition;
            
            const hasFutureResults = results.some(result => 
              new Date(getTargetTestDate(result)) > eventTimestamp
            );

            const hasPreviousResults = results.some(result => 
              new Date(getTargetTestDate(result)) < eventTimestamp
            );
            
            if (!hasFutureResults && results?.length > 0) {
              // If annotation is after all results, use the last result's y value
              const lastResult = results.sort((a,b) => {
                return new Date(getTargetTestDate(b)) - new Date(getTargetTestDate(a))
              })[0]
              yPosition = getTargetValue(lastResult)
            } else if (!hasPreviousResults && results?.length > 0) {
              // If annotation is before all results, use the first result's y value
              const firstResult = results.sort((a,b) => {
                return new Date(getTargetTestDate(a)) - new Date(getTargetTestDate(b))
              })[0]
              yPosition = getTargetValue(firstResult)
            } else {
              // Use existing interpolation logic for annotations between results
              yPosition = interpolateYValue(new Date(event.timestamp), results, test, chartRef)
            }
            
            return {
              x: eventTimestamp,
              y: yPosition,
              prescription: event
            };
          }),
          pointBackgroundColor: (context) => {
            const event = context.raw?.prescription;
            if (!event) return
            switch (event.eventType) {
              case PrescriptionEvent.STARTED:
              case PrescriptionEvent.RESTARTED:
              case PrescriptionEvent.INCREASED_DOSAGE:
              case PrescriptionEvent.DECREASED_DOSAGE:
                return 'rgba(12, 163, 127, 0.3)'
              case PrescriptionEvent.PAUSED:
                return 'rgba(255, 192, 0, 0.3)'
            }
          },
          pointBorderColor: (context) => {
            const event = context.raw?.prescription;
            if (!event) return
            switch (event.eventType) {
              case PrescriptionEvent.STARTED:
              case PrescriptionEvent.RESTARTED:
              case PrescriptionEvent.INCREASED_DOSAGE:
              case PrescriptionEvent.DECREASED_DOSAGE:
                return '#0ca37f'
              case PrescriptionEvent.PAUSED:
                return 'rgb(237, 110, 127)'
            }
          },
          pointBorderWidth: 2,
          pointRadius: 4,
          showLine: false,
        },
        {
          label: 'Annotations',
          data:  annotations?.map(annotation => {
            const annotationDate = new Date(annotation.occurredAt);
            let yPosition;
        
            const hasFutureResults = results.some(result => 
              new Date(getTargetTestDate(result)) > annotationDate
            );

            const hasPreviousResults = results.some(result => 
              new Date(getTargetTestDate(result)) < annotationDate
            );
        
            if (!hasFutureResults && results?.length > 0) {
              // If annotation is after all results, use the last result's y value
              const lastResult = results.sort((a,b) => {
                return new Date(getTargetTestDate(b)) - new Date(getTargetTestDate(a))
              })[0]
              yPosition = getTargetValue(lastResult)
            } else if (!hasPreviousResults && results?.length > 0) {
              // If annotation is before all results, use the first result's y value
              const firstResult = results.sort((a,b) => {
                return new Date(getTargetTestDate(a)) - new Date(getTargetTestDate(b))
              })[0]
              yPosition = getTargetValue(firstResult)
            } else {
              // Use existing interpolation logic for annotations between results
              yPosition = interpolateYValue(new Date(annotation.occurredAt), results, test, chartRef)
            }
        
            return {
              x: annotationDate,
              y: yPosition,
              annotation: annotation
            };
          }),
          pointBackgroundColor: (context) => {
            const annotation = context.raw?.annotation;
            if (!annotation) return 
            return annotation.annotationType === AnnotationType.POSITIVE ? 'rgba(12, 163, 127, 0.3)' : 'rgba(237, 110, 127, 0.3)'; // Light green/red fill
          },
          pointBorderColor: (context) => {
            const annotation = context.raw?.annotation;
            if (!annotation) return
            return annotation.annotationType === AnnotationType.POSITIVE ? '#0ca37f' : 'rgb(237, 110, 127)'; // Green/red border
          },
          pointBorderWidth: 2,
          pointRadius: 4,
          showLine: false,
        },
        {
          label: 'Test Results',
          tension: 0,
          pointRadius: (context) => {
            const point = context.raw;
            return point?.isSynthetic ? 0 : 3.5;  // Hide synthetic points, show real points
          },
          hoverRadius: (context) => {
            const point = context.raw;
            return point?.isSynthetic ? 0 : 5;    // Also hide synthetic points on hover
          },
          borderWidth: 2,
          data: (() => {
            if (!test?.risk || !targets?.length) return [];

            // Helper function to get risk level for a value
            const getRiskLevel = (value) => {
              const risk = test.risk.find(r => {
                if (r.lessThan !== undefined && value >= r.lessThan) return false;
                if (r.lessThanOrEqual !== undefined && value > r.lessThanOrEqual) return false;
                if (r.greaterThan !== undefined && value <= r.greaterThan) return false;
                if (r.greaterThanOrEqual !== undefined && value < r.greaterThanOrEqual) return false;
                return true;
              });
              return risk?.level || null;
            };

            let basePoints = []

            const sortedTargets = targets.sort((a,b) => {
              return new Date(getTargetTestDate(a)) - new Date(getTargetTestDate(b))
            })
            
            let prevTarget
            for (const target of sortedTargets) {
              basePoints.push({
                x: new Date(getTargetTestDate(target)),
                y: formatNumber(getTargetValue(target)),
                // Store original target for real points
                originalTarget: target,

                risk: getTargetRisk(target),
                // Flag to identify real vs synthetic points
                isSynthetic: false,
                prevTarget
              })
              prevTarget = target
            }

            // Get all boundary values from test.risk
            const boundaries = [...new Set(test.risk
              .reduce((acc, risk) => {
                if (risk.lessThan !== undefined) acc.push(risk.lessThan);
                if (risk.lessThanOrEqual !== undefined) acc.push(risk.lessThanOrEqual);
                if (risk.greaterThan !== undefined) acc.push(risk.greaterThan);
                if (risk.greaterThanOrEqual !== undefined) acc.push(risk.greaterThanOrEqual);
                return acc;
              }, []))].sort((a, b) => a - b);

            // Insert intersection points
            const allPoints = [];
            for (let i = 0; i < basePoints.length - 1; i++) {
              const p0 = basePoints[i];
              const p1 = basePoints[i + 1];
              allPoints.push(p0);

              // Check each boundary
              boundaries.forEach(boundary => {
                // If line segment crosses this boundary
                if ((p0.y <= boundary && p1.y >= boundary) || 
                    (p0.y >= boundary && p1.y <= boundary)) {
                  
                  // Calculate exact x position where line crosses boundary
                  const percentage = (boundary - p0.y) / (p1.y - p0.y);
                  const timestamp = p0.x.getTime() + (p1.x.getTime() - p0.x.getTime()) * percentage;
                  
                  // Insert new point at boundary crossing
                  allPoints.push({
                    x: new Date(timestamp),
                    y: boundary,
                    isSynthetic: true,
                    risk: getRiskLevel(boundary)
                  });
                }
              });
            }
            // Don't forget the last point
            allPoints.push(basePoints[basePoints.length - 1]);
            
            return allPoints.sort((a,b) => b.x - a.x);
          })(),
          pointBackgroundColor: (context) => {
            const point = context.raw;
            if (!point) return Color.secondary_bg;
            
            if (point.isSynthetic) {
              return riskColors[point.risk];
            }
            return riskColors[getTargetRisk(point.originalTarget)];
          },
          segment: {
            borderColor: (context) => {
              const { p0, p1 } = context;

              const p0Risk = p0?.raw?.risk
              const p1Risk = p1?.raw?.risk
              const isLastPoint = p0.x === p1.x
              if (isLastPoint) {
                const prevPointRisk = getTargetRisk(p0.raw.prevTarget)
                if (prevPointRisk) {
                  return riskColors[prevPointRisk];
                } else {
                  return riskColors[p0Risk]
                }
              } else if (p0?.raw?.isSynthetic) {
                return riskColors[p1Risk];
              } else {
                return riskColors[p0Risk];
              }
            }
          }
        }
      ],
    })
  }
  
  const fetchChartOptions = () => {
    const targets = getTargets()
    
    if (!test || !targets?.length || (!isApoe && !riskRanges?.length)) {
      setChartOptions(null)
      return
    }

    if (isApoe) {
      setChartOptions({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: "MMM ''yy"
              }
            },
            title: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: true,
              z: 1,
            },
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 6,
              color: '#666666'    // Added color to make sure it's visible
            },
            border: {
              display: true      // Make sure border is displayed
            }
          },
          y: {
            type: 'category',
            labels: APOE_GENOTYPES,
            title: {
              display: true,
              text: 'Genotype'
            },
            grid: {
              z: 1,
            },
            offset: true,      // Centers labels between ticks
            ticks: {
              crossAlign: 'center'  // Centers labels vertically in their space
            },
            border: {
              dash: [2, 2],
            },
          }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false
          },
          annotation: {
            annotations: APOE_GENOTYPES.map((genotype, index) => {
              const risk = test.risk.find(r => {
                const { equal } = r
                return equal === genotype
              })?.level
  
              return {
                type: 'box',
                drawTime: 'beforeDatasetsDraw',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: index - 0.5,
                yMax: index + 0.5,
                backgroundColor: `${riskBackgroundColors[risk]}`,
                borderColor: 'transparent',
              }
            })
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            bodyMaxWidth: 200,
            padding: 8,
            boxPadding: 4,
            enabled: true,
            callbacks: {
              title: function(tooltipItems) {
                const item = tooltipItems[0];
                if (!item) return '';
                return format(item.raw.x, 'MMM d, yyyy');
              },
              label: function(context) {
                const dataPoint = context.raw;
                
                // Handle annotation and prescription points
                if (dataPoint?.annotation || dataPoint?.prescription) {
                  const text = dataPoint.prescription ? 
                    ` ${dataPoint.prescription.eventType}: ${dataPoint.prescription.prescriptionType}` : 
                    ` Note: ${dataPoint.annotation.content}`
                  
                  return text.match(/.{1,30}(?:\s|$)/g)?.map(line => line.trim()) || [];
                }
                
                // Handle regular points
                if (!dataPoint?.originalTarget) return ['No data available'];
                
                const target = dataPoint.originalTarget;
                // Safely find the index, handle case where target might not be in targets array
                const index = targets.findIndex(t => t && t._id === target._id);
                if (typeof index !== 'number' || index < 0) return ['No data available'];
                
                const currentRisk = getTargetRisk(target);
                const currentValue = getTargetValue(target);
                const distanceToOptimal = getDistanceToOptimal(currentValue, currentRisk);

                let delta = null;
                if (index > 0) {
                  const prevTarget = targets[index-1];
                  if (prevTarget) {
                    const prevValue = getTargetValue(prevTarget);
                    if (prevValue !== undefined && currentValue !== undefined) {
                      const decimalPlaces = Math.max(
                        getDecimalPlaces(currentValue),
                        getDecimalPlaces(prevValue),
                      );
                      delta = parseFloat((currentValue - prevValue).toFixed(decimalPlaces));
                      if (delta > 0) {
                        delta = `+${delta}`;
                      }
                    }
                  }
                }

                // Safely get the value, handling potential undefined values
                const testValue = target.values?.find(value => value?.test === id);
                const displayValue = testValue && typeof testValue.value === 'string' 
                  ? testValue.value 
                  : `${context.parsed.y}`;

                let labels = [];
                
                if (currentRisk && currentRisk !== 'UNKNOWN') {
                  labels.push(`Risk level:             ${riskLabels[currentRisk] || 'Unknown'}`);
                }
                
                labels.push(`Test value:            ${displayValue}${test?.unit ? ` ${test?.unit}` : ''}`);

                if (distanceToOptimal !== null && currentRisk && currentRisk !== RiskLevel.UNKNOWN) {
                  labels.push(`To reach optimal: ${distanceToOptimal > 0 ? '+' : ''}${distanceToOptimal}${test?.unit ? ` ${test.unit}` : ''}`);
                }

                if (delta !== null) {
                  labels.push(`Change:               ${delta}${test?.unit ? ` ${test.unit}` : ''} from previous`);
                }

                return labels;
              }
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        }
      })
    } 
    else if (test?.string === true) {
      // For string values, create a table-like display
      setChartOptions({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: function(tooltipItems) {
                const item = tooltipItems[0];
                if (!item) return '';
                return format(item.raw.x, 'MMM d, yyyy');
              },
              label: function(context) {
                const dataPoint = context.raw;
                if (!dataPoint) return '';
                
                // For string values, just show the value
                return `Value: ${dataPoint.y}`;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'time',
            position: 'top', // Position dates at the top
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM d, yyyy'
              }
            },
            grid: {
              display: true,
              drawBorder: true,
              color: '#f0f0f0'
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              color: '#666666'
            }
          },
          y: {
            type: 'category', // Use category scale for string values
            position: 'left',
            labels: (() => {
              // Get unique values
              const uniqueValues = [...new Set(targets.map(target => getTargetValue(target)))];
              return uniqueValues.sort();
            })(),
            grid: {
              display: true,
              drawBorder: true,
              color: '#f0f0f0'
            },
            ticks: {
              padding: 10,
              color: '#666666'
            }
          }
        }
      });

      // Update chart data for string values
      setChartData({
        datasets: [{
          label: 'Test Results',
          data: targets.map(target => ({
            x: new Date(getTargetTestDate(target)),
            y: getTargetValue(target),
            originalTarget: target
          })),
          pointBackgroundColor: (context) => {
            const target = context.raw?.originalTarget;
            if (!target) return Color.secondary_bg;
            return riskColors[getTargetRisk(target)];
          },
          pointBorderColor: '#fff',
          pointRadius: 6,
          pointHoverRadius: 8,
          showLine: false // Don't connect points with lines
        }]
      });
    }
    
    else {
      setChartOptions({
        clip: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: function() {
                // Dynamically set time unit based on number of data points and date range
                if (results.length <= 1) return 'day';
                
                // Calculate date range in days
                const firstDate = new Date(getTargetTestDate(results[0]));
                const lastDate = new Date(getTargetTestDate(results[results.length - 1]));
                const daysDiff = Math.ceil((lastDate - firstDate) / (1000 * 60 * 60 * 24));
                
                if (daysDiff <= 14) return 'day'; // For 2 weeks or less, show days
                if (daysDiff <= 90) return 'week'; // For 3 months or less, show weeks
                if (daysDiff <= 730) return 'month'; // For 2 years or less, show months
                return 'year'; // For more than 2 years, show years
              }(),
              displayFormats: {
                day: 'MMM d, yyyy',
                week: 'MMM d, yyyy',
                month: "MMM ''yy",
                year: 'yyyy'
              },
              tooltipFormat: 'MMM d, yyyy'
            },
            title: {
              display: false,
            },
            ticks: {
              display: true,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              source: results.length <= 7 ? 'data' : 'auto',
              color: '#666666',
            },
            grid: {
              display: false,
              drawBorder: false,
              z: 1,
            },
            min: (() => {
              if (results.length) {
                const firstDate = new Date(getTargetTestDate(results[0]));
                const lastDate = new Date(getTargetTestDate(results[results.length - 1]));
                const daysDifference = Math.ceil((lastDate - firstDate) / (1000 * 60 * 60 * 24));
                
                const paddingDays = Math.max(2, Math.ceil(daysDifference * 0.05));
                firstDate.setDate(firstDate.getDate() - paddingDays);
                return firstDate;
              }
              return undefined;
            })(),
            max: (() => {
              if (results.length) {
                const firstDate = new Date(getTargetTestDate(results[0]));
                const lastDate = new Date(getTargetTestDate(results[results.length - 1]));
                const daysDifference = Math.ceil((lastDate - firstDate) / (1000 * 60 * 60 * 24));
                
                const paddingDays = Math.max(2, Math.ceil(daysDifference * 0.05));
                lastDate.setDate(lastDate.getDate() + paddingDays);
                return lastDate;
              }
              return undefined;
            })(),
            afterBuildTicks: function(scale) {
              if (results.length <= 7) {
                scale.ticks = results.map(result => ({
                  value: new Date(getTargetTestDate(result)).getTime()
                }));
              }
              return;
            }
          },
          y: {
            beginAtZero: true,
            position: 'right',
            min: 0,
            border: {
              dash: [2, 2],
            },
            grid: {
              z: 1,
            },
            ticks: {
              padding: 10,
              mirror: false,
              align: 'end',
            }
          }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false
          },
          annotation: {
            annotations: {
              ...(riskRanges?.length > 0 ? 
              riskRanges.reduce((acc, { collectedAt, risk }, index) => {
                const prevRange = index > 0 ? riskRanges[index - 1] : null;

                const latestDate = Math.max(...riskRanges.map(r => new Date(r.collectedAt).getTime()));
                const isLastDate = new Date(collectedAt).getTime() === latestDate;
                
                let riskRangeIndex = 0
                for (const riskRange of risk) {
                  acc[`risk-range-${index}-${riskRangeIndex}`] = {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    xScaleID: 'x',
                    yScaleID: 'y',
                    xMin: prevRange ? new Date(prevRange.collectedAt) : undefined,
                    xMax: isLastDate ? undefined : new Date(collectedAt),
                    yMin: riskRange.greaterThan || riskRange.greaterThanOrEqual || 0,
                    yMax: riskRange.lessThan || riskRange.lessThanOrEqual,
                    backgroundColor: `${riskBackgroundColors[riskRange.level]}`,
                    borderColor: 'transparent',
                    adjustScaleRange: true
                  }
                  riskRangeIndex += 1
                }
                return acc
              }, {}) :
              test.risk.filter(({
                equal
              }) => {
                return equal === undefined
              }).reduce((acc, riskItem, index) => {
                acc[`risk-${index}`] = {
                  type: 'box',
                  drawTime: 'beforeDatasetsDraw',
                  xScaleID: 'x',
                  yScaleID: 'y',
                  yMin: riskItem.greaterThan || riskItem.greaterThanOrEqual || 0,
                  yMax: riskItem.lessThan || riskItem.lessThanOrEqual,
                  backgroundColor: `${riskBackgroundColors[riskItem.level]}`,
                  borderColor: 'transparent',
                };
                return acc;
              }, {})),
            }
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            filter: function(tooltipItem) {
              return !tooltipItem.raw?.isSynthetic;
            },
            bodyMaxWidth: 200,
            padding: 8,
            boxPadding: 4,
            enabled: true,
            callbacks: {
              title: function(tooltipItems) {
                const item = tooltipItems[0];
                if (!item) return '';
                return format(item.raw.x, 'MMM d, yyyy');
              },
              label: function(context) {
                const dataPoint = context.raw;
                
                // Handle annotation and prescription points
                if (dataPoint?.annotation || dataPoint?.prescription) {
                  const text = dataPoint.prescription ? 
                    ` ${dataPoint.prescription.eventType}: ${dataPoint.prescription.prescriptionType}` : 
                    ` Note: ${dataPoint.annotation.content}`
                  
                  return text.match(/.{1,30}(?:\s|$)/g)?.map(line => line.trim()) || [];
                }
                
                // Handle regular points
                if (!dataPoint?.originalTarget) return ['No data available'];
                
                const target = dataPoint.originalTarget;
                // Safely find the index, handle case where target might not be in targets array
                const index = targets.findIndex(t => t && t._id === target._id);
                if (typeof index !== 'number' || index < 0) return ['No data available'];
                
                const currentRisk = getTargetRisk(target);
                const currentValue = getTargetValue(target);
                const distanceToOptimal = getDistanceToOptimal(currentValue, currentRisk);

                let delta = null;
                if (index > 0) {
                  const prevTarget = targets[index-1];
                  if (prevTarget) {
                    const prevValue = getTargetValue(prevTarget);
                    if (prevValue !== undefined && currentValue !== undefined) {
                      const decimalPlaces = Math.max(
                        getDecimalPlaces(currentValue),
                        getDecimalPlaces(prevValue),
                      );
                      delta = parseFloat((currentValue - prevValue).toFixed(decimalPlaces));
                      if (delta > 0) {
                        delta = `+${delta}`;
                      }
                    }
                  }
                }

                // Safely get the value, handling potential undefined values
                const testValue = target.values?.find(value => value?.test === id);
                const displayValue = testValue && typeof testValue.value === 'string' 
                  ? testValue.value 
                  : `${context.parsed.y}`;

                let labels = [];
                
                if (currentRisk && currentRisk !== 'UNKNOWN') {
                  labels.push(`Risk level:             ${riskLabels[currentRisk] || 'Unknown'}`);
                }
                
                labels.push(`Test value:            ${displayValue}${test?.unit ? ` ${test?.unit}` : ''}`);

                if (distanceToOptimal !== null && currentRisk && currentRisk !== RiskLevel.UNKNOWN) {
                  labels.push(`To reach optimal: ${distanceToOptimal > 0 ? '+' : ''}${distanceToOptimal}${test?.unit ? ` ${test.unit}` : ''}`);
                }

                if (delta !== null) {
                  labels.push(`Change:               ${delta}${test?.unit ? ` ${test.unit}` : ''} from previous`);
                }

                return labels;
              }
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        }
      })
    }
  }

  const renderAbout = (test) => {

    if (!test) return null
    if (test.about && test.about.length > 0) {
      return test.about.map((item, index) => (
        <div key={index} className="about-item">
          {TextHelper.renderBlock(item, {currentUser, results, tests})}
        </div>
      ))
    }

    else if (test.details) {
      return <div className="test-details">{ContentHelper.formatByTestDocument(test.details, tests, setChildTest, setOpenChild)}</div>
    }

    else if (test.tagline) {
      return <p className="test-tagline">{test.tagline}</p>
    }

    return null
  } 

  const renderFAQs = (faqs) => {
    if (!faqs) return null
    if (faqs && faqs.length > 0) {
      return <Questions items={faqs} />
    }
    return null
  }

  const renderReferences = (test) => {
    if (!test) return null;
    if (test.references && test.references.length > 0) {
      return (
        <div className="references-list">
          <ol>
            {test.references.map((item, index) => (
              <li key={index} className="reference-item">
                {item.url ? (
                  <a 
                    href={item.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="reference-link"
                  >
                    {item.description}
                  </a>
                ) : (
                  <span className="reference-text">
                    {item.description}
                  </span>
                )}
              </li>
            ))}
          </ol>
        </div>
      );
    }
    return null;
  }
  
  const renderRecommendations = (test) => {
    if (!test) return null
    if (test.recommendations && test.recommendations.length > 0) {

      const hasTest = ResultHelper.getLatestTest(test.code, results, tests) && recommendations && recommendations.length > 0


      return test.recommendations.map((item, index) => {
        const recommendation = hasTest ? recommendations.find(r => r.code === item.code) : item
        if (!recommendation) return null
        return (
          <div key={index} className="recommendation-item">
            <RecommendationItem 
            key={index} 
            recommendation={recommendation} 
            patient={currentUser} 
            results={results} 
            tests={tests} 
            viewByAction={false}
          />
          </div>
        )
      })  
    }
    return null
  }

  const renderOrderButton = (test) => {
    if (!test) return null
    return <OrderButton test={test} />
  }


  const formatter = (startValue, endValue) => {
    // Add prefix for values that had comparison operators
    const prefix = typeof endValue === 'string' && (endValue.startsWith('>') ? '>' : endValue.startsWith('<') ? '<' : '');
    
    return (
      <>
        {prefix}
        <CountUp 
          start={startValue}
          end={typeof endValue === 'string' ? Number(endValue.replace(/[<>]/g, '')) : endValue} 
          separator="," 
          decimals={Math.max(
            getDecimalPlaces(startValue),
            getDecimalPlaces(endValue)
          )}
        />
      </>
    )
  }

  return (test) ? (
    <div className="test-chart-component-container">

      <Row gutter={[hasOrderButton ? 16 : 0, 0]}>
        <Col sm={24} md={hasOrderButton ? 16 : 24} style={{width: '100%'}}>

          <div className="test-chart-component">
            <TestChartDrawer
              open={openChild}
              setOpen={setOpenChild}
              test={childTest}
              setTest={setChildTest}
            />

      
            <div className="chart-header">
              <div className="test-info">
              <div className="test-name-container">
                <h1 className="test-name">
                  {test.longName || test.name}
                  {test.shortName && test.shortName !== (test.longName || test.name) && (
                    <span className="test-abbreviation">
                      {test.shortName}
                    </span>
                  )}
                </h1>
              </div>                
                {(chartData && chartOptions) ? (
                  <div className="test-value-container">
                  <span className="test-value">
                    {typeof stats?.latestValue === 'string' ? 
                      stats?.latestValue : 
                      formatter(0, stats?.latestValue)}
                    {test?.unit && <span className="test-unit">{test.unit}</span>}
                  </span>
                  
                  {getTargets()?.length > 1 && stats?.delta !== null && (
                    <div className={`delta-value ${ stats?.hasImproved === true ? 'improved' : stats?.hasImproved === false ? 'worsened' : ''}`}>
                      {stats?.delta > 0 ? '+' : ''}{stats?.delta}{" "}{test?.unit} 
                      <span className="delta-label">
                        ({stats?.delta > 0 ? '+' : ''}{((stats?.delta / (stats?.latestValue - stats?.delta)) * 100).toFixed(2)}%)
                      </span>
                    </div>
                  )}
              </div>
                ) : !(chartData && chartOptions) && (
                  <h2 className="test-tagline">{test.tagline}</h2>
                )}
          
              </div>
            </div>
          
          
            {/* Chart Area */}
            {chartData && chartOptions && (
                <div className="test-data-area">
                {(test?.string === true && !isApoe) ? (
                  <div className="test-results-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Value</th>
                          <th>Risk Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getTargets()?.map((target, index) => {
                          const riskLevel = getTargetRisk(target)?.toLowerCase();
                          return (
                            <tr key={index} className={`risk-row risk-${riskLevel}`}>
                              <td className="date-cell">{getTargetTestDate(target)}</td>
                              <td className={`value-cell value-${riskLevel}`}>
                                <span className="value-content">{getTargetValue(target)}</span>
                                {test?.unit && <span className="unit">{test.unit}</span>}
                              </td>
                              <td className="risk-cell">
                                <span className={`risk-badge risk-${riskLevel}`}>
                                  {riskLabels[getTargetRisk(target)]}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                
                ) : (
                  <div className="chart-area">
                    <div className="chart-container">
                      <Line 
                      ref={chartRef}
                      data={chartData} 
                      options={chartOptions} 
                    />
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Stats Card */}
            {chartData && chartOptions && (
              <div className="test-chart-section">
                <h2>Your Stats</h2>
                <div className="stats-cards">

              <div className="stats-card">
                <span className="stats-label">Last Test Date</span>
                <span className="stats-value">{stats?.latestTestDate}</span>
              </div>
              
              {/* <div className="stats-card">
                <span className="stats-label">Type</span>
                <span className="stats-value">{test?.absoluteCategory?.name}</span>
              </div> */}

              {/* <div className="stats-card">
                <span className="stats-label">Current Value</span>
                <span className={`stats-value`}>
                    {stats?.latestValue}
                </span>
              </div>
              */}
              <div className="stats-card">
                <span className="stats-label">Current Risk</span>
                <span className={`stats-value risk-${stats?.latestRisk?.toLowerCase()}`}>
                  {stats?.latestRisk && riskLabels[stats.latestRisk]}
                </span>
              </div>
              
              {stats?.distanceToOptimal!==0 && stats?.latestRisk !== RiskLevel.UNKNOWN && stats?.distanceToOptimal !== null && (
                <div className="stats-card">
                  <span className="stats-label">To Reach Optimal</span>
                  <span className={`stats-value`}>
                    {stats?.distanceToOptimal > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {Math.abs(stats.distanceToOptimal)} {test?.unit}
                  </span>
                </div>
              )}
              
              {(getTargets()?.length > 1 && stats?.delta !== null) && (
                <div className="stats-card">
                  <span className="stats-label">Change From Previous</span>
                  <span className={`stats-value ${stats?.delta === 0 ? 'neutral' : stats?.delta > 0 ? 'improved' : 'worsened'}`}>
                    {stats?.delta > 0 ? <ArrowUpOutlined /> : stats?.delta < 0 ? <ArrowDownOutlined /> : ''} {Math.abs(stats.delta)} {test?.unit}
                  </span>
                </div>
              )}
              </div>
            </div>)}

            {/* About Section */}
            {((test.about && test.about.length > 0) || test.details) && (
              <div className="about-section test-chart-section">
                <h2>About {test.shortName || test.name}</h2>
                <div className="about-content">
                  {renderAbout(test)}
                </div>
              </div>
            )}

            {/* Recommendations Section
            {test.recommendations && test.recommendations.length > 0 && (
              <div className="recommendations-section test-chart-section">
                <h2>How to improve {test.shortName || test.name}</h2>
                <div className="recommendations-content">
                  {renderRecommendations(test)}
                </div>
              </div>
            )} */}

            {/* FAQs Section */}
            {faqs && faqs.length > 0 && (
              <div className="faqs-section test-chart-section">
                <h2>FAQs</h2>
                <div className="faqs-content">  
                  {renderFAQs(faqs)}
                </div>
              </div>
            )}

            {/* References Section */}
            {test.references && test.references.length > 0 && (
              <div className="references-section test-chart-section">
                <h2>References</h2>
                <div className="references-content">
                  {renderReferences(test)}
                </div>
              </div>
            )}

          </div>

        </Col>
        <Col sm={0} md={hasOrderButton ? 8 : 0}>
          {/* Order button for desktop view */}
          {!isMobileView && test && (
            <div className="order-button-container">
              {renderOrderButton(test)}
            </div>
          )}
        </Col>
      </Row>

      {/* Mobile footer with order button */}
      {isMobileView && hasOrderButton && (
        <div className="mobile-footer">
          {renderOrderButton(test)}
        </div>
      )}
    </div>
  ) : (
    <div className="test-chart-loading"> 
      <div className="loading-text">
        <Spin className="loading-icon" /> <Text className="loading-text">Loading...</Text>
      </div>
    </div>
  )
}