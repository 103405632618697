const ReportSection = Object.freeze({

  // V2 sections
  CVD: "CVD",
  CANCER: "CANCER",
  METABOLIC: "METABOLIC",
  NEURO: "NEURO",
  OTHER: "OTHER",
  NUTRITION: "NUTRITION",
  EXERCISE: "EXERCISE",
  TESTING: "TESTING",
  MEDICATION: "MEDICATION",
  LIFESTYLE: "LIFESTYLE",
  PROVIDER: "PROVIDER",

  HEALTH_SCORE: "HEALTH_SCORE",
  PREGNANT_ADVICE: "PREGNANT_ADVICE",
  PREGNANT_NUTRIENTS: "PREGNANT_NUTRIENTS",
  HYPERTHYROIDISM_ADVICE: "HYPERTHYROIDISM_ADVICE",
  HYPOTHYROIDISM_ADVICE: "HYPOTHYROIDISM_ADVICE",

  SEVERE_TG_ADVICE: "SEVERE_TG_ADVICE",
  RHABDOMYOLYSIS_ADVICE: "RHABDOMYOLYSIS_ADVICE",
  METABOLIC_SYNDROME_ADVICE: "METABOLIC_SYNDROME_ADVICE",
  METABOLIC_ADVICE: "METABOLIC_ADVICE",
  CARDIO_ADVICE: "CARDIO_ADVICE",
  BP_ADVICE: "BP_ADVICE",
  HYPOTENSION_ADVICE: "HYPOTENSION_ADVICE",
  SMOKING_ADVICE: "SMOKING_ADVICE",
  LIVER_ADVICE: "LIVER_ADVICE",
  GENETIC_CARDIO_ADVICE: "GENETIC_CARDIO_ADVICE",
  CKD_ADVICE: "CKD_ADVICE",
  HYPONATREMIA_ADVICE: "HYPONATREMIA_ADVICE",
  PROSTATE_ADVICE: "PROSTATE_ADVICE",
  PLATELET_ADVICE: "PLATLET_ADVICE",
  LIPID_ADVICE: "LIPID_ADVICE",
  HOMOCYSTEINE_ADVICE: "HOMOCYSTEINE_ADVICE",
  URIC_ACID_ADVICE: "URIC_ACID",
  LOW_FERRITIN_ADVICE: "LOW_FERRITIN_ADVICE",
  ANEMIA_ADVICE: "ANEMIA_ADVICE",
  VITAMIN_D_ADVICE: "VITAMIN_D_ADVICE",
  VITAMIN_B12_ADVICE: "VITAMIN_B12_ADVICE",
  VITAMIN_B9_ADVICE: "VITAMIN_B9_ADVICE",
  VITAMIN_B_ADVICE: "VITAMIN_B_ADVICE",
  TESTOSTERONE_ADVICE: "TESTOSTERONE_ADVICE",
  INFLAMMATION_CHECK: "INFLAMMATION_CHECK",
  STRENGTH_ADVICE: "STRENGTH_ADVICE",
  GENERAL_EXERCISE_ADVICE: "GENERAL_EXERCISE_ADVICE",
  RHR_ADVICE: "RHR_ADVICE",
  MACROCYTOSIS_ADVICE: "MACROCYTOSIS_ADVICE",
  VIRAL_INFECTION_ADVICE: "VIRAL_INFECTION_ADVICE",
  LOW_WBC_COUNT: "LOW_WBC_COUNT_ADVICE",
  OMEGA_3_ADVICE: "OMEGA_3_ADVICE",
  
  AUTOIMMUNE_ADVICE: "AUTOIMMUNE_ADVICE",
  HRT_IMPACT: "HRT_IMPACT",
  SERM_IMPACT: "SERM_IMPACT",
  HORMONE_CHECK: "HORMONE_CHECK",
  HORMONE_CHECK_FEMALE: "HORMONE_CHECK_FEMALE",
  FERRITIN_CHECK: "FERRITIN_CHECK",
  SHBG_CHECK: "SHBG_CHECK",
  GILBERTS_SYNDROME: "GILBERTS_SYNDROME",
  CREATINE_IMPACT: "CREATINE_IMPACT",
  ISOLATED_FREE_T4_ADVICE: "ISOLATED_FREE_T4_ADVICE",
  ISOLATED_TSH_ADVICE: "ISOLATED_TSH_ADVICE",
  DEHYDRATED_ADVICE: "DEHYDRATED_ADVICE",
  VAT_ADVICE: "VAT ADVICE",
  INTENSE_EXERCISE_IMPACT: "INTENSE_EXERCISE_IMPACT",
  HIGH_CAC_ADVICE: "HIGH_CAC_ADVICE",
  SLEEP_ADVICE: "SLEEP_ADVICE",
  CORTISOL_ADVICE: "CORTISOL_ADVICE",
  CRITICAL_RESULTS: "CRITICAL_RESULTS",
  PHOSPHORUS_ADVICE: "PHOSPHORUS_ADVICE",
  HEAVY_METAL_ADVICE: "HEAVY_METAL_ADVICE",
  // Catch all sections
  FIXED_TESTS: "FIXED_TESTS",
  MULTIPLE_ABNORMAL_TESTS: "MULTIPLE_ABNORMAL_TESTS",
  SINGLE_ABNORMAL_TEST: "SINGLE_ABNORMAL_TEST",
  NEXT_STEPS: "NEXT_STEPS",
});


export default ReportSection;
