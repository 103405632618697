import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { CleerlyConfirm } from "./cleerlyConfirm.component.js"
import { PanelModal } from "../panelModal/panelModal.component"
import { heartHealthPanel } from "../../data/heartHealthPanel.data"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import { MembershipPointsModal } from "../membershipPointsModal/membersipPointsModal.component";
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  ACCOUNT: 'account',
  LOCATION: 'location',
  FACILITY: 'facility',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  PHONE: 'phone',
  HAS_ALLERGIES: 'has-allergies',
  ALLERGIES: 'allergies',
  HAS_MEDICATIONS: 'has-medications',
  MEDICATIONS: 'medications',
  PAY: 'pay',
  SHIPPING: 'shipping',
  CONFIRM: 'confirm',
}

export const CleerlyFlow = () => {
  const { instalabMembership, currentUser, setHasLongevityMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  
  const [openPanel, setOpenPanel] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipType, setMembershipType] = useState()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [facilityPrice, setFacilityPrice] = useState()

  const navigate = useNavigate()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, hasPass, initialUser, instalabMembership, setHasLongevityMembership, flow, currentUser])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])
  
  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.CLEERLY,
          ]
        }
      }
    })
    setProductTypes(response)
  }

  useEffect(() => {
    if (flow?.facility && flow.facility.menu) {
      const product = flow.facility.menu.find(f => f.productType === getProductType(ProductTypeCode.CLEERLY)?._id);
      if (product && product.price) {
        setFacilityPrice(product.price);
      }
    }
  }, [flow, productTypes]);


  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchMembershipType = async () => {
    const response = await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY)
    setMembershipType(response)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return

    const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.CLEERLY)], instalabMembership, currentUser)

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.CLEERLY}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildProduct:true,
        nextStep: Step.FACILITY,
        title: `What's your current address?`,
        description: `We'll search for imaging centers near this address.`,
        field: {
          name: 'location'
        },
      },
      [Step.FACILITY]: {
        type: StepType.FACILITY,
        nextStep: Step.NAME,
        buildFlow: true,
        productTypeCode: ProductTypeCode.CLEERLY,
        showPrice: true,
        title: `Select your preferred imaging center.`,
        description: `We’ll book your CCTA with Cleerly at this facility. You’ll pay the facility directly at your appointment.`,
        field: {
          name: 'facility',
          required: true,
        },
      },
      
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `This is required for the physician referral.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.DOB
          }
        },
        buildUser: true,
        title: `What's your assigned sex at birth?`,
        description: `This is required for the physician referral.`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `Are you currently pregnant?`,
        description: 'Please note you should not get the scan done if there is any chance you may be pregnant.',
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        skipIf: () => {
          return initialUser?.gender === Gender.MALE
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.HAS_ALLERGIES,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.HAS_ALLERGIES]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasAllergies) {
            return Step.ALLERGIES
          } 
          else {
            return Step.HAS_MEDICATIONS
          }
        },
        buildUser: true,
        title: 'Do you currently have any allergies?',
        description: 'For example to drugs, food, iodinated contrast, etc.',
        field: {
          name: "hasAllergies",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.ALLERGIES]: {
        type: StepType.TEXTAREA,
        nextStep: Step.HAS_MEDICATIONS,
        buildUser: true,
        title: `Please list your allergies.`,
        description: `Separate each allergy with a new line.`,
        field: {
          name: 'allergies',
          placeholder: '',
        },
      },
      [Step.HAS_MEDICATIONS]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: (patient) => {
          if (patient?.hasMedications) {
            return Step.MEDICATIONS
          } 
          else {
            return Step.PAY
          }
        },
        buildUser: true,
        title: 'Are you taking any prescribed medications?',
        field: {
          name: "hasMedications",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.MEDICATIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.PAY,
        buildUser: true,
        title: `Please list each prescribed medication you currently take.`,
        description: `Separate each one with a new line. Include dosage if you know it.`,
        field: {
          name: 'freeformMedications',
          placeholder: 'Type medications here ...',
        },
      },
  
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.SHIPPING,
        addProduct: !flow?.addLongevityMembership,
        productType: ProductTypeCode.CLEERLY,
        title: <>Pay <span className="true-price">${totalCost}</span> for the service fee.</>,
        description: <>This fee covers your required physician order, scheduling and result report. <p><i>Note: This doesn't include the scan cost{facilityPrice && ` ($${facilityPrice})`}, which you'll pay directly to {flow?.facility ? flow?.facility.name : 'the facility'}.</i></p></>,
        skipIf: () => {
          return instalabMembership 
        }
      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `What's your shipping address?`,
        description: `We may prescribe and send you an oral beta blocker for your scan. This medication is highly recommended to take 12 hours and 1 hour before your scan. It helps lower your heart rate, ensuring clear imaging results of your heart.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set! 🎉`,
        description: "We'll email you within 1 business day.",
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <CleerlyConfirm/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }
  

  return ( <>
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={1}
      productTypeCode={ProductTypeCode.CLEERLY}
    />

    <MembershipPointsModal
      open={openMembershipPointsModal}
      setOpen={setOpenMembershipPointsModal}
    />



    <PanelModal
      panel={heartHealthPanel}
      title="Advanced Heart Health Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
    </>
  )
}