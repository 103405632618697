import { Collapse } from 'antd';
import './questions.scss';

const { Panel } = Collapse

export const Questions = ({ items }) => {
  return (
    <div className="questions">
      <Collapse
        accordion
        bordered={false}
        expandIconPosition='end'
        className="question-collapse"
      >
        {items.map(({ question, answer }, index) => (
          <Panel
            key={`question-${index}`}
            header={question}
            className="question-panel"
          >
            <div className="answer">
              {answer}
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}