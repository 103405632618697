import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Input, 
  Row, 
  Col, 
  Card, 
  Tag, 
  Divider, 
  Spin, 
  Empty, 
  Select,
  Typography,
  Tabs,
  Button,
  Space
} from 'antd';
import { 
  SearchOutlined, 
  ExperimentOutlined, 
  FileTextOutlined,
  AppstoreOutlined,
  FireOutlined,
  ClearOutlined
} from '@ant-design/icons';
import { TestChartDrawer } from '../testChartDrawer/testChartDrawer.component';
import { listTests } from '../../services/test.service';
import { listPanels } from '../../services/panel.service';
import { PanelCode } from '../../enums/index.enum';
import './testIndex.scss';
import { TextHelper } from '../../helpers/text.helper';
import { useSearchParams } from 'react-router-dom';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const PANEL_BLACKLIST = [
    PanelCode.ATHLETE
]

// Modify the SearchInput component to maintain focus
const SearchInput = ({ value, onChange }) => {
  const inputRef = useRef(null);
  
  // Add a useEffect to refocus the input after value changes
  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current.input;
      if (input) {
        // Get the current cursor position
        const cursorPosition = input.selectionStart;
        // Refocus and restore cursor position
        input.focus();
        input.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
  }, [value]); // Re-run when value changes
  
  // Handle change locally before passing to parent
  const handleChange = (e) => {
    onChange(e);
    // Make sure input doesn't lose focus during the change
    setTimeout(() => {
      if (inputRef.current && inputRef.current.input) {
        inputRef.current.input.focus();
      }
    }, 0);
  };
  
  return (
    <Input
      ref={inputRef}
      className="search-input"
      placeholder="Search by name"
      prefix={<SearchOutlined />}
      onChange={handleChange}
      value={value}
      size="large"
    />
  );
};

export const TestIndex = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [categories, setCategories] = useState([]);
  const [typeFilter, setTypeFilter] = useState('all');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeTab, setActiveTab] = useState('popular');

  // Get URL parameters and navigation utilities
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Get default values from URL or fallback to defaults
  // const defaultCategory = searchParams.get('category') || 'popular';
  // const defaultSearchTerm = searchParams.get('search') || '';
  
  // State for active category
  // const [activeCategory, setActiveCategory] = useState(defaultCategory);

  useEffect(() => {
    document.title = 'Lab Test Index | Instalab';
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        const [testsData, panelsData] = await Promise.all([
          listTests({ populate: ['absoluteCategory']}),
          listPanels({
            filter: {active: true, code: {$nin: PANEL_BLACKLIST}},
            populate: ['productType', {path: 'tests', select: '_id code cost tagline name shortName longName', populate: {path: 'absoluteCategory'}}]
          })
        ]);
        
        // Filter out tests/panels with no cost or associated productType
        const validPanels = panelsData.filter(panel => (panel.productType || panel.cost));
        const validTests = testsData.filter(test => {
          // First check: if the test has a cost > 0, it's valid
          if (test.cost > 0) return true;
          
          // Second check: if the test is included in any valid panel
          return panelsData.some(panel => {
            // Find if the test exists in this panel
            const testInPanel = panel.tests.some(t => t._id === test._id);
            return testInPanel && (panel.productType || panel.cost);
          });
        });
        
        // Process only the valid panels
        const processedPanels = validPanels.map(panel => {
          // Extract all unique categories from the panel's tests
          const panelCategories = [];
          if (panel.tests && panel.tests.length > 0) {
            panel.tests.forEach(test => {
              if (test.absoluteCategory && !panelCategories.some(cat => cat._id === test.absoluteCategory._id)) {
                panelCategories.push(test.absoluteCategory);
              }
            });
          }
          
          return {
            ...panel,
            itemType: 'panel',
            shortName: panel.tests?.length === 1 ? panel.tests[0].shortName || panel.tests[0].name : panel.shortName || panel.name,
            name: panel.tests?.length === 1 ? panel.tests[0].name : panel.name,
            longName: panel.tests?.length === 1 ? panel.tests[0].name || panel.tests[0].longName : panel.name,
            tagline: panel.tests?.length === 1 ? panel.tests[0].tagline : panel.tagline,
            productType: panel.productType,
            testCategories: panelCategories,
            cost: panel.productType ? panel.productType.costIsServiceFee ? null : panel.productType.cost : panel.cost            
          };
        });


        const processedTests = validTests.map(test => ({
          ...test,
          itemType: 'test',
          cost: (() => {
            // First, check if the test has its own cost
            if (test.cost > 0) return test.cost;
            
            // Find all panels containing this test that have a valid cost
            const relevantPanelCosts = processedPanels
              .filter(panel => 
                panel.tests.some(t => t._id === test._id) && 
                panel.cost && 
                panel.cost > 0
              )
              .map(panel => panel.cost);
            
            // If we found panels with costs, return the minimum cost
            if (relevantPanelCosts.length > 0) {
              return Math.min(...relevantPanelCosts);
            }
            
            // No valid cost found
            return 0; // or any default you prefer
          })(),
          productType: test.cost > 0 ? null : processedPanels.find(panel => panel.tests.some(t => t._id === test._id))?.productType
        }));
        
        const allItems = [...processedTests, ...processedPanels.filter(panel => panel.tests.length > 1)].sort((a, b) => 
          (a.shortName || a.name).localeCompare(b.shortName || b.name)
        );
        
        // Extract all unique categories for the filter dropdown
        const categoriesMap = {};
        
        // Add categories from individual tests
        allItems.forEach(item => {
          if (item.itemType === 'test' && item.absoluteCategory && item.absoluteCategory._id) {
            categoriesMap[item.absoluteCategory._id] = {
              _id: item.absoluteCategory._id,
              name: item.absoluteCategory.name
            };
          }
          
          // Add all test categories from panels
          if (item.itemType === 'panel' && item.testCategories && item.testCategories.length > 0) {
            item.testCategories.forEach(category => {
              if (category && category._id) {
                categoriesMap[category._id] = {
                  _id: category._id,
                  name: category.name
                };
              }
            });
          }
        });
        
        const uniqueCategories = Object.values(categoriesMap)
          .sort((a, b) => a.name.localeCompare(b.name));
        
        setItems(allItems);
        setFilteredItems(allItems);
        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    let filtered = items;

    
    // Always apply search, category, and type filters regardless of tab
    if (searchTerm) {
      const term =  TextHelper.normalizeText(searchTerm);
      filtered = filtered.filter(item => 
        // Search in item properties
        (item.name && TextHelper.normalizeText(item.name).includes(term)) ||
        (item.tagline && TextHelper.normalizeText(item.tagline).includes(term)) ||
        (item.longName && TextHelper.normalizeText(item.longName).includes(term)) ||
        (item.shortName && TextHelper.normalizeText(item.shortName).includes(term)) ||
        (item.productType && item.productType.title && TextHelper.normalizeText(item.productType.title).includes(term)) ||
        (item.alternativeNames && item.alternativeNames.some(name => 
          TextHelper.normalizeText(name).includes(term)
        )) ||
        (item.altNames && item.altNames.some(name => 
          TextHelper.normalizeText(name).includes(term)
        )) ||
        
        // Search in tests within panels
        (item.itemType === 'panel' && item.tests && item.tests.some(test => 
          // Search in test name
          (test.name && TextHelper.normalizeText(test.name).includes(term)) ||
          // Search in test shortName
          (test.shortName && TextHelper.normalizeText(test.shortName).includes(term)) ||
          // Search in test alternativeNames if they exist
          (test.alternativeNames && test.alternativeNames.some(name => 
            TextHelper.normalizeText(name).includes(term)
          )) ||
          // Search in test altNames if they exist
          (test.altNames && test.altNames.some(name => 
            TextHelper.normalizeText(name).includes(term)
          )) ||
          // Search in test tagline
          (test.tagline && TextHelper.normalizeText(test.tagline).includes(term))
        ))
      );
    }
    
    if (categoryFilter && categoryFilter !== 'all') {
      filtered = filtered.filter(item => {
        if (item.itemType === 'test') {
          // For tests, check the absoluteCategory
          return item.absoluteCategory && item.absoluteCategory._id === categoryFilter;
        } else if (item.itemType === 'panel') {
          // For panels, ONLY check if any of the panel's tests belong to the selected category
          return item.testCategories && 
                 item.testCategories.some(cat => cat && cat._id === categoryFilter);
        }
        return false;
      });
    }
    
    if (typeFilter && typeFilter !== 'all') {
      filtered = filtered.filter(item => item.itemType === typeFilter);
    }
    
    // Only apply the isPopular filter when on the popular tab
    if (activeTab === 'popular') {
      filtered = filtered.filter(item => item.isPopular);
    }
    
    setFilteredItems(filtered);
  }, [searchTerm, categoryFilter, typeFilter, items, activeTab]);

  // 1. Add a reset filters function
  const resetFilters = () => {
    // Clear both the input value and the actual search term
    setSearchInputValue('');
    setSearchTerm('');
    
    // Reset other filters
    setCategoryFilter('all');
    setTypeFilter('all');
    
    // Update URL to remove search and keep only category
    setSearchParams(params => {
      params.delete('search');
      return params;
    });
  };

  // 2. Handle tab changes with filter reset option
  const handleTabChange = (key) => {
    // Update local tab state
    setActiveTab(key);
    
    // Update URL, preserving the search term if it exists
    setSearchParams(params => {
      params.set('tab', key);
      return params;
    });
  };

   // Update state when URL changes
   useEffect(() => {
    const tabFromUrl = searchParams.get('tab');
    const searchFromUrl = searchParams.get('search');
  
    if (tabFromUrl) {
      setActiveTab(tabFromUrl);
    }
    
    if (searchFromUrl !== undefined && searchFromUrl !== searchTerm) {
      setSearchTerm(searchFromUrl || '');
      setSearchInputValue(searchFromUrl || '');
    }
  }, [searchParams]);
  

  const groupItemsByLetter = () => {
    const grouped = {};
    
    filteredItems.forEach(item => {
      const name = item.shortName || item.name;
      const firstLetter = name.charAt(0).toUpperCase();
      
      if (!grouped[firstLetter]) {
        grouped[firstLetter] = [];
      }
      
      grouped[firstLetter].push(item);
    });
    
    return Object.entries(grouped).sort(([a], [b]) => a.localeCompare(b));
  };

  const groupItemsByCategory = () => {
    const grouped = {};
    
    // First pass: handle individual tests and primary panel categories
    filteredItems.forEach(item => {
      let categoryName = 'Uncategorized';
      let categoryId = 'uncategorized';
      
      if (item.itemType === 'test' && item.absoluteCategory && item.absoluteCategory.name) {
        categoryName = item.absoluteCategory.name;
        categoryId = item.absoluteCategory._id;
        
        if (!grouped[categoryId]) {
          grouped[categoryId] = {
            name: categoryName,
            items: []
          };
        }
        
        grouped[categoryId].items.push(item);
      } else if (item.itemType === 'panel') {
      
        
        // Second pass for panels: add references to additional categories
        // This is only for the "By Category" view when not filtering
        if (!categoryFilter || categoryFilter === 'all') {
          if (item.testCategories && item.testCategories.length > 0) {
            item.testCategories.forEach(category => {
              // Skip the primary category as the panel is already there
              if (item.primaryCategory && category._id === item.primaryCategory._id) {
                return;
              }
              
              if (!grouped[category._id]) {
                grouped[category._id] = {
                  name: category.name,
                  items: []
                };
              }
              
              // Add a reference flag to indicate this is a secondary category
              const panelWithRef = { 
                ...item,
                isSecondaryCategory: true 
              };
              grouped[category._id].items.push(panelWithRef);
            });
          }
        }
      }
    });
    
    return Object.entries(grouped).sort(([, a], [, b]) => 
      a.name.localeCompare(b.name)
    );
  };

  const renderItemCard = (item) => {
    const isPanel = item.itemType === 'panel';
    
    const handleCardClick = () => {
      setSelectedItem(item);
      setDrawerOpen(true);
    };
    
    return (
      <div key={item._id} style={{ height: '100%' }}>
        <Card 
          className={`test-card ${isPanel ? 'panel-card' : ''}`}
          hoverable
          onClick={handleCardClick}
        >
          <div className="test-card-content">
            <div className="card-header">
              <h3 className="test-title">
                {item.longName || item.name}
              </h3>
     
            </div>
            
            {item.shortName && (item.shortName !== (item.longName || item.name)) && (
              <div className="test-full-name">{item.shortName}</div>
            )}
            
            {item.tagline && (
              <div className="item-tagline">{item.tagline}</div>
            )}
            
            <div className="content-spacer"></div>
            
            <div className="card-footer">
              {item.cost > 0 ? (
                <span className="price-tag">
                  ${item.cost}
                </span>
              ) : item.productType && item.productType.lowestPrice && item.productType.highestPrice ? (
                <span className="price-tag price-tag-location-based">
                  {TextHelper.formatCurrency(item.productType.lowestPrice)} - {TextHelper.formatCurrency(item.productType.highestPrice)}
                </span>
              ) : null}

              {isPanel && item.tests && item.tests.length > 1 && (
                <Tag className="tests-count-tag">
                  {item.tests.length} biomarkers
                </Tag>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const renderAlphabeticalSection = () => {
    const letterGroups = groupItemsByLetter();
    
    return (
      <>
        <div className="alphabet-nav">
          <Space wrap>
            {letterGroups.map(([letter]) => (
              <a
                key={letter}
                href={`#letter-${letter}`}
                className="letter-link"
              >
                <Button type="default">
                  {letter}
                </Button>
              </a>
            ))}
          </Space>
        </div>
        
        <div className="letter-groups">
          {letterGroups.map(([letter, items]) => (
            <div 
              className="letter-group" 
              key={letter} 
              id={`letter-${letter}`}
            >
              <Title level={2} className="letter-heading" id={`letter-${letter}`}>
                <span className="letter-badge">{letter}</span>
                <span className="letter-count">{items.length} items</span>
              </Title>
              
              <Row gutter={[16, 16]} className="tests-grid">
                {items.map(item => (
                  <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
                    {renderItemCard(item)}
                  </Col>
                ))}
              </Row>
              <Divider />
            </div>
          ))}
        </div>
      </>
    );
  };

  const categoryView = () => (
    <div className="category-index">
      {groupItemsByCategory().map(([categoryId, category]) => (
        <div className="category-group" key={categoryId} id={`category-${categoryId}`}>
          <Title level={3} className="category-heading">
            {category.name}
            <span className="category-count">{category.items.length} items</span>
          </Title>
          
          <Row gutter={[16, 16]} className="tests-grid">
            {category.items.map(item => (
              <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
                {renderItemCard(item)}
              </Col>
            ))}
          </Row>
          <Divider />
        </div>
      ))}
    </div>
  );

  const popularView = () => (
    <div className="popular-index">
      <Row gutter={[16, 16]} className="tests-grid">
        {filteredItems.map(item => (
          <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
            {renderItemCard(item)}
          </Col>
        ))}
      </Row>
      
      {filteredItems.length === 0 && (
        <Empty 
          description="No popular items available" 
          image={Empty.PRESENTED_IMAGE_SIMPLE} 
        />
      )}
    </div>
  );

  // 3. Create a dedicated filter component
  const FilterBar = () => (
    <div className="search-filters">
      <div className="filter-controls">
        <SearchInput 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        
        <div className="select-filters">
          <Select
            className="type-select"
            value={typeFilter}
            onChange={setTypeFilter}
            size="large"
            placeholder="Filter by type"
          >
            <Option value="all">All Types</Option>
            <Option value="test">Individual Tests Only</Option>
            <Option value="panel">Test Panels Only</Option>
          </Select>
          
          <Select
            className="category-select"
            value={categoryFilter}
            onChange={setCategoryFilter}
            size="large"
            placeholder="Filter by category"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="all">All Categories</Option>
            {categories.map(category => (
              <Option key={category._id} value={category._id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      
      {/* 4. Add active filters display and reset button */}
      {(searchTerm || categoryFilter !== 'all' || typeFilter !== 'all') && (
        <div className="active-filters">
          <div className="filter-tags">
            {searchTerm && (
              <Tag closable onClose={resetFilters}>
                Search: {searchTerm}
              </Tag>
            )}
            {categoryFilter !== 'all' && (
              <Tag closable onClose={() => setCategoryFilter('all')}>
                Category: {categories.find(c => c._id === categoryFilter)?.name}
              </Tag>
            )}
            {typeFilter !== 'all' && (
              <Tag closable onClose={() => setTypeFilter('all')}>
                Type: {typeFilter === 'test' ? 'Individual Tests Only' : 'Test Panels Only'}
              </Tag>
            )}
          </div>
          <Button 
            type="link" 
            onClick={resetFilters}
            icon={<ClearOutlined />}
          >
            Clear All Filters
          </Button>
        </div>
      )}
      
      {/* 5. Add results count within filter bar */}
      <div className="filter-results-count">
        <Text type="secondary">
          {activeTab === 'popular' 
            ? `${filteredItems.length} popular items match your filters`
            : `${filteredItems.length} items match your filters`
          }
        </Text>
      </div>
    </div>
  );

  // Add a check for results in other tabs
  const getResultsInOtherTabs = () => {
    if (activeTab === 'all') return 0;
    
    // Check how many items match the current filters regardless of tab
    const allMatches = items.filter(item => {
      let match = true;
      
      if (searchTerm) {
        const term = searchTerm.toLowerCase();
        match = match && (
          (item.name && item.name.toLowerCase().includes(term)) ||
          (item.tagline && item.tagline.toLowerCase().includes(term)) ||
          (item.longName && item.longName.toLowerCase().includes(term)) ||
          (item.shortName && item.shortName.toLowerCase().includes(term)) ||
          (item.alternativeNames && item.alternativeNames.some(name => 
            name.toLowerCase().includes(term)
          )) ||
          (item.altNames && item.altNames.some(name => 
            name.toLowerCase().includes(term)
          )) ||
      
          // Search in tests within panels
          (item.itemType === 'panel' && item.tests && item.tests.some(test => 
            // Search in test name
            (test.name && test.name.toLowerCase().includes(term)) ||
            // Search in test shortName
            (test.shortName && test.shortName.toLowerCase().includes(term)) ||
            // Search in test alternativeNames if they exist
            (test.alternativeNames && test.alternativeNames.some(name => 
              name.toLowerCase().includes(term)
            )) ||
            // Search in test altNames if they exist
            (test.altNames && test.altNames.some(name => 
              name.toLowerCase().includes(term)
            )) ||
            // Search in test tagline
            (test.tagline && test.tagline.toLowerCase().includes(term))
          ))
        );
      }
      
      if (categoryFilter && categoryFilter !== 'all') {
        // Apply category filter logic
        // ... (your existing category filter logic)
      }
      
      if (typeFilter && typeFilter !== 'all') {
        match = match && (item.itemType === typeFilter);
      }
      
      return match;
    });
    
    // If on popular tab, exclude popular items from count
    if (activeTab === 'popular') {
      return allMatches.filter(item => !item.isPopular).length;
    }
    
    return allMatches.length - filteredItems.length;
  };

  return (
    <div className="test-index-page">
      <div className="page-header">
        <ExperimentOutlined className="header-icon" />
        <Title level={1}>Instalab Test Index</Title>
        <Text className="page-description">
          Browse our comprehensive list of tests.
        </Text>
      </div>
      
      {/* Add the filter component */}
      <FilterBar />
      
      {loading ? (
        <div className="loading-container">
          <Spin size="large" />
          <p>Loading tests ...</p>
        </div>
      ) : filteredItems.length === 0 ? (
        <div className="no-results">
          <Empty 
            description={
              <>
                <p>{activeTab === 'popular' ? 
                  "No popular items match your search" : 
                  "No items match your search criteria"}
                </p>
                
                {getResultsInOtherTabs() > 0 && (
                  <div className="other-results-message">
                    <p>Found {getResultsInOtherTabs()} matching items in other views</p>
                    <Button 
                      type="primary" 
                      onClick={() => setActiveTab('all')}
                    >
                      View all matching results
                    </Button>
                  </div>
                )}
              </>
            } 
            image={Empty.PRESENTED_IMAGE_SIMPLE} 
          />
        </div>
      ) : (
        <div className="tests-container">
          <Tabs 
            defaultActiveKey="popular"
            activeKey={activeTab}
            onChange={handleTabChange}
            className="view-tabs"
            type="card"
          >
            <TabPane 
              tab={<span><FireOutlined /> Most Popular</span>} 
              key="popular"
            >
              {popularView()}
            </TabPane>
            <TabPane 
              tab={<span><FileTextOutlined /> All Tests A-Z</span>} 
              key="all"
            >
              {renderAlphabeticalSection()}
            </TabPane>
            <TabPane 
              tab={<span><AppstoreOutlined /> By Category</span>} 
              key="category"
            >
              {categoryView()}
            </TabPane>
          </Tabs>
        </div>
      )}
      

      
      <TestChartDrawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        test={selectedItem}
        setTest={setSelectedItem}
      />
    </div>
  );
};

