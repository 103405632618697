import {  Typography } from 'antd';
import FlowType from '../../enums/flowType.enum';

const { Text } = Typography

export const ServiceFeeNotice = ({ productType, complex=true, consult=false}) => {
    return (
        consult ?
            <div className="service-fee-notice"> 
                <Text strong>Concierge Fee</Text>: Instalab charges a ${productType?.cost || 25} concierge fee for finding your specialist and scheduling your appointment. This is separate from any fees that may be charged by the specialist. <Text underline><a className="cta-link"href={`/flow/${FlowType.MEMBER}`}>Members pay no service fee</a></Text>.
            </div>
        :
            complex 
                ?          
                    <div className="service-fee-notice"> 
                    <Text strong>Service Fee</Text>: Instalab charges a ${productType?.cost || 25} service fee for the physician order, scheduling, and interpretiation of results. This is separate from the cost of the test paid to the facility. <Text underline><a className="cta-link"href={`/flow/${FlowType.MEMBER}`}>Members pay no service fee</a></Text>.
                </div>
            :
                <div className="service-fee-notice"><Text strong>Service Fee</Text>: Instalab charges a ${productType?.cost} service fee for scheduling your appointment and result interpretation. This is separate from the test cost, which is paid directly to the facility. <Text underline><a className="cta-link"href={`/flow/${FlowType.MEMBER}`}>Members pay no service fee</a></Text>.</div>
        )
    }