import { Card, Row, Col, Button, Dropdown, Tag, Tooltip } from 'antd';
import { 
  CalendarOutlined, 
  EnvironmentOutlined, 
  VideoCameraOutlined, 
  MoreOutlined,
  EyeOutlined 
} from '@ant-design/icons';
import { useState } from 'react';
import { ReactComponent as DoctorIcon } from '../../assets/svg/doctor-2.svg';
import UserHelper from '../../helpers/user.helper.js';
import dayjs from 'dayjs';
import { AppointmentStatus } from '../../enums/index.enum.js';
import { useNavigate } from 'react-router-dom';
import AppointmentHelper from '../../helpers/appointment.helper.js';
import "./appointmentCard.scss";
import { UserContext } from '../../contexts/user.context.js';
import { useContext } from 'react';
import { listTests } from '../../services/test.service.js';
import TestPanelModal from '../testPanelModal/testPanelModal.component.js';
import { Gender } from '../../enums/index.enum.js';

// New components to organize the information
const AppointmentHeader = ({ appointment, actionItems }) => (
  <>
    {actionItems.length > 0 && (
      <Dropdown menu={{ items: actionItems }} placement="bottomRight" trigger={['click']}>
        <Button type="text" icon={<MoreOutlined />} className="more-actions-button" />
      </Dropdown>
    )}
  </>
);

const AppointmentTests = ({ title, tests, panels, currentUser, onViewTests, hideBiomarkers }) => (
  <div className="tests">
    {title}
    {!hideBiomarkers && (
      (() => {
        const allTests = [...tests, ...panels.map(panel => 
          panel.hasSexSpecificTests 
            ? currentUser.gender === Gender.MALE ? panel.maleTests : panel.femaleTests 
          : panel.tests
      ).flat()];
      
      return allTests.length > 0 && (
        <Tooltip className="test-tooltip" title="Click to view biomarkers included">
          <Tag 
            style={{ marginLeft: 8 }} 
            color="default"
            onClick={onViewTests}
            className="clickable"
          >
            {allTests.length} biomarker{allTests.length > 1 ? 's' : ''}
          </Tag>
        </Tooltip>
      );
    }))()} 
  </div>
);

const AppointmentLocation = ({ appointment, locationDisplay }) => (
  appointment.remoteLocation ? (
    <div className="remote-location">
      <VideoCameraOutlined className="anticon" />
      <span>
        {(() => {
          try {
            new URL(appointment.remoteLocation);
            return <a href={appointment.remoteLocation} className="remote-location-link" target="_blank" rel="noopener noreferrer">
              {appointment.remoteLocation}
            </a>;
          } catch {
            return appointment.remoteLocation;
          }
        })()}
      </span>
    </div>
  ) : (
    <div className="location">
      <EnvironmentOutlined className="anticon" />
      <span>{locationDisplay}</span>
    </div>
  )
);

const AppointmentCard = ({ 
    appointment, 
    defaultView = true,
    actionItems = [],
    hideBiomarkers = false
  }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const { location, facility } = appointment;

  // location 
  const address = facility?.location || location;
  const { streetAddress, streetAddress2, city, state, postalCode } = address || {};
  const fullAddress = address ? `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}` : 'TBD';
  const locationDisplay = facility ? `${facility.name} - ${fullAddress}` : fullAddress;

  // title
  const { title, tests, panels } = AppointmentHelper.getTitle(appointment, currentUser);


  const [testsModalVisible, setTestsModalVisible] = useState(false);
  const [testDetails, setTestDetails] = useState([]);

  const fetchTestDetails = async () => {
    try {
      const response = await listTests();
      const testDetailsMap = response.reduce((acc, test) => {
        acc[test._id] = test;
        return acc;
      }, {});

      // Process panel tests first
      const panelTestDetails = panels.map(panel => ({
        _id: panel._id,
        isPanel: true,
        name: panel.name,
        tagline: panel.tagline,
        tests: panel.tests.map(test => testDetailsMap[test._id])
      }));


      // Then process standalone tests
      const standaloneTests = tests.map(test => 
        testDetailsMap[test] || { name: test.name || 'Unknown Test', ...test }
      );

      // Set panels first, followed by standalone tests
      setTestDetails([...panelTestDetails, ...standaloneTests]);
    } catch (error) {
      console.error('Error fetching test details:', error);
    }
  };

  const handleModalOpen = () => {
    if (tests?.length || panels?.length) {
      setTestsModalVisible(true);
      fetchTestDetails();
    }
  };

  return (
    <Card 
      className={`appointment-card ${defaultView ? 'flat' : ''} ${appointment.status}`}
      key={appointment._id}
      onClick={() => navigate(`/patient/appointments`)}
    >
      <div className="appointment-card-content">
        <AppointmentHeader appointment={appointment} actionItems={actionItems} />
        
        <Row justify="space-between" align="top">
          <Col flex="1">
            {defaultView && <div className="appointment-status">
              <Tag color={AppointmentHelper.getTagColor(appointment.status)}>{appointment.status}</Tag>
            </div>}

 
              <AppointmentTests 
                title={title}
                tests={tests}
                panels={panels}
                currentUser={currentUser}
                onViewTests={handleModalOpen}
                hideBiomarkers={hideBiomarkers}
              />


            <div className="time">
              <CalendarOutlined /> 
              {appointment.start ? 
                dayjs(appointment.start)
                  .tz(appointment.facility?.location?.timeZoneId || appointment.location?.timeZoneId)
                  .format('MMM D, YYYY @ h:mm a z') 
                : 'TBD'
              }
            </div>


            {appointment.provider && 
              <div className="provider">
                <DoctorIcon className="anticon" />
                <span>
                  {UserHelper.getName(appointment.provider)}
                </span>
              </div>
            }

            <AppointmentLocation appointment={appointment} locationDisplay={locationDisplay} />


            {appointment.status === AppointmentStatus.PENDING && <div className="pending-message">
              <span>Our concierge team is working on your appointment and will be in touch soon.{' '}
              Please email concierge@instalab.com with any questions.</span>
            </div>}


          </Col>

        </Row>
      </div>

      <TestPanelModal
        visible={testsModalVisible}
        onClose={() => setTestsModalVisible(false)}
        testDetails={testDetails}
      />
    </Card>
  );
};

export default AppointmentCard;