import React, { useContext, useEffect } from 'react';
import './providerCatalog.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { TestCatalog } from '../testCatalog/testCatalog.component'
import { UserContext } from '../../contexts/user.context';
import { SupportSidebar } from '../supportSidebar/supportSidebar.component';
import { Row, Col } from 'antd'

export const ProviderCatalog = () => {
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    document.title = `Home | Instalab`
  }, [])


  return  currentUser && <>
    <div className="provider-catalog">


        <Row gutter={16}>
            <Col 
              className="dashboard-main"
              >
      <PageHeader
        title={currentUser.firstName ? `Welcome back, ${currentUser.firstName}.` : `Welcome back!`}
        description={<>Select test to order for your client.</>}
      />
      <div className="test-catalog-container">
        <TestCatalog xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} showAll={true} provider={true} />
      </div>
      </Col>

        </Row>
    </div>
    </>
}