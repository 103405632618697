import React, { useState, useContext } from 'react';
import { Drawer, Button, Divider, Row, Col, Card, Image } from 'antd';
import { LeftOutlined, CalendarOutlined, ShoppingOutlined, EyeOutlined, LineChartOutlined, MessageOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/user.context';
import './recommendationDrawer.scss';
import { useEffect } from 'react';
import { TextHelper } from '../../helpers/text.helper';
import { getProductTypeByCode } from '../../services/productType.service';

export const RecommendationDrawer = ({ 
  recommendation,
  tests,
  results,
  patient,
  open,
  setOpen,
}) => {
  const { currentUser } = useContext(UserContext);
  const [drawerWidth, setDrawerWidth] = useState('80%');
  const [isMobileView, setIsMobileView] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  // Handle responsive drawer width
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
      
      if (window.innerWidth <= 768) {
        setDrawerWidth('100%');
      } 
      else if (window.innerWidth <= 1024) {
        setDrawerWidth('75%');
      }
      else {
        setDrawerWidth('50%');
      }
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, [recommendation]);

  useEffect(() => {  
    
    
    const fetchProductTypes = async () => {
      // Gather all product type codes from steps
      const productTypeCodes = recommendation.steps.flatMap(step => {
        // Handle single productType
        if (step.productType && step.productType.code) {
          return [step.productType.code];
        }
        
        // Handle productTypes array
        if (Array.isArray(step.productTypes)) {
          return step.productTypes
            .filter(product => product && product.code)
            .map(product => product.code);
        }
        
        return [];
      });
      
      // Remove duplicates
      const uniqueProductTypeCodes = [...new Set(productTypeCodes)];
      
      // Fetch all product types
      const productTypes = await Promise.all(
        uniqueProductTypeCodes.map(async (productTypeCode) => 
          await getProductTypeByCode(productTypeCode)
        )
      );
      
      setProductTypes(productTypes);
    };
    
    fetchProductTypes();
  }, [recommendation]);



  const onClose = () => {
    setOpen(false);
  };

  // Custom close icon with left arrow
  const closeIcon = (
    <Button 
      className="drawer-back-button" 
      icon={<LeftOutlined />} 
      type="text"
      onClick={onClose}
    >
    </Button>
  );

  const getProductType = (productTypeCode) => {
    return productTypes.find(productType => productType.code === productTypeCode);
  }

  const handleProductClick = (productType) => {
    const { url, code } = productType;
    if (url !== null && url !== undefined) {
      window.open(url, '_blank');
    }
    else {
      const url = getProductType(code)?.path ? getProductType(code)?.path : `/flow/${getProductType(code)?.flowType}`;
      window.open(url, '_blank');
    }
  }

  const handleFlowClick = (flowType) => {
    const { code } = flowType;
    window.open(`/flow/${code}`, '_blank');
  }

  const renderDescription = (description) => {
    if (!description) return null;
    if (typeof description === "string") {
      return description;
    }
    else {
      return description.map((block, index) => (
        <div key={index} className="description-block">{TextHelper.renderBlock(block, {tests, results, patient})}</div>
      ));
    }
  }

  const getProductTypesFromStep = (step) => {
    if (!step) return [];
    
    // If there's a productTypes array, use it
    if (Array.isArray(step.productTypes)) {
      return step.productTypes;
    }
    
    // If there's a singular productType object, return it as an array with one item
    if (step.productType && typeof step.productType === 'object') {
      return [step.productType];
    }
    
    return [];
  };

  // Create a mapping of action types to their respective icons and default text
  const actionTypeConfig = {
    order: {
      icon: <ShoppingOutlined style={{ marginRight: 8 }} />,
      defaultText: "Get Now"
    },
    schedule: {
      icon: <CalendarOutlined style={{ marginRight: 8 }} />,
      defaultText: "Schedule"
    },
    learn: {
      icon: <EyeOutlined style={{ marginRight: 8 }} />,
      defaultText: "Learn More"
    },
    concierge: {
      icon: <MessageOutlined style={{ marginRight: 8 }} />,
      defaultText: "Ask Concierge"
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        open={open}
        width={drawerWidth}
        closeIcon={closeIcon}
        push={false}
        className={`recommendation-drawer`}
      >
        <Row>
          <Col span={24 }>
            <div className="recommendation-drawer-content">

              <div className="recommendation-title">{recommendation.title}</div>

              <div className="recommendation-description">
                {recommendation.description.map((block, index) => (
                  <div key={index} className="description-block">
                    {TextHelper.renderBlock(block, {tests, results, patient})}
                  </div>
                ))}
              </div>

              {recommendation.steps.length > 0 && (
              <div className="recommendation-steps">
                <Divider orientation="center">Next Steps</Divider>
                <div className="game-plan-container">
                  {recommendation.steps.map((step, index) => (
                    <Card key={index} className={`step flat`}>
                      <div className="step-title">{step.title}</div>
                      <div className="step-description">{renderDescription(step.description)}</div>

                      {/* Product Types */}
                      {(() => {
                        const productTypes = getProductTypesFromStep(step);
                      
                        return productTypes.length > 0 ? (
                          <div className="product-recommendations-container">
                            {productTypes.map((productType, productIndex) => (
                              <div 
                                key={`product-${productIndex}`} 
                                className={`step-product-type-code ${productType.recommendTag ? 'recommend-tag' : ''} action-${productType.actionType || 'order'}`}
                              >
                                {/* Left side - content */}
                                <div className="product-content">
                                  <div className="step-product-type-code-title">
                                    {productType.title || getProductType(productType.code)?.title}
                                  </div>
                                  
                                  <div className="step-product-type-code-description">
                                    {productType.description || getProductType(productType.code)?.tagline}
                                  </div>
                                  
                                  {/* More efficient CTA rendering */}
                                  <div 
                                    className="step-product-type-code-cta" 
                                    onClick={() => handleProductClick ? handleProductClick(productType) : null}
                                  >
                                    {(() => {
                                      // Determine the action type (default to "order")
                                      const actionType = productType.actionType || "order";
                                      
                                      // Get the config for this action type
                                      const config = actionTypeConfig[actionType] || actionTypeConfig.order;
                                      
                                      // Determine the button text (use cta if provided, otherwise use default)
                                      const buttonText = productType.cta || config.defaultText;
                                      
                                      // Add price if it's an order type and has cost
                                      const price = actionType === "order" && 
                                                   getProductType(productType.code)?.cost && 
                                                   !getProductType(productType.code)?.costIsServiceFee
                                                    ? ` - $${Math.round(getProductType(productType.code)?.cost)}`
                                                    : "";
                                      
                                      // Return the rendered button content
                                      return (
                                        <>
                                          {config.icon}
                                          {buttonText}{price}
                                        </>
                                      );
                                    })()}
                                  </div>
                                </div>
                                
                                {/* Right side - photos */}
                                {getProductType(productType.code)?.photos?.length > 0 && (
                                  <div className={`step-product-type-photos ${getProductType(productType.code)?.photos.length === 1 ? 'single-product-image' : ''}`}>
                                    {getProductType(productType.code)?.photos.map((photo, index) => (
                                      <Image 
                                        key={index}
                                        src={photo.png || photo.webp || photo.jpg} 
                                        preview={false}
                                        className="product-image"
                                        alt={`${getProductType(productType.code)?.title} - Image ${index + 1}`}
                                      />    
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : null;
                      })()}


                      {/* Flow Types */}
                      {step.flowType && (
                        <div className="step-flow-type">
                          {/* Left side - content */}
                          <div className="flow-content">
                            <div className="step-flow-type-title">
                              {step.flowType.title}
                            </div>
                            
                            <div className="step-flow-type-description">
                              {step.flowType.text}
                            </div>
                            
                            <div 
                              className="step-flow-type-cta" 
                              onClick={() => handleFlowClick(step.flowType)}
                            >
                              <LineChartOutlined style={{ marginRight: 8 }} />
                              {step.flowType.cta || "Track"}
                            </div>
                          </div>
                          
        
                        </div>
                      )}

                    </Card>
                  ))}
                </div>  
              </div>  
              )}
              
            </div>
          </Col>


        </Row>

        </Drawer>

  
    </>
  );
};