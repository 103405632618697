const Medication = Object.freeze({
 
  // GENERIC
  CHOLESTEROL: "Statin / Cholesterol Medication",
  HIGH_BLOOD_PRESSURE: "High Blood Pressure Medication",
  LOWER_TRIGLYCERIDES: "Medication to Lower Trigylcerides",
  LOWER_GLUCOSE: "Medication to Lower Glucose Levels",
  HRT: "Hormonal Replacement Therapy",
  THYROID: "Thyroid Medication",
  BIRTH_CONTROL: "Hormonal Birth Control",

  // Drug Classes
  CORTICOSTEROIDS: "Corticosteroids",
  GLUCOCORTICOIDS: "Glucocorticoids",
  MINERALOCORTICOIDS: "Mineralocorticoids",
  SERMS: "SERMs",
  SSRIS: "SSRIs",

  // SPECIFIC
  SIMVASTATIN: "Simvastatin",
  ATORVASTATIN: "Atorvastatin",
  ROSUVASTATIN: "Rosuvastatin",
  PITAVASTATIN: "Pitavastatin",
  ALIROCUMAB: "Alirocumab",
  EVOLOCUMAB: "Evelocumab",
  LEVOTHYROXINE: "Levothyroxine",
  ADDERALL: "Adderall", 
  ALLOPURINOL: "Allopurinol",
  CLOMIPHENE: "Clomiphene",
  DUTASETERIDE: "Dutasteride",
  ENCLOMIPHENE: "Enclomiphene",
  EZETIMIBE: "Ezetimibe",
  FINASTERIDE: "Finasteride",
  GLP1: "GLP-1 Agonists",
  ICOSAPENT_ETHYL: "Icosapent Ethyl",
  INCLISIRIN: "Inclisirin",
  LEXAPRO: "Lexapro",
  METFORMIN: "Metformin",
  MINOXIDIL: "Minoxidil",
  MIRABEGRON: "Mirabegron",
  OZEMPIC: "Ozempic",
  PCKS9: "PCKS9 Inhibitor",
  PREGNENOLONE: "Pregnenolone",
  PROZAC: "Prozac",
  RITALIN: "Ritalin",
  SEMAGLUTIDE: "Semaglutide",
  STATIN: "Statin",
  TAMOXIFEN: "Tamoxifen",
  WEGOVY: "Wegovy",
  HCG: "Human Chorionic Gonadotropin",
  ISOTRETINOIN: "Isotretinoin",
  DESCOVY: "Descovy",
  TESTOSTERONE: "Testosterone",
  OTHER: "Other"
});

export default Medication;
