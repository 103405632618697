import React, { useState, useEffect, useContext } from 'react';
import { Radio, Button, Tag, Drawer } from 'antd';
import { 
  ShoppingCartOutlined, 
  SafetyCertificateOutlined,
  TagOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';
import FlowType from '../../enums/flowType.enum';
import EventType from '../../enums/eventType.enum';
import { addEvent } from '../../services/event.service';
import { listPanels } from '../../services/panel.service';
import { PanelCode } from '../../enums/index.enum';
import "./orderButton.scss";
import ProductHelper from '../../helpers/product.helper';
import { UserContext } from '../../contexts/user.context';


export const OrderButton = ({ test, panel }) => {
  const [selectedOption, setSelectedOption] = useState('single'); // Pre-select the single test option
  const [isMobile, setIsMobile] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [panels, setPanels] = useState([]);
  const { currentUser, instalabMembership } = useContext(UserContext);
  const [orderOptions, setOrderOptions] = useState([]);


  // Check if mobile on component mount and window resize
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);


  useEffect(() => {
    const fetchPanels = async () => {
      const tests = test ? [test?._id] : panel?.tests.length > 40 ? [] : panel?.tests.map(test => test._id);

      if (tests.length > 0) {
        const filter = {
          tests: { $all: tests },
          active: true,
          code: { $exists: true, $ne: panel?.code },
          productType: { $ne: panel?.productType }
        }
        const panels = await listPanels({filter: filter, populate: [{
          path: 'tests',
          select: '_id code cost tagline productType name shortName longName'
        }, {
          path: 'productType',
          select: '_id code cost tagline brandTitle title flowType path'
        }]});
        setPanels(panels.filter(panel => panel.code !== PanelCode.ATHLETE && (panel.productType || panel.cost>0)).sort((a, b) => a.cost - b.cost));
      }
    }
    fetchPanels();
  }, [test, panel]);

  useEffect(() => {
    // Define the ordering options
    const getOrderOptions = () => [
      test && test.cost > 0 && {
        key: 'single',
        title: `${test?.shortName || test?.name} Only`,
        price: test?.cost,
        description: `Individual test for ${test?.shortName || test?.name}.`,
        url: `/flow/${FlowType.CUSTOM_TEST}?tests=${test?.code}`,
        benefits: [
          'Results within 1 week',
          'Detailed analysis of your results',
          'Personalized recommendations'
        ]
      },
  
      panel && (panel.cost > 0 || panel.productType) && {
        key: 'single',
        title: `${panel.productType?.brandTitle || panel.productType?.title || panel.name}`,
        price: panel.productType?.costIsServiceFee ? 0 : ProductHelper.getTotalCostV2({productTypes: [panel.productType], instalabMembership: instalabMembership, currentUser: currentUser, ignoreCredits: true}) || panel.cost,
        description: panel.productType?.tagline || `Individual test for ${panel.productType?.name || panel.name}.`,
        url: panel.productType?.flowType ? `/flow/${panel.productType.flowType}` : `/flow/${FlowType.CUSTOM_TEST}?panels=${panel.code}`,
        learnMoreUrl: panel.productType?.path,
        benefits: panel.productType?.collectionMethod === 'blood' || !panel.productType ? [
          'Results within 1 week',
          'Detailed analysis of your results',
          'Personalized recommendations'
        ] : null
      },
  
      ...panels.map((panel) => ({ 
        key: panel.code,
        title: `${panel.productType?.brandTitle || panel.productType?.title || panel.name}`,
        price: panel.productType?.costIsServiceFee ? 0 : ProductHelper.getTotalCostV2({productTypes: [panel.productType], instalabMembership: instalabMembership, currentUser: currentUser, ignoreCredits: true}) || panel.cost,
        description: panel.productType?.tagline || panel.tagline,
        includes: panel.tests.sort((a,b) => b.cost - a.cost),
        url:  panel.productType?.flowType ? `/flow/${panel.productType.flowType}` : `/flow/${FlowType.CUSTOM_TEST}?panels=${panel.code}`,
        learnMoreUrl: panel.productType?.path,
        benefits: panel.code === PanelCode.HEART_HEALTH ? [
          'Results within 1 week',
          'Complete cardiovascular risk assessment',
          'Personalized recommendations'
        ] : panel.code === PanelCode.ADVANCED_FOLLOWUP ? [
          'Results within 1 week',
          'Comprehensive longevity analysis',
          'Personalized health optimization plan'
        ] : null
      })),
  
    ].filter(Boolean);

    const sortedOptions = getOrderOptions().sort((a, b) => {
      // Handle cases where price might be undefined or 0
      const priceA = a.price || 0;
      const priceB = b.price || 0;
      return priceA - priceB;
    });

    setOrderOptions(sortedOptions);
  }, [test, panel, panels]);



  useEffect(() => {
    if (orderOptions.length > 0) {
      setSelectedOption(orderOptions[0].key);
    }
  }, [orderOptions]);

  // Handle option change
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Handle order button click
  const handleOrderClick = async () => {
    const option = orderOptions.find(opt => opt.key === selectedOption);
    
    try {
      await addEvent({
        eventType: EventType.ORDER_TEST_CLICK,
        meta: {
          testId: test?._id || panel?._id,
          testName: test?.name || panel?.name,
          orderType: selectedOption
        }
      });
    } catch (error) {
      console.error('Failed to log event:', error);
    }
    
    window.open(option.url, '_blank');
  };

  // Extract the selectedOptionData lookup before the JSX
  const selectedOptionData = orderOptions.find(o => o.key === selectedOption);


  // Render the test selection content
  const renderTestSelection = () => (
    <div className="test-selection-content">
      <h3 className="selection-title">{test?.shortName || test?.name || panel?.productType?.name || panel?.name} {test ? " Test Options" : " Options"}</h3>
      
      <Radio.Group 
        onChange={handleOptionChange} 
        value={selectedOption}
        className="test-radio-group"
      >
        {orderOptions.map((option) => {
          const isSelected = selectedOption === option.key;
          
          return (
            <div 
              key={option.key} 
              className={`test-option ${isSelected ? 'selected' : ''}`}
              onClick={() => setSelectedOption(option.key)}
            >
              <div className="option-header">
                <Radio 
                  value={option.key} 
                  checked={isSelected}
                  onClick={(e) => e.stopPropagation()}
                >
                    <span className="option-title">{option.title}</span>
                </Radio>
                <div className="option-price">{option?.price > 0 ? `$${option?.price}` : ''}</div>
              </div>
              
              {isSelected && (
                <div className="option-details">
                  <p className="option-description">{option.description}</p>
                  
                  {option.benefits && (
                    <div className="option-benefits">
                      <ul>
                        {option.benefits.map((benefit, i) => (
                          <li key={i}>{benefit}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {panel?.code !== option.key && option.includes && option.includes.length > 1 && (
                    <div className="option-includes">
                      <div className="includes-label">
                        <TagOutlined /> <span>Includes {option.includes.length} key biomarkers:</span>
                      </div>
                      
                      <div className="includes-tags">
                        {/* Show first 5 tags always */}
                        {option.includes.slice(0, 5).map((item, i) => {
                          if (!item) return null;
                          const isHighlighted = (item.code === test?.code) || (panel?.tests.some(test => test.code === item.code));
                          return (
                            <Tag 
                              key={i} 
                              color={isHighlighted ? option.color : undefined}
                              className={`tag-item ${isHighlighted ? 'highlighted-tag' : ''}`}
                            >
                              {item.shortName || item.name}
                            </Tag>
                          );
                        })}
                        
                        {/* If there are more than 10 items, show count and toggle button */}
                        {option.includes.length > 10 && (
                          <ShowMoreTags 
                            items={option.includes.slice(5)} 
                            color={option.color}
                            test={test}
                            panel={panel}
                          />
                        )}
                        
                        {/* If there are between 5-10 items, just show the rest */}
                        {option.includes.length > 5 && option.includes.length <= 10 && 
                          option.includes.slice(5).map((item, i) => {
                            if (!item) return null;
                            const isHighlighted = item.code === test?.code;
                            return (
                              <Tag 
                                key={i + 5} 
                                color={isHighlighted ? option.color : undefined}
                                className={`tag-item ${isHighlighted ? 'highlighted-tag' : ''}`}
                              >
                                {item.shortName || item.name}
                              </Tag>
                            );
                          })
                        }
                      </div>
                    </div>
                  )}
                  
                </div>
              )}
            </div>
          );
        })}
      </Radio.Group>
      
      <div className="order-action">
        <Button 
          type="primary"
          icon={<ShoppingCartOutlined />}
          className="order-button"
          size="large"
          onClick={handleOrderClick}
        >
          Order Test {selectedOptionData?.price > 0 ? `- $${selectedOptionData.price}` : ''}
        </Button>

        {selectedOptionData?.learnMoreUrl && 
          <Button 
            className="learn-more-button" 
            type="default" 
            size="large" 
            onClick={() => window.open(selectedOptionData.learnMoreUrl, '_blank')}
          >
            Learn More
          </Button>
        } 
        
        <div className="secure-payment">
          <SafetyCertificateOutlined /> Secure payment • HIPAA-compliant
        </div>
      </div>

      
    </div>
  );

  // Show drawer for mobile, inline for desktop
  if (isMobile) {
    return (
      <div className="mobile-order-button-container">
        <Button 
          type="primary"
          icon={<ShoppingCartOutlined />}
          className="mobile-order-button"
          block
          size="large"
          onClick={() => setDrawerVisible(true)}
        >
          Order Test {selectedOptionData?.price > 0 ? `- $${selectedOptionData.price}` : ''}
        </Button>

        <div className="secure-payment">
          <SafetyCertificateOutlined /> Secure payment • HIPAA-compliant
        </div>
        
        <Drawer
          placement="bottom"
        
          closable={true}
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          height="auto"
          maxHeight="80vh"
          title={`${test?.shortName || test?.name} Test Options`}
          closeIcon={<CloseOutlined />}
          className="mobile-order-drawer"
        >
          {renderTestSelection()}
        </Drawer>
      </div>
    );
  }
  
  // Desktop view
  return (
    <div className="test-selection-container">
      {renderTestSelection()}
    </div>
  );
};

// Create a ShowMoreTags component to handle the collapsible behavior
const ShowMoreTags = ({ items, color, test, panel }) => {
  const [expanded, setExpanded] = useState(false);
  
  return (
    <>
      {!expanded && (
        <Button 
          type="link" 
          size="small"
          className="show-more-button"
          onClick={() => setExpanded(true)}
        >
          +{items.length} more <DownOutlined />
        </Button>
      )}
      
      {expanded && (
        <>
          {items.map((item, i) => {
            const isHighlighted = (item.code === test?.code) || (panel?.tests.some(test => test.code === item.code));
            return (
              <Tag 
                key={i} 
                color={isHighlighted ? color : undefined}
                className={`tag-item ${isHighlighted ? 'highlighted-tag' : ''}`}
              >
                {item.shortName || item.name}
              </Tag>
            );
          })}
          
          <Button 
            type="link" 
            size="small"
            className="show-less-button"
            onClick={() => setExpanded(false)}
          >
            Show less <UpOutlined />
          </Button>
        </>
      )}
    </>
  );
};

export default OrderButton;
