import { useState, useContext } from 'react';
import { Card } from 'antd';
import { RecommendationDrawer } from '../recommendationDrawer/recommendationDrawer.component';
import { RightOutlined } from '@ant-design/icons';
import "./recommendationItem.scss"
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
import { UserContext } from '../../contexts/user.context';

const RecommendationItem = ({ recommendation, tests, results, patient }) => {
  const [open, setOpen] = useState(false);
  const { currentUser } = useContext(UserContext);

  if (!recommendation || !recommendation.title) return null;


  const handleClick = async () => {
    try {
      PosthogHelper.track(currentUser, `[${EventCode.RECOMMENDATION_ITEM_CLICK}] ${recommendation.title}`);
    } catch (error) {
      console.error("Error tracking recommendation item click", error);
    }
    setOpen(true);

  };

  return recommendation &&  (
    <>
      <RecommendationDrawer
        recommendation={recommendation}
        tests={tests}
        results={results}
        patient={patient}
        open={open}
        setOpen={setOpen}
    />

    <Card className="recommendation-item" onClick={() => handleClick()}>
      <div className="recommendation-content">
        <div className="recommendation-item-title">{recommendation.title}</div>
        <RightOutlined className="recommendation-arrow" />
      </div>
    </Card>
    </>

  );
};

export default RecommendationItem