import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Modal, message, Tooltip } from 'antd'
import { listOrders, removeOrder } from "../../services/order.service"
import { PageHeader } from '../pageHeader/pageHeader.component';
import FilterDropdownType from '../../enums/filterDropdownType.enum'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import "./adminOrders.scss";
import { OrderModal } from '../orderModal/orderModal.component.js';
import classNames from 'classnames';
import { DeleteOutlined, EditOutlined, FileTextOutlined, UnorderedListOutlined } from '@ant-design/icons'
import AppointmentStatus from '../../enums/appointmentStatus.enum.js';
import UrlHelper from '../../helpers/url.helper.js';
import Role from '../../enums/role.enum.js';
import PhlebType from '../../enums/phlebType.enum.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';
import TestPanelModal from '../testPanelModal/testPanelModal.component.js';
import { useParams } from 'react-router-dom';
import { getPatient } from '../../services/patient.service.js';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const { Text } = Typography

export const AdminOrders = ({ hasPatient = false }) => {
  const { patientId } = useParams()

  const [orderId, setOrderId] = useState()
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState()
  const { currentUser, setCounts } = useContext(UserContext)
  const [filteredCount, setFilteredCount] = useState();
  
  const [panels, setPanels] = useState([])
  const [addOns, setAddons] = useState([])
  const [isAddOnsModalOpen, setIsAddOnsModalOpen] = useState(false);
  const [selectedAddOns, ] = useState([]);

  const [isTestPanelModalOpen, setIsTestPanelModalOpen] = useState(false);
  const [selectedTests, setSelectedTests] = useState([]);
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    document.title = 'Instalab | Orders'
  }, [])

  useEffect(() => {
    fetchOrders()
  }, [currentUser])

  useEffect(() => {

    const fetchPatient = async () => {
      const patient = await getPatient(patientId)
      setPatient(patient)
    }

    if (hasPatient) {
      fetchPatient()
    }
  }, [hasPatient])

  const fetchOrders = async () => {
    if (!currentUser || (hasPatient && !patientId)) return
    setIsLoading(true)

    let params = {
      select: '_id status appointment reqFileName createdAt patient panels tests notes lab facility',
      filter: {
        isRemoved: {
          $ne: true
        },
      },
      populate: [{
        path: 'lab',
        select: '_id abbreviation'
      }, {
        path: 'facility',
        select: '_id name'
      }, {
        path: 'appointment',
        select: '_id start phlebType status'
      }, {
        path: 'patient',
        select: '_id firstName lastName dob gender'
      },
      {
        path: 'tests',
        select: '_id code name'
      },
      {
        path: 'panels',
        select: '_id code name tests hasSexSpecificTests maleTests femaleTests',
        populate: [{
          path: 'tests',
          select: '_id code name'
        }, {
          path: 'maleTests',
          select: '_id code name'
        }, {
          path: 'femaleTests',
          select: '_id code name'
        }]
      }],
      sort: '-createdAt'
    }

    if (hasPatient) {
      params.filter = {
        patient: patientId,
      }
    }

    const fetchedOrders = await listOrders(params)
    const filteredOrders = filterOrders(fetchedOrders)
    setPanels([...new Set(filteredOrders.filter(({ panel }) => panel).map(({ panel }) => panel))].sort())
    setAddons([...new Set(filteredOrders.reduce((acc, { addOns }) => {
      if (addOns?.length) {
        acc = [...addOns, ...acc]
      }
      return acc
    }, []))].sort())
    setOrders(sortOrders(filteredOrders))
    setFilteredCount(fetchedOrders.length)
    setIsLoading(false)
  }

  const filterOrders = (unfilteredOrders) => {
    if (currentUser?.role !== Role.STAFF) return unfilteredOrders
    return unfilteredOrders.filter(order => order.appointment?.status === AppointmentStatus.CONFIRMED);
  }  


  const sortOrders = (unsortedOrders) => {
    const shouldBubbleUp = (order) => {
      const hasMissingRequirements = order.appointment && 
                                    order.status !== AppointmentStatus.COMPLETE &&
                                    order.appointment.start && 
                                    new Date(order.appointment.start) > new Date().setHours(0, 0, 0, 0) &&
                                    !order.reqFileName
      return hasMissingRequirements
    };

    return unsortedOrders.sort((a, b) => {
      const aShouldBubbleUp = shouldBubbleUp(a);
      const bShouldBubbleUp = shouldBubbleUp(b);
  
      if (aShouldBubbleUp && !bShouldBubbleUp) return -1;
      if (!aShouldBubbleUp && bShouldBubbleUp) return 1;
      return 0;
    });
  }

  const getCustomFilter = (order, value) => {
    return {
      patient: () => order.patient?.firstName && `${order.patient.firstName.toLowerCase()} ${order.patient.lastName.toLowerCase()}`.includes(value.toLowerCase()),
      status: () => order?.appointment?.status === value,
      phlebType: () => order.appointment?.phlebType === value,
      addOns: () => order?.addOns?.includes(value),
      panels: () => {
        if (!value) return true;
        const searchValue = value.toLowerCase();
        
        // Search in individual tests
        const matchingTests = order.tests?.some(test => 
          test.name.toLowerCase().includes(searchValue)
        ) || false;

        // Search in panels and their tests
        const matchingPanels = order.panels?.some(panel => {
          // Check panel name and code
          if (panel.name.toLowerCase().includes(searchValue)) {
            return true;
          }
          
          // Check tests within panel
          return panel.tests?.some(test =>
            test.name.toLowerCase().includes(searchValue) 
          );
        }) || false;

        return matchingTests || matchingPanels;
      }
    }
  }
  
  const onRemove = (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this order?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk() {
        removeOrder(_id)
          .then(() => {
            setOrders(orders.filter(order => order._id !== _id));
            message.info('Order removed');
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                orders: cachedCounts.orders - 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount-1)
          })
          .catch(err => {
            message.error('Failed to remove order');
          });
      }
    });
  }

  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }

  const getActionItems = (order) => {
    return [
      {
        key: 'tracking-number',
        label: (
          <Button onClick={() => {
              setOrderId(order._id);
              setIsModalOpen(true);
          }}>
            <EditOutlined /> Edit Order
          </Button>
        )
      }, {
        type: 'divider'
      }, {
        key: 'delete',
        label: (
          <Button 
            onClick={() => onRemove(order._id)}
            className="remove-item"
          >
            <DeleteOutlined /> Remove
          </Button>
        ),
      }
    ]
  }

  const rowClassName = (record) => {
    let isDateValid = true;
    if (record.appointment) {
      const collectionDate = new Date(record.appointment.start);
      collectionDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      isDateValid = collectionDate > today;
    }
    const isReqLinkMissing = record.appointment && (!record.reqFileName) && record.appointment.status === AppointmentStatus.CONFIRMED;
    return isDateValid && isReqLinkMissing ? 'row-missing-req' : '';
  };



  return (
    <div className="admin-orders">
      <PageHeader 
        title='Lab Orders'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setOrderId(null)
              setIsModalOpen(true)
            }}
          >
            + Add New Order
          </Button>
        )}
      />

      <OrderModal
        orderId={orderId}
        setOrderId={setOrderId}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onSuccess={(order) => {
          if (orderId) {
            setOrders(orders.map((p) => (p._id === orderId ? order : p)));
          } else {
            setOrders([order, ...orders]);
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                orders: cachedCounts.orders + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <TestPanelModal
        visible={isTestPanelModalOpen}
        onClose={() => setIsTestPanelModalOpen(false)}
        testDetails={selectedTests}
        patient={patient || currentUser}
        readOnly
      />

      <Modal
        title="Add-On Tests"
        open={isAddOnsModalOpen}
        onCancel={() => setIsAddOnsModalOpen(false)}
        footer={null}
      >
        <div>
          {selectedAddOns.map((test, index) => (
            <div key={index}>{test.name}</div>
          ))}
        </div>
      </Modal>

      <FlexibleTable
        isLoading={isLoading}
        records={orders}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        tableProps={{ rowClassName }}
        getActionItems={getActionItems}
        columns={[{
          title: 'Created',
          dataIndex: 'createdAt',
          render: (createdAt) => <>{convertTimeZone(createdAt, currentUser).format('MM/DD/YY')}</>
        }, 
        !hasPatient && {
          title: 'Patient',
          dataIndex: 'patient',
          render: (patient) => patient && <a href={UrlHelper.getPatientProfile(patient._id)} target="_blank">{patient.firstName} {patient.lastName}</a>,
          filterDropdownType: FilterDropdownType.INPUT
        },
        {
          title: 'Tests',
          dataIndex: 'panels',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (_, {panels, tests}) => {
            const MAX_TESTS_TO_SHOW = 3;
            const allTests = [
              ...(panels?.map(panel => ({ isPanel: true, ...panel })) || []),
              ...(tests || [])
            ];
            const hasMoreTests = allTests.length > MAX_TESTS_TO_SHOW;
            
            return (
              <>
                {allTests.length > 0 ? (
                  <span>
                    {allTests
                      .slice(0, MAX_TESTS_TO_SHOW)
                      .map(test => test.isPanel ? test.name : test.name)
                      .join(", ")}
                    
                    {hasMoreTests ? (
                      <>
                        <Button 
                          type="link" 
                          className="secondary-link"
                          onClick={() => {
                            setSelectedTests(allTests);
                            setIsTestPanelModalOpen(true);
                          }}
                        >
                          <span style={{fontWeight:500}}>{` + ${allTests.length - MAX_TESTS_TO_SHOW} more`}</span>
                        </Button> 
                      </>
                    ) : (
                      <>
                        <Button 
                          type="link" 
                          className="secondary-link"
                          onClick={() => {
                            setSelectedTests(allTests);
                            setIsTestPanelModalOpen(true);
                          }}
                        >
                          <span style={{fontWeight:500}}><UnorderedListOutlined /></span>
                        </Button> 
                      </>
                    )}
                  </span>
                ) : (
                  <>-</>
                )}
              </>
            )
          }
        },
        currentUser?.role === Role.STAFF && !hasPatient && {
          title: 'DOB',
          dataIndex: 'dob',
          render: (_, { patient }) => patient.dob
        }, currentUser?.role === Role.STAFF && !hasPatient && {
          title: 'Gender',
          dataIndex: 'gender',
          render: (_, { patient }) => patient.gender
        }, 
        {
          title: 'Appointment',
          dataIndex: 'appointment',
          render: (appointment) =>  appointment?.start ? convertTimeZone(appointment?.start, currentUser).format('MM/DD/YY') : <>n/a</>
        }, {
          title: '',
          dataIndex: 'appointment',
          render: (appointment) =>  appointment?.start &&  convertTimeZone(appointment?.start, currentUser).format('h:mma z')
        }, {
          title: 'Phleb',
          dataIndex: 'phlebType',
          render: (_, { appointment }) =>  appointment?.phlebType ? appointment?.phlebType : <>-</>,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(PhlebType)
        },
        {
          title: "Lab / Facility",
          dataIndex: 'lab',
          render: (lab, { facility }) => lab ? lab.abbreviation : facility ? facility.name : <>-</>
        },
        
        {
          title: 'Status',
          dataIndex: 'status',
          render: (_, { appointment }) => appointment && <Text className={classNames(`${appointment?.status}-status`, "appointment-status")}>{appointment?.status}</Text>,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(AppointmentStatus),
          width: 100
        }, {
          title: '',
          dataIndex: 'notes',
          width: 40,
          align: 'center',
          render: notes => notes && (
            <Tooltip title={notes} className="notes-tooltip">
              <FileTextOutlined />
            </Tooltip>
          )
        },]}
      />
    </div>
  )
}