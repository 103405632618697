import React from "react";
import "./cortisolAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { ReportSection, TestCode } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import { AdviceSection } from "../adviceSection/adviceSection.component";
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const CortisolAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [cortisolTest, setCortisolTest] = useState([]);
  const [recs, setRecs] = useState()

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();

  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, cortisolTest, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          recs
        }
      };
    });
  }, [title, primaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setCortisolTest(ReportHelper.getSectionTests(report, ReportSection.CORTISOL_ADVICE, tests) || []);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);
    if (!report || !results || !tests || !cortisolTest) return null;

    // Get cortisol value from results
    const cortisolValue = ReportHelper.getTestValue(TestCode.CORTISOL, report, results, tests);

    const intensityWord = cortisolValue > 100 ? "high" : "elevated";
    
    let content = 
      <>Your <span>cortisol</span> is <span>{intensityWord}</span>, which may indicate elevated stress, hormonal imbalance, or inflammation.</>;

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !cortisolTest) return null;

    return (
      <>
       Cortisol, often called the ‘stress hormone,’ is essential for regulating key body systems, including cardiovascular health, immune resilience, and metabolism. Yet, when cortisol levels stay consistently high, they can place undue strain on these systems, raising long-term health risks.
      <br/>
      <br/>
      Since a single blood test only offers a brief snapshot, a cortisol test that tracks fluctuations throughout the day is recommended to provide a more complete picture of your body’s cortisol patterns and regulation.
      </>
    );
  };
  
  return (
    cortisolTest && (
      <div className="cortisol-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={cortisolTest}
          results={results}
          reports={reports}
          user={user}
        />

        
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />

      </div>
    )
  );
};