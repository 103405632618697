import { ProductTypeBox } from "../components/gameplan-v2/productTypeBox.component";
import { SelfReportBox } from "../components/gameplan-v2/selfReportBox/selfReportBox.component";
import { TestLink }  from "../components/testLink/testLink.component";
import { Table } from "antd";

const FoodTable = ({foods, restriction=[], eatMore=false, description}) => { 
    // Add safety check for foods prop
    if (!foods || !Array.isArray(foods)) {
      return null; // or return a loading state/empty message
    }

    // Transform the data structure for the table
    const transformedFoods = foods.flatMap(category => {
      // Get filtered foods for this category
      const categoryFoods = (category.foods || [])
        .filter(item => !item.exclude?.some(exclude => restriction?.includes(exclude)))
        .map(item => ({
          food: item.food,
          servingSize: item.servingSize,
          note: item.note,
          amount: item.amount,
          isCategory: false,
          nutrient: item.nutrient,
        }));

      // Return category header followed by foods
      return [
        { food: category.category, isCategory: true },
        ...categoryFoods.map(food => ({
          food: food,
          isCategory: false,
        }))
      ];
    });

    const renderFoodItem = (item) => {
      if (!item) return null;
      const isEatMore = eatMore;
      const isEatLess = !eatMore;
      
      return (
        <div className={`food-item ${isEatMore ? 'eat-more' : isEatLess ? 'eat-less' : ''}`}>
          <div className="food-item-content">
            <div className="food-main">
              <span className="food-name">{item.food}</span>
              {(item.servingSize || item.amount) && (
                <span className="food-details">
                  {" • "}
                  {item.servingSize && (
                    <span className="serving-size">
                      <span className="detail-label">Serving: </span>
                      {item.servingSize}
                    </span>
                  )}
                  {item.servingSize && item.amount && " • "}
                  {item.amount && (
                    <span className="nutrient-amount">
                      <span className="detail-label">{item.nutrient}: </span>
                      {item.amount}
                    </span>
                  )}
                </span>
              )}
            </div>
            {item.note && <div className="food-note">{item.note}</div>}
          </div>
        </div>
      );
    };

    return (
      <div className="food-section">
        <div className="food-categories">
          <div className="table-header">
            <p className="table-description">{description}</p>
          </div>
          <Table 
            dataSource={transformedFoods}
            columns={[
              {
                title: 'Food Categories',
                dataIndex: 'food',
                key: 'food',
                width: '100%',
                render: (text, record) => {
                  if (record.isCategory) {
                    return <strong className="category-title">{text}</strong>;
                  }
                  return renderFoodItem(record.food);
                }
              }
            ]}
            pagination={false}
            showHeader={false}
            className="foodTable"
            rowKey={(record, index) => `food-${index}`}
            rowClassName={(record) => record.isCategory ? 'category-row' : ''}
          />
        </div>
      </div>
    );
}


export const TextHelper = {

  normalizeText: (text) => {
    if (!text) return '';
    return text.toString()
      .toLowerCase()
      .replace(/[^\w\s]/g, '') // Remove all punctuation
      .replace(/\s+/g, ' ')    // Replace multiple spaces with a single space
      .trim();
  },

  parseMarkdown: (text) => {
    if (!text) return null;

        // Convert **bold** → <strong>bold</strong>
        text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
        // Convert __underline__ → <u>underline</u>
        text = text.replace(/__(.*?)__/g, '<u>$1</u>');
    
        // Convert ~~strikethrough~~ → strikethrough
        text = text.replace(/~~(.*?)~~/g, 'strikethrough');
    
        // Convert [text](link) → <a href="link">text</a>
        text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
    
        return text;
    },
    
    renderBlock: (block, params) => {
        const createTestLinks = (text) => {
            // {{testCode:testName}} or {{testCode}}
            if (!text) return null;
            
            const parts = text.split(/(\{\{.*?\}\})/);
            return parts.map((part, index) => {
                const match = part.match(/\{\{(.*?)(:(.*?))?\}\}/);
                if (match) {
                    const [, testCode, , displayText] = match;
                    return <TestLink 
                        key={index} 
                        testCode={testCode} 
                        {...params} 
                        content={displayText || null} 
                    />;
                }
                return <span key={index} dangerouslySetInnerHTML={{ __html: TextHelper.parseMarkdown(part) }} />;
            });
        };

        switch (block.type) {
          case 'heading':
            return (
              <h3 className="recommendation-heading">
                {createTestLinks(block.text)}
              </h3>
            );
          case 'paragraph':
            return (
              <p className="recommendation-paragraph">
                {createTestLinks(block.text)}
              </p>
            );
            case 'table':
              const columns = block.headers.map((header, index) => ({
                title: header,
                dataIndex: `col${index}`,
                key: `col${index}`,
              }));
            
              const dataSource = block.rows.map((row, rowIndex) => {
                const rowData = {};
                row.forEach((cell, cellIndex) => {
                  rowData[`col${cellIndex}`] = createTestLinks(cell);
                });
                return { ...rowData, key: rowIndex };
              });
            
              return (
                <Table 
                  dataSource={dataSource} 
                  columns={columns} 
                  pagination={false}
                />
            );
          
          case 'list':
            const ListComponent = block.style === 'unordered' ? 'ul' : 'ol';
            return (
              <ListComponent className="recommendation-list">
                {block.items.map((item, i) => (
                  <li 
                    key={i} 
                    className="recommendation-list-item"
                  >
                    {createTestLinks(item)}
                  </li>
                ))}
              </ListComponent>
            );
          case 'quote':
            return (
              <blockquote className="recommendation-quote">
                <p>{createTestLinks(block.text)}</p>
                  {block.author && <cite className="recommendation-cite">{block.author}</cite>}
              </blockquote>
            );
          case 'foodTable':
            return <FoodTable foods={block.data.foods} restriction={block.data.exclude} eatMore={block.data.eatMore} description={block.data.description} />;
          case 'productTypeBox':
            return (
              <div className="recommendation-product-box">
                <ProductTypeBox 
                  productTypeCode={block.productTypeCode} 
                  membershipTypeCode={block.membershipTypeCode} 
                  cta={block.cta} 
                  title={block.title}
                  text={block.text}
                  url={block.url}
                />
              </div>
            );

          case 'selfReportBox':
            return (
              <div className="recommendation-self-report-box">
                <SelfReportBox 
                  title={block.title}
                  text={block.text}
                  cta={block.cta}
                  url={block.url}
                />
              </div>
            );
     
          default:
            return null;
        }
    },

    formatCurrency: (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(amount);
    }
}

export default TextHelper;