import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'


export const listFoods = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/foods`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}


export const addFood = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/foods`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeFood = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/foods/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const updateFood = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/foods/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}
