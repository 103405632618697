import FlowType from "../enums/flowType.enum"
import { GalleriFlow } from "../components/galleriFlow/galleriFlow.component"
import { SubscribeFlow } from "../components/subscribeFlow/subscribeFlow.component"
import { MemberFlow } from "../components/memberFlow/memberFlow.component"
import { LongevityConsultFlow } from "../components/longevityConsultFlow/longevityConsultFlow.component"
import { IntakeFlow } from "../components/intakeFlow/intakeFlow.component"
import { PrenuvoFlow } from "../components/prenuvoFlow/prenuvoFlow.component"
import { TruageCompleteFlow } from "../components/truageCompleteFlow/truageCompleteFlow.component"
import { ComprehensiveTestFlow } from "../components/comprehensiveTestFlow/comprehensiveTestFlow.component"
import { CacFlow } from "../components/cacFlow/cacFlow.component"
import { HeartHealthTestFlow } from "../components/heartHealthTestFlow/heartHealthTestFlow.component"
import { SupplementFlow } from "../components/supplementFlow.js/supplementFlow.component"
import { LongevityTestFlow } from "../components/longevityTestFlow/longevityTestFlow.component"
import { InviteFlow } from "../components/inviteFlow/inviteFlow.component"
import { ToxicMetalsTestFlow } from "../components/toxicMetalsTestFlow/toxicMetalsTestFlow.component"
import { HeartHealthStrippedFlow } from "../components/heartHealthStrippedFlow/heartHealthStrippedFlow.component"
import { FertilityTestFlow } from "../components/fertilityTestFlow/fertilityTestFlow.component"
import { AthleteTestFlow } from "../components/athleteTestFlow/athleteTestFlow.component"
import { DexaFlow } from "../components/dexaFlow/dexaFlow.component"
import { ToxicMoldsTestFlow } from "../components/toxicMoldsTestFlow/toxicMoldsTestFlow.component"
import { ProConsultFlow } from "../components/proConsultFlow/proConsultFlow.component"
import { SleepApneaTestFlow } from "../components/sleepApneaTestFlow/sleepApneaTestFlow.component"
import { ProSignupFlow } from "../components/proSignupFlow/proSignupFlow.component"
import { BiobeatFlow } from "../components/biobeatFlow/biobeatFlow.component"
import { ProDrawFlow } from "../components/proDrawFlow/proDrawFlow.component"
import { CleerlyFlow } from "../components/cleerlyFlow/cleerlyFlow.component"
import { TestosteronePanelFlow } from "../components/testosteronePanelFlow/testosteronePanelFlow.component"
import { CgmFlow } from "../components/cgmFlow/cgmFlow.component"
import { HeartHealthIntakeFlow } from "../components/heartHealthIntake/heartHealthIntake.component"
import { UltimateMemberFlow } from "../components/ultimateMemberFlow/ultimateMemberFlow.component"
import { LeqvioFlow } from "../components/leqvioFlow/leqvioFlow.component"
import { DexaBodyCompFlow } from "../components/dexaBodyCompFlow/dexaBodyCompFlow.component"
import { HeartMemberConsultFlow } from "../components/heartMemberConsultFlow/heartMemberConsultFlow.component"
import { Vo2maxFlow } from "../components/vo2maxFlow/vo2maxFlow.component"
import { DiurnalCortisolProfileFlow } from "../components/diurnalCortisolProfileFlow/diurnalCortisolProfileFlow.component"
import { GiftFlow} from "../components/giftFlow/giftFlow.component"
import { HeartHealthGeneticTestFlow } from "../components/heartHealthGeneticTestFlow/heartHealthGeneticTestFlow.component"
import { DemoFlow } from "../components/demoFlow/demoFlow.component"
import { RequestFlow } from "../components/requestFlow/requestFlow.component"
import { ProAthletePanelFlow } from "../components/proFlows/proAthletePanelFlow/proAthletePanelFlow.component"
import { AthleteConsultFlow } from "../components/athleteConsultFlow/athleteConsultFlow.component"
import { ProLongevityPanelFlow } from "../components/proFlows/proLongevityPanelFlow/proLongevityPanelFlow.component"
import { ProSleepApneaTestFlow } from "../components/proFlows/proSleepApneaTestFlow/proSleepApneaTestFlow.component"
import { ProAvaliablityFlow } from "../components/proFlows/proAvailabilityFlow/proAvailabilityFlow.component"
import { CustomTestFlow } from "../components/customTestFlow/customTestFlow.component"
import { RmrFlow } from "../components/rmrFlow/rmrFlow.component"
import { FitStoolFlow } from "../components/fitStoolFlow/fitStoolFlow.component"
import { ProMobileDrawFlow } from "../components/proFlows/proMobileDrawFlow/proMobileDrawFlow.component"
import { SkinExamFlow } from "../components/skinExamFlow/skinExamFlow.component"
import { MammogramFlow } from "../components/mammogramFlow/mammogramFlow.component"
import { ColonoscopyFlow } from "../components/colonoscopyFlow/colonoscopyFlow.component"
import { LdctFlow } from "../components/ldctFlow/ldctFlow.component"
import { DreFlow } from "../components/dreFlow/dreFlow.component"
import { HpvFlow } from "../components/hpvFlow/hpvFlow.component"
import { PapSmearFlow } from "../components/papSmearFlow/papSmearFlow.component"
import { PackageFlow } from "../components/packageFlow/packageFlow.component"
import { SpermTestFlow } from "../components/spermTestFlow/spermTestFlow.component"
import { WaitlistFlow } from "../components/waitlistFlow/waitlistFlow.component"
import { SpecialistFlow } from "../components/specialistFlow/specialistFlow.component"
import { SelfReportFlow } from "../components/selfReportFlow/selfReportFlow.component"
import { HeartHealthV2Flow } from "../components/hearthealthV2Flow/heartHealthV2Flow.component"
import { SiboFlow } from "../components/siboFlow/siboFlow.component"
import { CancerGeneticTestFlow } from "../components/cancerGeneticTestFlow/cancerGeneticTestFlow.component"
import { CholesterolBalanceFlow } from "../components/cholesterolBalanceFlow/cholesterolBalanceFlow.component"
import { OmegaCompleteFlow } from "../components/omegaCompleteFlow/omegaCompleteFlow.component"
const FlowHelper = {
  getFlow: flowType => {
    switch (flowType) {
      case FlowType.OMEGA_3_INDEX:
        return {
          component: <OmegaCompleteFlow />,
          forceNew: true
        }
      case FlowType.CHOLESTEROL_BALANCE:
        return {
          component: <CholesterolBalanceFlow />,
          forceNew: true
        }
      case FlowType.CANCER_GENETIC_TEST:
        return {
          component: <CancerGeneticTestFlow />,
          forceNew: true
        }
      case FlowType.SUBSCRIBE:
        return {
          component: <SubscribeFlow />,
          forceNew: true
        }
      case FlowType.SIBO_TEST:
        return {
          component: <SiboFlow />,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_V2:
        return {
          component: <HeartHealthV2Flow />,
          forceNew: true
        }
      case FlowType.SPECIALIST:
        return {
          component: <SpecialistFlow />,
          forceNew: true
        }
      case FlowType.INVITE:
        return {
          component: <InviteFlow />,
          forceNew: false
        }
      case FlowType.LONGEVITY_TEST:
        return {
          component: <LongevityTestFlow />,
          forceNew: true
        }
      case FlowType.MEMBER:
        return {
          component: <MemberFlow/>,
          forceNew: true,
        }
      case FlowType.WAITLIST:
        return {
          component: <WaitlistFlow/>,
          forceNew: true
        }

      case FlowType.HEART_HEALTH_INTAKE:
        return {
          component: <HeartHealthIntakeFlow />,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_STRIPPED:
        return {
          component: <HeartHealthStrippedFlow />,
          forceNew: true
        }
      case FlowType.PRO_SIGNUP:
        return {
          component: <ProSignupFlow />,
          forceNew: false
        }
      case FlowType.ATHLETE_TEST:
        return {
          component: <AthleteTestFlow />,
          forceNew: true
        }
      case FlowType.COMPREHENSIVE_TEST:
        return {
          component: <ComprehensiveTestFlow/>,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_TEST:
        return {
          component: <HeartHealthTestFlow />,
          forceNew: true,
        }
      case FlowType.GALLERI_TEST:
        return {
          component: <GalleriFlow/>,
          forceNew: true,
        }
      case FlowType.INTAKE:
        return {
          component: <IntakeFlow/>,
          forceNew: true,
        }
      case FlowType.SELF_REPORT:
        return {
          component: <SelfReportFlow/>,
          forceNew: true,
        }
      case FlowType.PRENUVO:
        return {
          component: <PrenuvoFlow/>,
          forceNew: true
        }
      case FlowType.TRUAGE_COMPLETE:
        return {
          component: <TruageCompleteFlow />,
          forceNew: true
        }

      case FlowType.DIURNAL_CORTISOL_PROFILE:
        return {
          component: <DiurnalCortisolProfileFlow/>,
          forceNew: true
        }
      case FlowType.TOXIC_METALS_TEST:
        return {
          component: <ToxicMetalsTestFlow/>,
          forceNew: true
        }
      case FlowType.CAC:
        return {
          component: <CacFlow/>,
          forceNew: true,
        }
      case FlowType.CLEERLY:
        return {
          component: <CleerlyFlow/>,
          forceNew: true,
        }

      case FlowType.SUPPLEMENTS:
        return {
          component: <SupplementFlow/>,
          forceNew: true
        }
      case FlowType.DEXA:
        return {
          component: <DexaFlow/>,
          forceNew: true
        }
      case FlowType.DEXA_BODYCOMP:
        return {
          component: <DexaBodyCompFlow/>,
          forceNew: true
        }
      case FlowType.FERTILITY:
        return {
          component: <FertilityTestFlow/>,
          forceNew: true
        }
      case FlowType.MOLD_TOXINS:
        return {
          component: <ToxicMoldsTestFlow/>,
          forceNew: true
        }
      case FlowType.SLEEP_APNEA:
        return {
          component: <SleepApneaTestFlow/>,
          forceNew: true
        }
      case FlowType.BIOBEAT:
        return {
          component: <BiobeatFlow/>,
          forceNew: true
        }
      case FlowType.TESTOSTERONE:
      case FlowType.TESTOSTERONE_PANEL:
        return {
          component: <TestosteronePanelFlow/>,
          forceNew: true
        }
      case FlowType.CGM:
        return {
          component: <CgmFlow/>,
          forceNew: true
        }
      case FlowType.ULTIMATE_MEMBERSHIP:
        return {
          component: <UltimateMemberFlow/>,
          forceNew: true,
        }
      case FlowType.LEQVIO:
        return {
          component: <LeqvioFlow/>,
          forceNew: true
        }

      case FlowType.VO2MAX:
        return {
          component: <Vo2maxFlow/>,
          forceNew: true
        }
      case FlowType.GIFT:
        return {
          component: <GiftFlow/>,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_GENETIC_TEST:
        return {
          component: <HeartHealthGeneticTestFlow/>,
          forceNew: true
        }
      case FlowType.REQUEST:
        return {
          component: <RequestFlow/>,
          forceNew: true
        }

      case FlowType.FIT_STOOL:
        return {
          component: <FitStoolFlow/>,
          forceNew: true
        }
      case FlowType.DEMO:
        return {
          component: <DemoFlow/>,
          forceNew: true
        }
      case FlowType.COLONOSCOPY:
        return {
          component: <ColonoscopyFlow/>,
          forceNew: true
        }
      case FlowType.LDCT:
        return {
          component: <LdctFlow/>,
          forceNew: true
        }
      case FlowType.DRE:
        return {
          component: <DreFlow/>,
          forceNew: true
        }
      case FlowType.HPV_TEST:
        return {
          component: <HpvFlow/>,
          forceNew: true
        }
      case FlowType.PAP_SMEAR:
        return {
          component: <PapSmearFlow/>,
          forceNew: true
        }
      // Consults
      case FlowType.LONGEVITY_CONSULT:
        return {
          component: <LongevityConsultFlow />,
          forceNew: true
        }
      case FlowType.PRO_CONSULT:
        return {
          component: <ProConsultFlow />,
          forceNew: true
        }
      case FlowType.HEART_MEMBER_CONSULT:
        return {
          component: <HeartMemberConsultFlow />,
          forceNew: true
      }
      case FlowType.ATHLETE_CONSULT:
        return {
          component: <AthleteConsultFlow/>,
          forceNew: true,
        }
      case FlowType.CUSTOM_TEST:
        return {
          component: <CustomTestFlow/>,
          forceNew: true
        }
      case FlowType.RMR:
        return {
          component: <RmrFlow/>,
          forceNew: true
        }
      case FlowType.SKIN_CANCER_EXAM:
        return {
          component: <SkinExamFlow/>,
          forceNew: true
        }
      case FlowType.MAMMOGRAM:
        return {
          component: <MammogramFlow/>,
          forceNew: true
        }
      case FlowType.PACKAGE:
        return {
          component: <PackageFlow/>,
          forceNew: true
        }
      case FlowType.SPERM_TEST:
        return {
          component: <SpermTestFlow/>,
          forceNew: true
        }

      // PRO Flows
      case FlowType.PRO_DRAW:
        return {
          component: <ProDrawFlow />,
          forceNew: true
        }
      case FlowType.PRO_MOBILE_BLOOD_DRAW:
        return {
          component: <ProMobileDrawFlow/>,
          forceNew: true
        }
      case FlowType.PRO_ATHLETE_PANEL:
        return {
          component: <ProAthletePanelFlow/>,
          forceNew: true
        }
      case FlowType.PRO_LONGEVITY_PANEL:
        return {
          component: <ProLongevityPanelFlow/>,
          forceNew: true
        }
      case FlowType.PRO_SLEEP_APNEA:
        return {
          component: <ProSleepApneaTestFlow/>,
          forceNew: true
        }
      case FlowType.PRO_AVAILABILITY:
        return {
          component: <ProAvaliablityFlow/>,
          forceNew: true
        }
      default:
        return null
  }
}
}

export default FlowHelper