import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import Color from '../../colors.scss'
import FlowType from "../../enums/flowType.enum"
import ConfirmInterest from "./static/confirmInterest/confirmInterest.component"
import { addSlack } from "../../services/slack.service"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import { getAvailability } from "../../services/schedule.service"
import PhlebType from "../../enums/phlebType.enum"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"
import { listTests } from "../../services/test.service"
import { getPanelByCode } from "../../services/panel.service"
import PanelCode from "../../enums/panelCode.enum"
import { confirmAppointment, setShippingLocation,  releasePendingAppointment } from "../../services/appointment.service"
import { updateFlow } from "../../services/flow.service"
import TimerDisplay from "../timerDisplay/timerDisplay.component"
import { listPanels } from "../../services/panel.service"
import TestPanelModal from "../testPanelModal/testPanelModal.component"
import { PanelModal } from "../panelModal/panelModal.component"
import { advancedPanel } from "../../data/advancedPanel.data"

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  GENDER: 'gender',
  DOB: 'dob',
  PHONE: 'phone',
  ADDONS: 'addons',
  LOCATION: 'location',
  INTEREST: 'interest',
  INTEREST_CONFIRMATION: 'interest-confirmation',
  DRAW: 'draw',
  PSC: 'psc',
  PAY: 'pay',
  SHIPPING: 'shipping',
  SCHEDULE: 'schedule',
  RESCHEDULE: 'reschedule',
  INVITE: 'invite',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation'
}

export const LongevityTestFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [phlebType, setPhlebType] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const [tests, setTests] = useState()
  const [panels, setPanels] = useState()
  const [isTestModalOpen, setIsTestModalOpen] = useState(false)
  const [selectedTests, setSelectedTests] = useState([])
  const [isPanelModalOpen, setIsPanelModalOpen] = useState(false)
  const [instalabPanel, setInstalabPanel] = useState()

  useEffect(() => {
    const fetchProductTypes = async () => {
      const productTypes = await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.LONGEVITY_PANEL,
              ProductTypeCode.MOBILE_BLOOD_DRAW,
              ProductTypeCode.LAB_VISIT
            ]
          }
        }
      })
      setProductTypes(productTypes)
    }
  

    const fetchTests = async () => {
      const filter = {
        name: { $exists: true },
      }
      
      const tests = await listTests({filter})
      const panel = await getPanelByCode(PanelCode.ADVANCED_FOLLOWUP)

      const panelTests = tests.filter(test => !panel?.maleTests.some(x => x._id.toString() === test._id.toString()))
      panel.tests = panelTests

      setTests(tests.filter(test => !panel?.maleTests.some(x => x._id.toString() === test._id.toString()) && (currentUser?.gender === Gender.MALE ? !test.femaleOnly : !test.maleOnly)))
      setInstalabPanel(panel)
    }

    const fetchPanels = async () => {
      const PANELS = [
        PanelCode.OMEGACHECK,
        PanelCode.NMR_LIPOPROFILE,
        PanelCode.CELIAC,
        PanelCode.BLOOD_TYPE,
        PanelCode.HEAVY_METALS,
        PanelCode.LYME_AB_SCREEN,
        PanelCode.ANA_SCREEN_REFLEX
      ]
      
      const panels = await listPanels({filter: {code: { $in: PANELS}}})
      setPanels(panels)
    }



    fetchProductTypes()
    fetchTests()
    fetchPanels()
  }, [])


  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, flow, currentUser, phlebType, hasPass, initialUser, tests, panels])

  useEffect(() => {
    fetchPhlebType()
  }, [currentUser, flow])

  
  const fetchPhlebType = async () => {
    if (!currentUser?.location || !flow) return
    const response = await getAvailability({
      startDate: new Date(),
      location: currentUser.location,
      flowId: flow._id
    })
    setPhlebType(response.phlebType)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes || !tests || !panels) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.LONGEVITY_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.ADDONS,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.ADDONS]: {
        type: StepType.TEST_PICKER,
        title: <>The Instalab Panel includes <a className="secondary-link" onClick={() => setIsPanelModalOpen(true)}>60+ biomarkers</a>. Would you like to add any additional tests?</>,
        description: <>Select additional tests below.</>,
        buildFlow: true,
        multiple: true,
        hasSkip: true,
        tests: tests,
        panels: panels,
        forceShow: [{
          ...instalabPanel,
          cost: ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.LONGEVITY_PANEL)],
            instalabMembership,
            currentUser,
            ignoreCredits: true
          })
        }],
        buttonText: 'Add Tests',
        onNextStep: () => {
          return Step.LOCATION
        },        
        field: {
          name: 'testAddOns',
        },

      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildFlow: true,
        onNextStep: async (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType;
          
          return phlebType ? Step.DRAW : Step.INTEREST
        },
        title: `Where are you located?`,
        description: `For your blood draw, you can visit a nearby patient service center, or a phlebotomist will come to this location.`,
        field: {
          name: 'location'
        },
      },
      [Step.INTEREST]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INTEREST_CONFIRMATION,
        buildUser: true,
        title: `Apologies, we're not in your area yet...`,
        description: <>But if you leave your email, we'll notify you as soon as we are. You can also reach out to us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>.</>,
        field: {
          name: 'email',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        },
        onSuccess: async (patient) => {
          await addSlack({
            message: `${patient.email} interested: instalab panel`,
            channel: process.env.REACT_APP_PATIENT_SLACK
          })
        },
      },
      [Step.INTEREST_CONFIRMATION]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You joined the waitlist.`,
        description: `When we launch in your area, we'll send you an email.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmInterest />
      },

      [Step.DRAW]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your blood draw option.`,
        buildFlow: true,
        multiple: false,
        onNextStep: (_, flow) => {
          if (flow?.prefersMobileDraw) {
            return Step.SCHEDULE;
          }
          else return Step.PSC;
        },
        field: (() => {

          const patient = flow?.user
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType;
          const noLabVisit = ['AZ','HI','NJ','RI'].includes(patient?.location?.state)

          const mobileCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)], 
            instalabMembership, 
            currentUser,
            ignoreCredits: true
          })

          const labCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.LAB_VISIT)], 
            instalabMembership, 
            currentUser,
            ignoreCredits: true
          })


          return {
            name: 'prefersMobileDraw',
            required: true,
            options: [
              {
                title: "Lab Visit",
                value: false,
                description: "Visit a nearby patient service center (select location next).",
                price: labCost,
                disabled: noLabVisit,
                tag: noLabVisit && "Unavailable in your state"

              },             
              {
                title: "At-Home Draw",
                value: true,
                description: "A licensed phlebotomist will come to your home or office.",
                price: mobileCost,
                disabled: phlebType === PhlebType.FACILITY ,
                tag: phlebType === PhlebType.FACILITY && "Unavailable in your area"
              },             
          ] 
        }})(),
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.MOBILE_BLOOD_DRAW].includes(product.productType.code)).length > 0
        }

      },
      [Step.PSC]: {
        type: StepType.FACILITY,
        nextStep: Step.SCHEDULE,
        buildFlow: true,
        multiple: false,
        productTypeCode: ProductTypeCode.LONGEVITY_PANEL,
        title: `Select your preferred patient service center.`,
        description: `This is where you'll get your blood drawn.`,
        field: {
          name: 'facility',
          required: true,
        },
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        createPending: true,
        addPendingAppointment: true,
        nextStep: Step.PAY,
        title: `Schedule your blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.prefersMobileDraw 
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })(),
      },   
      

      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: async (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          if (flow?.prefersMobileDraw) {
            if (phlebType === PhlebType.GETLABS) {
              return Step.SHIPPING
            }
            else {
              return Step.INVITE
            }
          }
          else {
            return Step.APPOINTMENT_CONFIRMATION
          }       
        },
        addProduct: true,
        tests: tests,
        panels: panels,
        productType: (() => { 
          return [
            ProductTypeCode.LONGEVITY_PANEL, 
            flow?.prefersMobileDraw ? ProductTypeCode.MOBILE_BLOOD_DRAW : ProductTypeCode.LAB_VISIT,
          ]
        })(),
      
        title: (() => {

          const panelTitle = getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title;

          const productTypeCodes = [
            ProductTypeCode.LONGEVITY_PANEL, 
            flow?.prefersMobileDraw ? ProductTypeCode.MOBILE_BLOOD_DRAW : ProductTypeCode.LAB_VISIT,
          ]

          const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))

          const totalCost = ProductHelper.getTotalCostV2({
            productTypes: productTypeCodes.map(code => getProductType(code)),
            instalabMembership,
            currentUser,
            tests,
            panels,
            testAddOns: flow?.testAddOns
          })

          
          return hasPaid ? (
            <>Payment complete for <span className="product-name">{panelTitle}</span></>
          ) : (
            <>Pay <span className="true-price">{ProductHelper.formatPrice(totalCost)}</span> for the <span className="product-name">{panelTitle}</span>.</>
          );
        })(),
        header: () => {
          const productTypeCodes = flow?.prefersMobileDraw ? [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.LONGEVITY_PANEL]
          const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))
    
          return !hasPaid && (
            <div>
              <div className="timer-warning">
                <div className="timer-warning-content">
                Complete payment within{" "} 
                  <TimerDisplay 
                    start={flow?.appointment?.updatedAt} 
                    timeAllowed={8*60} 
                    onExpire={async () => {
                      await releasePendingAppointment(flow?.appointment?._id)
                      await updateFlow(flow?._id, {appointment: null})
                      setFlow(prevFlow => ({...prevFlow, appointment: null}))
                      navigate(`/flow/${FlowType.LONGEVITY_TEST}/${Step.SCHEDULE}/${flow?._id}`)
                    }} 
                  />{" "}
                
                  to secure your appointment. 
              </div>  
              </div>
            </div>
          )
        },
        description: (() => {
          const selectedTests = flow?.testAddOns?.map(testId => 
            tests?.find(t => t._id === testId) || 
            {
              _id: testId,
              name: panels?.find(p => p._id === testId)?.name,
              tagline: panels?.find(p => p._id === testId)?.tagline,
              isPanel: true,
              // TO DO: sex-based tess
              tests: panels?.find(p => p._id === testId)?.tests.map(testId => tests?.find(t => t._id === testId))
            }
          ).filter(Boolean);

          return <>
          {selectedTests?.length > 0 ? (
            <>
              This includes the <a 
                href="#" 
                className="secondary-link"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTests(selectedTests);
                  setIsTestModalOpen(true);
                }}
              >
                {selectedTests.length} additional test{selectedTests.length > 1 ? 's' : ''}
              </a>, {flow?.prefersMobileDraw ? 'at-home blood draw' : 'lab visit'}, and results report.
            </>
          ) : (
            <>
              This includes your {flow?.prefersMobileDraw ? 'at-home blood draw' : 'lab visit'} and results report.
            </>
          )}
        </>
        })(),

        onSuccess: async () => {
          await confirmAppointment(flow?.appointment?._id, {flowId: flow?._id})
        },

      },

      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.INVITE,
        title: `Where should we ship your test supplies kit?`,
        description: `You will receive a kit in the mail containing all the supplies needed for your phlebotomist to complete your blood draw.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient, flow) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }

            await setShippingLocation(flow?.appointment?._id, {shippingLocation: patient.shippingLocation})
            
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.INVITE]: {
        type: StepType.INVITE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addInvite: true,
        title: `Add friends and family to your appointment.`,
        description: <>
          Invite others to join your appointment and get the same tests at{" "} 
          <span style={{ color: Color.success }}>{ProductHelper.formatPrice(flow?.appointment?.invitePrice)} per person</span>{flow?.appointment?.invitePrice < getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost && <span> (normally ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost})</span>}.&nbsp;
          No additional charges will be made until after the appointment.
        </>,
      },

      [Step.RESCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: () =>  `Reschedule your blood draw appointment.`,
        description: `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).` , 
      },
      
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}`,
        title: `Your blood draw appointment is confirmed!`,
        description: (() => {
          const isMobile = flow?.prefersMobileDraw;
          return isMobile && flow?.appointment?.phlebType === PhlebType.GETLABS ? 
            <><p><u>You will receive a kit in the mail</u> containing all the supplies needed to complete your blood draw. A phlebotomist from our partner (Getlabs) will call or text you upon arrival on the day of your appointment.</p><p>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations.</p>
            </> :
            <>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations.</>;
        })(),
        buttonText: 'Continue',
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return <>

    <TestPanelModal
      visible={isTestModalOpen}
      onClose={() => setIsTestModalOpen(false)}
      testDetails={selectedTests}
    />

    <PanelModal 
      open={isPanelModalOpen}
      setOpen={setIsPanelModalOpen}
      title="The Instalab Panel"
      panel={advancedPanel}
    />

    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      productTypeCode={flow?.prefersMobileDraw || flow?.prefersMobileDraw === undefined ? [ProductTypeCode.MOBILE_BLOOD_DRAW, ProductTypeCode.LONGEVITY_PANEL] : [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.LAB_VISIT]}
      startIndex={1}
    />
  </>
}