import React from "react";
import "./heavyMetalsAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { ReportSection, TestCode } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import { AdviceSection } from "../adviceSection/adviceSection.component";
import parse from 'html-react-parser';
import RiskLevel from "../../../enums/riskLevel.enum";
const { Paragraph } = Typography;

export const HeavyMetalsAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [mercuryTest, setMercuryTest] = useState([]);
  const [recs, setRecs] = useState()

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();

  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, mercuryTest, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          recs
        }
      };
    });
  }, [title, primaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setMercuryTest(ReportHelper.getSectionTests(report, ReportSection.HEAVY_METAL_ADVICE, tests) || []);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);
    if (!report || !results || !tests || !mercuryTest) return null;

    // Get cortisol value from results
    const mercuryRisk = ReportHelper.getTestRisk(TestCode.MERCURY, report, results, tests);

    const intensityWord = mercuryRisk === RiskLevel.HIGH ? "high" : "elevated";
    
    let content = 
      <>Your <span>mercury</span> is <span>{intensityWord}</span>, which can impact your long-term brain health.</>;

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !mercuryTest) return null;

    return (
      <>
       Elevated mercury—especially from dietary sources like high-mercury fish—can accumulate in the body and negatively impact brain health over time. It has been associated with memory issues, mood disturbances, and increased risk for cognitive decline, especially with long-term exposure.
      </>
    );
  };

  
  return (
    mercuryTest && (
      <div className="cortisol-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={mercuryTest}
          results={results}
          reports={reports}
          user={user}
        />

        
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />

      </div>
    )
  );
};